import React, { useRef, useState } from "react";
import { Grid, Typography, Divider, Button, IconButton } from "@mui/material";
import "../../../Style/Custom.css";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { useLocation, useNavigate } from "react-router";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import { useReactToPrint } from 'react-to-print';
import NGOLogo from "component/Logo";

const ViewSingleEvent = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const event_date_time = queryParams.get("event_date_time");
  const title = queryParams.get("title");
  const event_description = queryParams.get("event_description");
  const event_location = queryParams.get("event_location");
  const media_url = queryParams.get("media_url");
  const handleClickBack = () => {
    navigate("/display-events");
  };
  const componentPDF = useRef();
  const [downloadInProgress, setDownloadInProgress] = useState(false);

  const handleDownloadClick = () => {
    setDownloadInProgress(true);

    setTimeout(() => {
      generatePDF();
    }, 50);
  };


  const generatePDF = useReactToPrint({
    content: () => componentPDF.current,
    documentTitle: "userData",
    onBeforePrint: () => setDownloadInProgress(true),
    onAfterPrint: () => setDownloadInProgress(false),
  });
  return (
    <div className="main-container-single">
      <div spacing={0} className="d-flex-space-bw">
        <div>
          <Button onClick={handleClickBack} variant="text">
            <IconButton
              onClick={handleClickBack}
              aria-label="edit"
              color="primary"
            >
              <ArrowBackIosIcon />
            </IconButton>
            Back
          </Button>
        </div>

        <div>
          <Button onClick={handleDownloadClick} variant="text">
            <IconButton onClick={handleDownloadClick} aria-label="edit" color="primary">
              <ArrowDownwardIcon />
            </IconButton>
            Download
          </Button>
        </div>
      </div>

      <Grid container spacing={3} className="pdf-grid" ref={componentPDF}>
        {downloadInProgress && (
          <div className="pdf-topbar">
            <div className="pdf-top-div">
              <Typography color="#fff" gutterBottom variant="h4">
                NGO-org pvt. ltd.
              </Typography>
              <div>address line 1</div>
              <div>address line 2</div>
            </div>
            <NGOLogo />
          </div>
        )}
        <div className="d-flex-center95" >
          <Typography variant="h5">Event Details</Typography>
        </div>
        <Grid container>
          <Grid item xs={12}>
            <Divider />
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Typography color="textPrimary" gutterBottom variant="h4">
            Basic Information
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography variant="h6">Event Type:</Typography>
          <Typography variant="body1">Charity Event</Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography variant="h6">Event Title:</Typography>
          <Typography variant="body1">{title}</Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography variant="h6">Description:</Typography>
          <Typography variant="body1">{event_description}</Typography>
        </Grid>
        <Grid item xs={12}>
          <Divider />
        </Grid>

        <Grid item xs={12}>
          <Typography color="textPrimary" gutterBottom variant="h4">
            Date and Time
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography variant="h6">Date and Time:</Typography>
          <Typography variant="body1">{event_date_time}</Typography>
        </Grid>
        <Grid item xs={12}>
          <Divider />
        </Grid>

        <Grid item xs={12}>
          <Typography color="textPrimary" gutterBottom variant="h4">
            Address Details
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography variant="h6">Event Venue:</Typography>
          <Typography variant="body1">
            {event_location}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Divider />
        </Grid>

        {/* <Grid item xs={12}>
          <Typography color="textPrimary" gutterBottom variant="h4">
            Contact Person
          </Typography>
        </Grid> */}

        <Grid item xs={6}>
          <Typography variant="h6">Contact Name:</Typography>
          <Typography variant="body1">qwertyui</Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography variant="h6">Contact Number:</Typography>
          <Typography variant="body1">123456789</Typography>
        </Grid>
        <Grid item xs={12}>
          <Divider />
        </Grid>

        <Grid item xs={12}>
          <Typography color="textPrimary" gutterBottom variant="h4">
            Media Details
          </Typography>
        </Grid>

        <Grid item xs={6}>
          <Typography variant="h6">Media Type:</Typography>
          <Typography variant="body1">qwertyui</Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography variant="h6">Media URL:</Typography>
          <Typography variant="body1">{media_url}</Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography variant="h6">Media (audio/video/other):</Typography>
          <Typography variant="body1">123456789</Typography>
        </Grid>
        <Grid item xs={12}>
          <Divider />
        </Grid>
      </Grid>
    </div>
  );
};

export default ViewSingleEvent;
