import React, { useState, useEffect } from "react";
import { Grid, TextField, Typography, Button } from "@mui/material";
import Divider from "@mui/material/Divider";
import "../../../Style/Custom.css";
// import FormGroup from '@mui/material/FormGroup';
// import FormControlLabel from '@mui/material/FormControlLabel'
// import Switch from '@mui/material/Switch';
import { addMemberCat } from "api/donor";
import { toast } from "react-toastify";
import { DataGrid } from "@mui/x-data-grid";
import { getMemberCat } from "api/donor";


const AddMemberType = () => {
  const [formData, setFormData] = useState({
    memberType: "",
    amount: "",
    // status: ''
  });

  const [errors, setErrors] = useState({});

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  // const handleStatusChange = () => {
  //   const newStatus = formData.status === 'active' ? 'inactive' : 'active';
  //   setFormData({ ...formData, status: newStatus });
  // };

  const handleSubmit = async () => {
    if (validation()) {
      try {
        const _user = JSON.parse(sessionStorage.getItem("user"));
        const res = await addMemberCat({ category: formData.memberType, amount: Number(formData.amount), schema_name: _user.schema_name });
        toast.success("Member category added successfully.");
        setFormData({
          memberType: "",
          amount: "",
        });
        setRefresh(refresh + 1)
      } catch (err) {
        toast.error("Member category failed.");
      }
    };
  }

  const handleError = (inputs, value) => {
    setErrors((prev) => ({ ...prev, [inputs]: value }));
  };

  const validation = () => {
    var isValid = true;

    if (!formData.amount) {
      handleError("amount", "Please enter the Amount");
      isValid = false;
    }
    if (!formData.memberType) {
      handleError("memberType", "Please enter Member Category");
      isValid = false;
    }
    // if (!formData.status) {
    //   handleError("status", "Please enter Status");
    //   isValid = false;
    // }
    return isValid;
  };

  const [cats, setCats] = useState([]);
  const [refresh, setRefresh] = useState(0);

  const fetchCats = async () => {
    const _user = JSON.parse(sessionStorage.getItem("user"));
    const res = await getMemberCat({ schema_name: _user.schema_name });
    setCats(res?.data?.map((v, i) => {
      return { label: v.category, amount: v.amount, id: i + 1 }
    }))
  }

  useEffect(() => {
    fetchCats();
  }, [refresh])

  const columns = [
    { field: "id", headerName: "S.No", width: 125, minWidth: 125, maxWidth: 200 },
    { field: "label", headerName: "Member Category", width: 125, minWidth: 125, maxWidth: 200 },
    { field: "amount", headerName: "Amount", width: 125, minWidth: 125, maxWidth: 200 },
  ];


  return (
    <div>
      <div className="main-container">
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography color="textPrimary" variant="h5" className="heading2">
              Add Member Category
            </Typography>
            <Divider />
          </Grid>
          {/* <Grid item xs={12}>
            <Typography color="textPrimary" variant="h5">
              Basic Information
            </Typography>
          </Grid> */}
          <Grid item xs={12} md={12} lg={6}>
            <TextField
              name="memberType"
              label="Member Category"
              value={formData.memberType}
              onChange={handleInputChange}
              variant="outlined"
              required
              fullWidth
              error={!errors.memberType ? false : true}
              helperText={errors.memberType}
              onFocus={() => handleError("memberType", null)}
            />
            {/* <TextField
              select
              name="memberType"
              label="Member Category"
              value={formData.memberType}
              onChange={handleInputChange}
              variant="outlined"
              fullWidth
              error={!errors.memberType ? false : true}
              helperText={errors.memberType}
              onFocus={() => handleError('memberType', null)}
            >
              <MenuItem value="employee">Employee</MenuItem>
              <MenuItem value="volunteer">Volunteer</MenuItem>
            </TextField> */}
          </Grid>
          <Grid item xs={12} md={12} lg={6}>
            <TextField
              name="amount"
              label="Amount"
              value={formData.amount}
              onChange={handleInputChange}
              variant="outlined"
              required
              fullWidth
              error={!errors.amount ? false : true}
              helperText={errors.amount}
              onFocus={() => handleError("amount", null)}
            />
          </Grid>
          <Grid item xs={12} className="dFlex justifyCenter">
            <Button variant="contained" onClick={handleSubmit}>
              Register
            </Button>
          </Grid>

          <div style={{ height: "calc(100% - 48px)", width: "100%", marginTop: "1rem", overflow: 'auto' }}>
            <DataGrid sx={{ minWidth: '750px !important' }} rows={cats} columns={columns} pageSize={5} />
          </div>
        </Grid>
      </div>
    </div>
  );
};

export default AddMemberType;
