import * as React from 'react';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import { Typography } from '@mui/material';

const columns = [
  { id: 'sno', label: 'S.no', minWidth: 50 },
  { id: 'name', label: 'Employee Name', minWidth: 170 },
  { id: 'timeIn', label: 'Time In', minWidth: 100 },
  { id: 'timeOut', label: 'Time Out', minWidth: 100 },
  { id: 'status', label: 'Status', minWidth: 100 }
];

function createData(sno, name, timeIn, timeOut, status) {
  return { sno, name, timeIn, timeOut, status };
}

const rows = [
  createData(1, 'John Doe', '09:00 AM', '05:00 PM', 'Present'),
  createData(2, 'Jane Smith', '09:30 AM', '05:30 PM', 'Present'),
  createData(3, 'Bob Johnson', '10:00 AM', '06:00 PM', 'Late'),
  createData(4, 'Alice Williams', '09:15 AM', '05:15 PM', 'Absent'),
  createData(5, 'Michael Brown', '09:45 AM', '05:45 PM', 'Present'),
  createData(6, 'Emily Wilson', '09:10 AM', '05:10 PM', 'Present'),
  createData(7, 'Chris Evans', '09:20 AM', '05:20 PM', 'Present'),
  createData(8, 'Chris Evans', '09:20 AM', '05:20 PM', 'Present'),
  createData(9, 'Chris Evans', '09:20 AM', '05:20 PM', 'Present'),
  createData(10, 'Chris Evans', '09:20 AM', '05:20 PM', 'Present'),
  createData(11, 'Chris Evans', '09:20 AM', '05:20 PM', 'Present'),
  createData(12, 'Chris Evans', '09:20 AM', '05:20 PM', 'Present')
];

export default function StickyHeadTable() {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  return (
    <div style={{ maxheight: 600, width: '100%', backgroundColor: 'white', padding: '10px', borderRadius: '8px' }}>
      <Typography variant="h5" gutterBottom style={{ marginBottom: '20px' }}>
        Employee Attendance
      </Typography>

      <Paper sx={{ width: '100%', overflow: 'hidden' }}>
        <TableContainer sx={{ maxHeight: 440 }}>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                {columns.map((column) => (
                  <TableCell key={column.id} align={column.align} style={{ minWidth: column.minWidth }}>
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => (
                <TableRow key={row.sno}>
                  {columns.map((column) => (
                    <TableCell key={column.id} align={column.align}>
                      {row[column.id]}
                    </TableCell>
                  ))}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[10, 25, 100]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
    </div>
  );
}

