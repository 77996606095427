import React, { useRef, useState } from "react";
import {
  Grid,
  Dialog,
  Typography,
  Divider,
  Button,
  IconButton,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
} from "@mui/material";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import "../../../Style/Custom.css";
import { useLocation, useNavigate } from "react-router";
import { useReactToPrint } from "react-to-print";
import NGOLogo from "component/Logo";
import { DataGrid } from "@mui/x-data-grid";
import { CSVLink } from "react-csv";
import { Download } from "@mui/icons-material";

const ViewSingleDonor = () => {
  const componentPDFTable = useRef();
  const componentPDFReceipt = useRef();

  const location = useLocation();
  const { donor } = location?.state || {};
  const {
    full_name,
    user_type,
    address,
    donated_for,
    email,
    amount_donated,
    pan_number,
    mobile_number,
    payment_method,
    donations,
  } = donor;
  // createdAt,created_by,updatedAt,donations
  const { street, city, country, postal_code, state } = address;
  // const {} = donations;
  const navigate = useNavigate();
  const handleClickBack = () => {
    navigate("/display-donors");
  };
  const componentPDF = useRef();

  const [downloadInProgress, setDownloadInProgress] = useState(false);
  const [selectedDonorId, setSelectedDonorId] = useState(null);
  const [open, setOpen] = useState(false);

  const handleClickOpen = (rowData) => {
    setSelectedDonorId(rowData.row.id);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const handleDownloadClick = () => {
    setDownloadInProgress(true);

    setTimeout(() => {
      generatePDF();
    }, 50);
  };
  const generatePDFReceipt = useReactToPrint({
    content: () => componentPDFReceipt.current,
    documentTitle: "Receipt",
    onAfterPrint: () => setOpen(false),
  });

  const generatePDF = useReactToPrint({
    content: () => componentPDF.current,
    documentTitle: "userData",
    onBeforePrint: () => setDownloadInProgress(true),
    onAfterPrint: () => setDownloadInProgress(false),
  });

  const columns = [
    {
      field: "id",
      headerName: "S.no",
      width: 125,
      minWidth: 155,
      maxWidth: 200,
    },
    {
      field: "payment_method",
      headerName: "Payment Method",
      width: 125,
      minWidth: 155,
      maxWidth: 200,
    },
    {
      field: "amount",
      headerName: "Payment Amount",
      width: 125,
      minWidth: 155,
      maxWidth: 200,
    },
    {
      field: "donation_date",
      headerName: "Payment Date",
      width: 125,
      minWidth: 155,
      maxWidth: 200,
    },
    // { field: "note", headerName: "Payment Note", flex: 1 },
    {
      field: "action",
      headerName: "Action",
      width: 125,
      minWidth: 155,
      maxWidth: 200,
      renderCell: (item) => (
        <div>
          <IconButton
            aria-label="delete"
            color="error"
            onClick={() => handleClickOpen(item)}
          >
            <Download />
          </IconButton>
        </div>
      ),
    },
  ];
  const generatePDFPrintTable = useReactToPrint({
    content: () => componentPDFTable.current,
    documentTitle: "userData",
    onAfterPrint: () => setDownloadInProgress(false),
  });

  const donationsWithIds = donations.map((donation, index) => ({
    ...donation,
    id: index + 1, // Assuming index + 1 is unique enough
  }));

  return (
    <div className="main-container-single">
      <div spacing={0} className="d-flex-space-bw">
        <div>
          <Button onClick={handleClickBack} variant="text">
            <IconButton
              onClick={handleClickBack}
              aria-label="edit"
              color="primary"
            >
              <ArrowBackIosIcon />
            </IconButton>
            Back
          </Button>
        </div>
        <div>
          <Button onClick={handleDownloadClick} variant="text">
            <IconButton
              onClick={handleDownloadClick}
              aria-label="edit"
              color="primary"
            >
              <ArrowDownwardIcon />
            </IconButton>
            Download
          </Button>
        </div>
      </div>

      <Grid container spacing={3} className="pdf-grid" ref={componentPDF}>
        {downloadInProgress && (
          <div className="pdf-topbar">
            <div className="pdf-top-div">
              <Typography color="#fff" gutterBottom variant="h4">
                NGO-org pvt. ltd.
              </Typography>
              <div>address line 1</div>
              <div>address line 2</div>
            </div>
            <NGOLogo />
          </div>
        )}
        <div className="d-flex-center95">
          <Typography variant="h5">Donor Details</Typography>
        </div>
        <Grid container>
          <Grid item xs={12}>
            <Divider />
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Typography color="textPrimary" gutterBottom variant="h4">
            General Information
          </Typography>
        </Grid>
        <Grid item xs={6} lg={6}>
          <Typography variant="h6">Donor Type:</Typography>
          <Typography variant="body1">{user_type}</Typography>
        </Grid>
        <Grid item xs={6} lg={6}>
          <Typography variant="h6">Donor Name:</Typography>
          <Typography variant="body1">{full_name}</Typography>
        </Grid>
        <Grid item xs={6} lg={6}>
          <Typography variant="h6">Email:</Typography>
          <Typography variant="body1">{email}</Typography>
        </Grid>
        <Grid item xs={6} lg={6}>
          <Typography variant="h6">User Name:</Typography>
          <Typography variant="body1">{email}</Typography>
        </Grid>
        <Grid item xs={6} lg={6}>
          <Typography variant="h6">Phone:</Typography>
          <Typography variant="body1">{mobile_number}</Typography>
        </Grid>
        <Grid item xs={6} lg={6}>
          <Typography variant="h6">Address:</Typography>
          <Typography variant="body1">
            `{street},{city},{state},{country},{postal_code}`
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Divider />
        </Grid>
        <Grid item xs={12}>
          <Typography color="textPrimary" gutterBottom variant="h4">
            Address Information
          </Typography>
        </Grid>
        <Grid item xs={6} lg={6}>
          <Typography variant="h6">Address:</Typography>
          <Typography variant="body1">
            4-South Tilak Vihar A, Kalwara Road, Jhotwara, Jaipur-302012
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Divider />
        </Grid>
        <Grid item xs={12}>
          <Typography color="textPrimary" gutterBottom variant="h4">
            User Identification Information
          </Typography>
        </Grid>
        <Grid item xs={6} lg={6}>
          <Typography variant="h6">ID Proof:</Typography>
          <Typography variant="body1">ABCDE1234F</Typography>
        </Grid>
        <Grid item xs={6} lg={6}>
          <Typography variant="h6">PAN Number:</Typography>
          <Typography variant="body1">{pan_number}</Typography>
        </Grid>
        <Grid item xs={12}>
          <Divider />
        </Grid>
        <Grid item xs={12}>
          <Typography color="textPrimary" gutterBottom variant="h4">
            Payment Details
          </Typography>
        </Grid>
        <Grid item xs={6} lg={6}>
          <Typography variant="h6">Currency:</Typography>
          <Typography variant="body1">ABCDE1234F</Typography>
        </Grid>
        <Grid item xs={6} lg={6}>
          <Typography variant="h6">Donation Amount:</Typography>
          <Typography variant="body1">{amount_donated}</Typography>
        </Grid>
        <Grid item xs={6} lg={6}>
          <Typography variant="h6">Donation Purpose:</Typography>
          <Typography variant="body1">{donated_for}</Typography>
        </Grid>
        <Grid item xs={6} lg={6}>
          <Typography variant="h6">Payment Method:</Typography>
          <Typography variant="body1">{payment_method}</Typography>
        </Grid>
        <Grid item xs={6} lg={6}>
          <Typography variant="h6">UTR Number:</Typography>
          <Typography variant="body1">GST1234567890</Typography>
        </Grid>
        <Grid item xs={6} lg={6}>
          <Typography variant="h6">Cheque Number:</Typography>
          <Typography variant="body1">GST1234567890</Typography>
        </Grid>
        <Grid item xs={12}>
          <Divider />
        </Grid>
      </Grid>
      {/* <Grid container spacing={3} className="pdf-grid" ref={componentPDF}> */}
      <Divider />
      <div className="table-common">
        <div spacing={0} className="d-flex-space-bw">
          {/* <div >
            <TextField
              type="text"
              size="small"
              placeholder="Search"
              value={searchInput}
              onChange={handleSearchChange}
              variant="outlined"
              fullWidth
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
            />
          </div> */}
          <div className="d-flex-center90">
            <Typography variant="h5">Payment Information</Typography>
          </div>
          <div>
            <Button onClick={generatePDFPrintTable} variant="text">
              <IconButton
                onClick={generatePDFPrintTable}
                aria-label="edit"
                color="primary"
              >
                <ArrowDownwardIcon />
              </IconButton>
              Download
            </Button>
            {/* <Button variant="text" className="mr-10">
            <CSVLink
              data={formatDataForCSV()}
              filename={"members.csv"}
              className="csv-link"
              target="_blank"
            >
              <IconButton aria-label="edit" color="primary">
                <ArrowDownwardIcon />
              </IconButton>
              CSV
            </CSVLink>
          </Button> */}
          </div>
        </div>
        <div ref={componentPDFTable}>
          <div
            style={{
              height: "calc(100% - 48px)",
              width: "100%",
              overflow: "auto",
            }}
          >
            <DataGrid
              rows={donationsWithIds}
              columns={columns}
              pageSize={5}
              sx={{ minWidth: "900px !important" }}
            />
          </div>
        </div>
      </div>
      {/* </Grid> */}
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title"></DialogTitle>
        <DialogContent>
          <div ref={componentPDFReceipt}>
            <Grid
              container
              spacing={3}
              style={{ padding: print ? "20px" : "0px" }}
            >
              <Grid item xs={12}>
                <Typography
                  variant="h4"
                  style={{
                    color: "#fff",
                    textAlign: "center",
                    backgroundColor: "purple",
                    padding: "30px 0px",
                  }}
                >
                  Thanks for shopping with us
                </Typography>
              </Grid>

              <Grid item xs={12}>
                <Typography variant="h6">Hi {full_name}</Typography>
                <Typography variant="h6">
                  We have finished processing your order.
                </Typography>
              </Grid>

              <Grid item xs={12}>
                <Typography
                  variant="h6"
                  style={{ marginBottom: "10px", color: "purple" }}
                >
                  <strong>Order #164617</strong> (07/06/2024)
                </Typography>
              </Grid>

              <Grid item xs={4} style={{ border: "1px solid #c0c0c0" }}>
                <Typography variant="h6">
                  <strong>Product</strong>
                </Typography>
              </Grid>
              <Grid item xs={4} style={{ border: "1px solid #c0c0c0" }}>
                <Typography variant="h6">
                  <strong>Quantity</strong>
                </Typography>
              </Grid>
              <Grid item xs={4} style={{ border: "1px solid #c0c0c0" }}>
                <Typography variant="h6">
                  <strong>Price</strong>
                </Typography>
              </Grid>

              <Grid item xs={4} style={{ border: "1px solid #c0c0c0" }}>
                <Typography variant="h6">
                  WASender PRO (India) - 1 month
                </Typography>
              </Grid>
              <Grid item xs={4} style={{ border: "1px solid #c0c0c0" }}>
                <Typography variant="h6">1</Typography>
              </Grid>
              <Grid item xs={4} style={{ border: "1px solid #c0c0c0" }}>
                <Typography variant="h6">₹592.37 (ex. VAT)</Typography>
              </Grid>

              <Grid
                item
                xs={6}
                style={{
                  border: "1px solid #c0c0c0",
                  borderBottom: "1px solid #c0c0c0",
                }}
              >
                <Typography variant="h6">Subtotal:</Typography>
              </Grid>
              <Grid item xs={6} style={{ border: "1px solid #c0c0c0" }}>
                <Typography variant="h6">₹592.37 (ex. VAT)</Typography>
              </Grid>

              <Grid item xs={6} style={{ border: "1px solid #c0c0c0" }}>
                <Typography variant="h6">IGST:</Typography>
              </Grid>
              <Grid item xs={6} style={{ border: "1px solid #c0c0c0" }}>
                <Typography variant="h6">₹106.83</Typography>
              </Grid>

              <Grid item xs={6} style={{ border: "1px solid #c0c0c0" }}>
                <Typography variant="h6">Payment method:</Typography>
              </Grid>
              <Grid item xs={6} style={{ border: "1px solid #c0c0c0" }}>
                <Typography variant="h6">
                  Credit Card/Debit Card/NetBanking
                </Typography>
              </Grid>

              <Grid
                item
                xs={6}
                style={{
                  border: "1px solid #c0c0c0",
                  borderBottom: "2px solid #c0c0c0",
                }}
              >
                <Typography variant="h6">Total:</Typography>
              </Grid>
              <Grid
                item
                xs={6}
                style={{
                  border: "1px solid #c0c0c0",
                  borderBottom: "2px solid #c0c0c0",
                }}
              >
                <Typography variant="h6">₹699.00</Typography>
              </Grid>

              <Grid item xs={12}>
                <Typography variant="h6" style={{ color: "purple" }}>
                  <strong>Donor Details</strong>
                </Typography>
              </Grid>

              <Grid
                item
                xs={12}
                style={{ marginTop: "20px", border: "2px solid #c0c0c0" }}
              >
                <Typography variant="h6">Name:{full_name}</Typography>
                <Typography variant="h6">User Type:{user_type}</Typography>
                <Typography variant="h6">Donated For:{donated_for}</Typography>
                <Typography variant="h6">Donated For:{donated_for}</Typography>
              </Grid>

              {donations.map((donation, index) => (
                <>
                  <Grid item xs={12}>
                    <Typography variant="h6" style={{ color: "purple" }}>
                      <strong>Donation Details</strong>
                    </Typography>
                  </Grid>

                  <Grid
                    item
                    xs={12}
                    style={{ marginTop: "20px", border: "2px solid #c0c0c0" }}
                  >
                    <Typography variant="h6">Amount:{donation.amount}</Typography>
                    <Typography variant="h6">Donation Purpose:{donation.donation_purpose}</Typography>
                    <Typography variant="h6">Payment Method:{donation.payment_method}</Typography>
                    <Typography variant="h6">Donation Date:{donation.donation_date}</Typography>
                  </Grid>
                </>
              ))}

              <Grid item xs={12}>
                <Typography variant="h6" style={{ color: "purple" }}>
                  <strong>Billing address</strong>
                </Typography>
              </Grid>

              <Grid
                item
                xs={12}
                style={{ marginTop: "20px", border: "2px solid #c0c0c0" }}
              >
                <Typography variant="h6">Name:{full_name}</Typography>
                <Typography variant="h6">Add.:
                  {`${street}, ${city}, ${state}, ${country}, ${postal_code}`}
                </Typography>
                <Typography variant="h6">Email:{email}</Typography>
                <Typography variant="h6">Mob.:{mobile_number}</Typography>
                <Typography variant="h6">PAN:{pan_number}</Typography>
              </Grid>
            </Grid>
          </div>
        </DialogContent>

        <DialogActions>
          <Button autoFocus onClick={handleClose}>
            Cancel
          </Button>
          <Button onClick={generatePDFReceipt} autoFocus>
            Download receipt
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default ViewSingleDonor;
