import PropTypes from "prop-types";
import React from "react";

// material-ui
import { useTheme } from "@mui/material/styles";
import { List, Typography } from "@mui/material";

// project import
import NavItem from "../NavItem";
import NavCollapse from "../NavCollapse";

// ==============================|| NAVGROUP ||============================== //

const NavGroup = ({ item }) => {
  const theme = useTheme();
  const { role } = JSON.parse(sessionStorage.getItem("user")) || {};

  const access_ = JSON.parse(sessionStorage.getItem("user"))?.me?.[0]?.access;
  const access = Object.keys(access_ ? JSON.parse(access_) : {});

  const userRout = JSON.parse(sessionStorage.getItem("user"));
  const userRoutes = userRout?.me?.[0]?.access_routes ? JSON.parse(userRout?.me?.[0]?.access_routes) : []


  const items = item.children.map((menu) => {
    if (userRoutes?.length === 0) {
      if (role === "ngo-admin") {
        if (!menu?.access?.includes(role)) return;
      } else if (role === "super-admin") {
        if (!menu?.access?.includes(role)) return;
      } else {
        const childrens_ = menu?.children?.filter((v) => access.includes(v?.url));
        menu.children = childrens_;
        if (childrens_?.length === 0) return;
      }
    }
    if (menu?.type === "collapse") {
      if (role !== "ngo-admin" && role !== "super-admin") {
        const childs = menu?.children;
        const isValid = childs?.some(v => userRoutes?.includes(v?.url));
        const filteredChilds = childs?.filter(v => userRoutes?.includes(v?.url));
        if (!isValid) return;
        menu.children = filteredChilds;
      }
    }
    switch (menu.type) {
      case "collapse":
        return <NavCollapse key={menu.id} menu={menu} level={1} />;
      case "item":
        return <NavItem key={menu.id} item={menu} level={1} />;
      default:
        return (
          <Typography key={menu.id} variant="h6" color="error" align="center">
            Menu Items Error
          </Typography>
        );
    }
  });

  return (
    <List
      subheader={
        <Typography variant="caption" sx={{ ...theme.typography.menuCaption }} display="block" gutterBottom>
          {item.title}
          {item.caption && (
            <Typography variant="caption" sx={{ ...theme.typography.subMenuCaption }} display="block" gutterBottom>
              {item.caption}
            </Typography>
          )}
        </Typography>
      }
    >
      {items}
    </List>
  );
};

NavGroup.propTypes = {
  item: PropTypes.object,
  children: PropTypes.object,
  title: PropTypes.string,
  caption: PropTypes.string,
};

export default NavGroup;
