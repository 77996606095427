import React, { lazy } from "react";
// project import
import Permission from "pages/Permissions";
import MainLayout from "layout/MainLayout";
import Loadable from "component/Loadable";
import AddDonor from "pages/Donor/AddDonor";
import AddEmployee from "pages/Employee/AddEmployee";
import AddNgo from "pages/Ngo/AddNGO";
import ViewNgo from "pages/Ngo/DisplayNGO";
import ViewSingleNGO from "pages/Ngo/ViewSingleNGO";
import DisplayDonors from "pages/Donor/DisplayDonor";
import ViewSingleDonor from "pages/Donor/ViewSingleDonor";
import DisplayEmployee from "pages/Employee/DisplayEmployee";
import EmployeeAttendance from "pages/Employee/EmployeeAttendance";
import AddEvent from "pages/Event/AddEvent";
import DisplayEvents from "pages/Event/DisplayEvents";
import ViewSingleEvent from "pages/Event/ViewSingleEvent";
import DonationPurpose from "pages/DonationPurpose/AddPurpose";
import ViewSinglePurpose from "pages/DonationPurpose/ViewSinglePurpose";
import DisplayPurposes from "pages/DonationPurpose/DisplayPurposes";
import NotFound from "pages/not-found";
import ViewSingleMember from "pages/Member/ViewSingleMember";
import ViewSingleEmployee from "pages/Employee/ViewSingleEmployee";
import AddMember from "pages/Member/AddMember";
import AddMemberType from "pages/Member/AddMemberType";
import DisplayMember from "pages/Member/ViewMember";
import DownloadReciepts from "pages/Finance/DownloadReciepts";
import MetaHome from "pages/metadata/home";
import MetaAboutUs from "pages/metadata/about-us";
import AddBlog from "pages/Blog/AddBlog";
import CalendarSection from "pages/Calender/CalenderSection";
import AddPayment from "pages/Finance/AddPayment";
import ViewProfile from "pages/profile";
import DisplayMemberType from "pages/Member/ViewMemberType";
import ViewPayments from "pages/Finance/ViewPayments";
import DisplayBlogs from "pages/Blog/displayBlogs";
import AddEmployeeType from "pages/Employee/addEmployeeType/addEmployeeType";

const DashboardDefault = Loadable(lazy(() => import("../pages/Dashboard")));

const UtilsTypography = Loadable(
  lazy(() => import("../pages/Utils/Typography"))
);

const SamplePage = Loadable(lazy(() => import("../pages/SamplePage")));

const MainRoutes = {
  path: "/",
  element: <MainLayout />,
  children: [
    { path: "/", element: <DashboardDefault /> },
    { path: "/dashboard/default", element: <DashboardDefault /> },
    { path: "/utils/util-typography", element: <UtilsTypography /> },
    { path: "/sample-page", element: <SamplePage /> },

    // ----------------------------ngo---------------------------->>
    { path: "/ngo-register", element: <AddNgo /> },
    { path: "/display-ngo", element: <ViewNgo /> },
    { path: "/view-ngo-details", element: <ViewSingleNGO /> },

    // ----------------------------ngo---------------------------->>
    { path: "/view-profile", element: <ViewProfile /> },

    // ----------------------------member-------------------------->>
    { path: "/add-member-type", element: <AddMemberType /> },
    { path: "/display-member-type", element: <DisplayMemberType /> },
    { path: "/add-member", element: <AddMember /> },

    { path: "/display-member", element: <DisplayMember /> },
    { path: "/view-member-details", element: <ViewSingleMember /> },
    { path: "/member-update", element: <AddMember /> },
    { path: "/payment-update", element: <AddPayment /> },
    { path: "/add-member-type", element: <AddMemberType /> },

    // ----------------------------employee------------------------>>
    { path: "/add-employee", element: <AddEmployee /> },
    { path: "/display-employee", element: <DisplayEmployee /> },
    { path: "/view-employee-details", element: <ViewSingleEmployee /> },
    { path: "/employee-attendance", element: <EmployeeAttendance /> },
    { path: "/employee-update", element: <AddEmployee /> },
    { path: "/prefix-id", element: <AddEmployeeType /> },


    // ----------------------------donor--------------------------->>
    { path: "/donor-register", element: <AddDonor /> },
    { path: "/display-donors", element: <DisplayDonors /> },
    { path: "/view-donor-details", element: <ViewSingleDonor /> },

    // ----------------------------Blog Section --------------------------->>
    { path: "/add-blog", element: <AddBlog /> },
    { path: "/display-blogs", element: <DisplayBlogs /> },

    // ----------------------------Blog Section --------------------------->>
    { path: "/add-payment", element: <AddPayment /> },
    { path: "/payment-details", element: <ViewPayments /> },

    // ----------------------------donor--------------------------->>
    // { path: '/add-donation-purpose', element: <Add /> },

    // ----------------------------donation-purpose----------------->>
    { path: "/add-donation-purpose", element: <DonationPurpose /> },
    { path: "/display-all-purpose", element: <DisplayPurposes /> },
    { path: "/view-purpose-details", element: <ViewSinglePurpose /> },

    // ----------------------------project-------------------------->>
    // { path: '/donor-register', element: <AddDonor /> },
    // { path: '/display-donors', element: <DisplayDonors /> },
    // { path: '/donor-info', element: <DonorInfo /> },

    // ----------------------------event------------------------------>>
    { path: "/add-event", element: <AddEvent /> },
    { path: "/display-events", element: <DisplayEvents /> },
    { path: "/view-event-details", element: <ViewSingleEvent /> },

    // ----------------------------event------------------------------>>
    { path: "/calender", element: <CalendarSection /> },

    // ----------------------------calendar--------------------------->>
    // { path: '/donor-register', element: <AddDonor /> },

    // ----------------------------download-reciepts------------------->>
    { path: "/download-receipts", element: <DownloadReciepts /> },
    {
      path: "*",
      element: <NotFound />,
    },
    // ----------------------------financial report--------------------->>
    // { path: '/buget-analysis', element: <EmployeeAttendence /> },
    { path: "/metadata-home", element: <MetaHome /> },
    { path: "/metadata-about-us", element: <MetaAboutUs /> },
  ],
};

export default MainRoutes;