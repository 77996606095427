import React, { useState } from 'react';
import { Grid, Button, TextField, MenuItem, Typography } from '@mui/material';
import Divider from '@mui/material/Divider';
import '../../../Style/Custom.css';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import { registerNgo } from 'api/auth';
import CircularProgress from '@mui/material/CircularProgress';
import { useLocation } from 'react-router';

const AddNgo = () => {
  const location = useLocation();
  const { item } = location?.state || {};
  const [isLoading, setIsLoading] = useState(false);
  const [isCompleted, setIsCompleted] = useState(false);
  const [formData, setFormData] = useState({
    ngoName: item?.ngo_name || '',
    userName: '',
    email: '',
    password: '',
    confirmpassword: '',
    contactNumber1: '',
    contactNumber2: '',
    address1: '',
    address2: '',
    area: '',
    pinCode: '',
    state: '',
    city: '',
    panNumber: '',
    gstNumber: '',
    cinNumber: '',
    bankname: '',
    accountnumber: '',
    accountname: '',
    branchname: '',
    ifscCode: '',
    trialenddate: '',
    trialstartdate: '',
    trialperiodDuration: '',
    subcriptionstartdate: '',
    subscriptionDuration: '',
    subscriptionName: '',
    subscriptionendtdate: '',
    status: '',
    totalemployees: '',
    totalamount: '',
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleStatusChange = () => {
    const newStatus = formData.status === 'active' ? 'inactive' : 'active';
    setFormData({ ...formData, status: newStatus });
  };

  const handleSubmit = async () => {
    setIsLoading(true);
    if (validateForm()) {
      try {
        // setFormData({
        //   ngoName: '',
        //   userName: '',
        //   email: '',
        //   password: '',
        //   confirmpassword: '',
        //   contactNumber1: '',
        //   contactNumber2: '',
        //   address1: '',
        //   address2: '',
        //   area: '',
        //   pinCode: '',
        //   state: '',
        //   city: '',
        //   panNumber: '',
        //   gstNumber: '',
        //   cinNumber: '',
        //   bankname: '',
        //   accountnumber: '',
        //   accountname: '',
        //   branchname: '',
        //   ifscCode: '',
        //   trialenddate: '',
        //   trialstartdate: '',
        //   trialperiodDuration: '',
        //   subcriptionstartdate: '',
        //   subscriptionDuration: '',
        //   subscriptionName: '',
        //   subscriptionendtdate: '',
        //   status: '',
        //   totalemployees: '',
        //   totalamount: ''
        // });
        const payload = {
          ngo_name: formData.ngoName,
          ngo_address: formData.address1,
          ngo_pan_number: formData.panNumber,
          ngo_gst_number: formData.gstNumber,
          ngo_email: formData.email,
          ngo_mobile_number: formData.contactNumber1,
          ngo_website: formData.ngowebsite,
          number_of_directors: parseInt(formData.totalemployees),
          total_emp: formData.totalemployees,
          is_delete: false,
          user_name: formData.userName,
          password: formData.password,
          schema_name: formData.ngoName,
        };

        await registerNgo(payload);
        setIsCompleted(true);
      } catch (err) {
        console.log(err);
      }
    } else {
      console.log('Form validation failed');
    }
    setIsLoading(false);
  };

  const [errors, setErrors] = useState({});

  const handleError = (inputs, value) => {
    setErrors((prev) => ({ ...prev, [inputs]: value }));
  };

  // Validation function
  const validateForm = () => {
    let isValid = true;

    if (!formData.ngoName) {
      handleError('ngoName', 'Please enter  ngo name');
      isValid = false;
    }
    if (!formData.userName) {
      handleError('userName', 'Please enter user name');
      isValid = false;
    }
    if (!formData.email) {
      handleError('email', 'Please enter  email');
      isValid = false;
    } else if (!/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(formData.email)) {
      handleError('email', 'Please enter a valid email address');
      isValid = false;
    }

    if (!formData.password) {
      handleError('password', 'Please enter  password');
      isValid = false;
    }

    if (!formData.confirmpassword) {
      handleError('confirmpassword', 'Please confirm your password');
      isValid = false;
    } else if (formData.password !== formData.confirmpassword) {
      handleError('confirmpassword', 'Passwords do not match');
      isValid = false;
    }
    if (!formData.contactNumber1) {
      handleError('contactNumber1', 'Please enter contact number');
      isValid = false;
    } else if (!/^[0-9]{10}$/.test(formData.contactNumber1)) {
      handleError('contactNumber1', 'Please enter valid contact number');
      isValid = false;
    }

    if (!formData.address1) {
      handleError('address1', 'Please enter  address');
      isValid = false;
    }
    if (!formData.area) {
      handleError('area', 'Please enter  area');
      isValid = false;
    }

    if (!formData.pinCode) {
      handleError('pinCode', 'Please enter your PIN code');
      isValid = false;
    } else if (!/^[0-9]{6}$/.test(formData.pinCode)) {
      handleError('pinCode', 'PIN code must be  6-digit number');
      isValid = false;
    }
    if (!formData.state) {
      handleError('state', 'Please enter your state');
      isValid = false;
    }

    if (!formData.city) {
      handleError('city', 'Please select your city');
      isValid = false;
    }
    if (!formData.gstNumber) {
      handleError('gstNumber', 'Please enter gst number');
      isValid = false;
    }

    if (!formData.panNumber) {
      handleError('panNumber', 'Please enter your PAN number');
      isValid = false;
    } else if (!/^[A-Za-z]{5}[0-9]{4}[A-Za-z]{1}$/.test(formData.panNumber)) {
      handleError('panNumber', 'Please enter a valid pan number');
      isValid = false;
    }

    if (!formData.cinNumber) {
      handleError('cinNumber', 'Please enter cin number');
      isValid = false;
    }

    if (!formData.status) {
      handleError('status', 'Please enter status');
      isValid = false;
    }
    if (!formData.totalemployees) {
      handleError('totalemployees', 'Please enter total employee');
      isValid = false;
    }

    return isValid;
  };
  return (
    <div className="main-container">
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography color="textPrimary" variant="h5" className='heading2'>
            NGO Registration
          </Typography>
          <Divider />
        </Grid>
        <Grid item xs={12}>
          <Typography color="textPrimary" variant="h5">
            Basic Information
          </Typography>
        </Grid>
        <Grid item xs={12} md={12} lg={6}>
          <TextField
            name="ngoName"
            label="NGO Name"
            value={formData.ngoName}
            onChange={handleInputChange}
            variant="outlined"
            required
            fullWidth
            error={!errors.ngoName ? false : true}
            helperText={errors.ngoName}
            onFocus={() => handleError('ngoName', null)}
          />
        </Grid>
        <Grid item xs={12} md={12} lg={6}>
          <TextField
            name="email"
            label="NGO Email Id"
            value={formData.email}
            onChange={handleInputChange}
            variant="outlined"
            required
            fullWidth
            error={!errors.email ? false : true}
            helperText={errors.email}
            onFocus={() => handleError('email', null)}
          />
        </Grid>
        <Grid item xs={12} md={12} lg={6}>
          <TextField
            name="userName"
            label="User Name"
            type="username"
            value={formData.userName}
            onChange={handleInputChange}
            variant="outlined"
            required
            fullWidth
          />
        </Grid>

        <Grid item xs={12} md={12} lg={6}>
          <TextField
            name="password"
            label="Password"
            type="password"
            value={formData.password}
            onChange={handleInputChange}
            variant="outlined"
            required
            fullWidth
            error={!errors.password ? false : true}
            helperText={errors.password}
            onFocus={() => handleError('password', null)}
          />
        </Grid>
        <Grid item xs={12} md={12} lg={6}>
          <TextField
            name="confirmpassword"
            label="Confirm Password"
            type="password"
            value={formData.confirmpassword}
            onChange={handleInputChange}
            variant="outlined"
            required
            fullWidth
            error={!errors.confirmpassword ? false : true}
            helperText={errors.confirmpassword}
            onFocus={() => handleError('confirmpassword', null)}
          />
        </Grid>
        <Grid item xs={12} md={12} lg={6}>
          <TextField
            name="contactNumber1"
            label="NGO Contact Number 1"
            value={formData.contactNumber1}
            onChange={handleInputChange}
            variant="outlined"
            required
            fullWidth
            error={!errors.contactNumber1 ? false : true}
            helperText={errors.contactNumber1}
            onFocus={() => handleError('contactNumber1', null)}
          />
        </Grid>
        <Grid item xs={12} md={12} lg={6}>
          <TextField
            name="contactNumber2"
            label="NGO Contact Number 2"
            value={formData.contactNumber2}
            onChange={handleInputChange}
            variant="outlined"
            fullWidth
            error={!errors.contactNumber2 ? false : true}
            helperText={errors.contactNumber2}
            onFocus={() => handleError('contactNumber2', null)}
          />
        </Grid>
        <Grid item xs={12} md={12} lg={6}>
          <TextField
            name="ngowebsite"
            label=" WebSite"
            value={formData.ngowebsite}
            onChange={handleInputChange}
            variant="outlined"
            fullWidth
            error={!errors.ngowebsite ? false : true}
            helperText={errors.ngowebsite}
            onFocus={() => handleError('ngowebsite', null)}
          />
        </Grid>
      </Grid>
      <Grid item className='mt_35px'>
        <Typography color="textPrimary" variant="h5">
          Address Information
        </Typography>
      </Grid>
      <Grid container spacing={2} className='mt_2px'>
        <Grid item xs={12} md={12} lg={6}>
          <TextField
            name="address1"
            label="Address Line - 1"
            value={formData.address1}
            onChange={handleInputChange}
            variant="outlined"
            required
            fullWidth
            error={!errors.address1 ? false : true}
            helperText={errors.address1}
            onFocus={() => handleError('address1', null)}
          />
        </Grid>
        <Grid item xs={12} md={12} lg={6}>
          <TextField
            name="address2"
            label="Address Line - 2"
            value={formData.address2}
            onChange={handleInputChange}
            variant="outlined"
            fullWidth
          />
        </Grid>
        <Grid item xs={12} md={12} lg={6}>
          <TextField
            name="area"
            label="Area"
            value={formData.area}
            onChange={handleInputChange}
            variant="outlined"
            required
            fullWidth
            error={!errors.area ? false : true}
            helperText={errors.area}
            onFocus={() => handleError('area', null)}
          />
        </Grid>

        <Grid item xs={12} md={12} lg={6}>
          <TextField
            select
            name="state"
            label="State"
            value={formData.state}
            onChange={handleInputChange}
            variant="outlined"
            required
            fullWidth
            error={!errors.state ? false : true}
            helperText={errors.state}
            onFocus={() => handleError('state', null)}
          >
            <MenuItem value="Indore">Madhya Pradesh</MenuItem>
            <MenuItem value="Jaipur">Rajasthan</MenuItem>
          </TextField>
        </Grid>
        <Grid item xs={12} md={12} lg={6}>
          <TextField
            select
            name="city"
            label="City"
            value={formData.city}
            onChange={handleInputChange}
            variant="outlined"
            required
            fullWidth
            error={!errors.city ? false : true}
            helperText={errors.city}
            onFocus={() => handleError('city', null)}
          >
            <MenuItem value="Indore">Indore</MenuItem>
            <MenuItem value="Jaipur">Jaipur</MenuItem>
          </TextField>
        </Grid>
        <Grid item xs={12} md={12} lg={6}>
          <TextField
            name="pinCode"
            label="PIN Code"
            value={formData.pinCode}
            onChange={handleInputChange}
            variant="outlined"
            required
            fullWidth
            error={!errors.pinCode ? false : true}
            helperText={errors.pinCode}
            onFocus={() => handleError('pinCode', null)}
          />
        </Grid>
      </Grid>

      <Grid item className='mt_35px'>
        <Typography color="textPrimary" variant="h5">
          Offical Details
        </Typography>
      </Grid>
      <Grid container spacing={2} className='mt_2px'>
        <Grid item xs={12} md={12} lg={6}>
          <TextField
            name="gstNumber"
            label="GST Number"
            value={formData.gstNumber}
            onChange={handleInputChange}
            variant="outlined"
            required
            fullWidth
            error={!errors.gstNumber ? false : true}
            helperText={errors.gstNumber}
            onFocus={() => handleError('gstNumber', null)}
          />
        </Grid>
        <Grid item xs={12} md={12} lg={6}>
          <TextField
            name="cinNumber"
            label="CIN Number"
            value={formData.cinNumber}
            onChange={handleInputChange}
            variant="outlined"
            required
            fullWidth
            error={!errors.cinNumber ? false : true}
            helperText={errors.cinNumber}
            onFocus={() => handleError('cinNumber', null)}
          />
        </Grid>
        <Grid item xs={12} md={12} lg={6}>
          <TextField
            name="panNumber"
            label="PAN Number"
            value={formData.panNumber}
            onChange={handleInputChange}
            variant="outlined"
            required
            fullWidth
            error={!errors.panNumber ? false : true}
            helperText={errors.panNumber}
            onFocus={() => handleError('panNumber', null)}
          />
        </Grid>
        <Grid item xs={12} md={12} lg={6}>
          <TextField
            name="totalemployees"
            label="Total Employee "
            value={formData.totalemployees}
            onChange={handleInputChange}
            variant="outlined"
            required
            fullWidth
            error={!errors.totalemployees ? false : true}
            helperText={errors.totalemployees}
            onFocus={() => handleError('totalemployees', null)}
          />
        </Grid>
      </Grid>

      <Grid item className='mt_35px'>
        <Typography color="textPrimary" variant="h5">
          Bank Details
        </Typography>
      </Grid>
      <Grid container spacing={2} className='mt_2px'>
        <Grid item xs={12} md={12} lg={6}>
          <TextField
            name="bankname"
            label="Bank Name "
            value={formData.bankname}
            onChange={handleInputChange}
            variant="outlined"
            fullWidth
          />
        </Grid>
        <Grid item xs={12} md={12} lg={6}>
          <TextField
            name="accountname"
            label="Account Name"
            value={formData.accountname}
            onChange={handleInputChange}
            variant="outlined"
            fullWidth
          />
        </Grid>
        <Grid item xs={12} md={12} lg={6}>
          <TextField
            name="accountnumber"
            label="Account Number"
            value={formData.accountnumber}
            onChange={handleInputChange}
            variant="outlined"
            fullWidth
          />
        </Grid>

        <Grid item xs={12} md={12} lg={6}>
          <TextField
            name="ifscCode"
            label="IFSC Code "
            value={formData.ifscCode}
            onChange={handleInputChange}
            variant="outlined"
            fullWidth
          />
        </Grid>
        <Grid item xs={12} md={12} lg={6}>
          <TextField
            name="branchname"
            label="Auto Fetched from IFSC "
            value={formData.branchname}
            onChange={handleInputChange}
            variant="outlined"
            fullWidth
            disabled
          />
        </Grid>
      </Grid>

      <Grid item className='mt_35px'>
        <Typography color="textPrimary" variant="h5">
          Subscription/Renual
        </Typography>
      </Grid>
      <Grid className='mt_2px' container spacing={2} className='mt_2px'>
        <Grid item xs={12} md={12} lg={6}>
          <TextField
            name="subscriptionName"
            label="Subscription Name"
            value={formData.subscriptionName}
            onChange={handleInputChange}
            variant="outlined"
            fullWidth
          />
        </Grid>
        <Grid item xs={12} md={12} lg={6}>
          <TextField
            select
            name="subscriptionDuration"
            label="Subscription Duration"
            value={formData.subscriptionDuration}
            onChange={handleInputChange}
            variant="outlined"
            fullWidth
          >
            <MenuItem value="week">Weekly</MenuItem>
            <MenuItem value="month">Monthly</MenuItem>
          </TextField>
        </Grid>

        <Grid item xs={12} md={12} lg={6}>
          <TextField
            name="subcriptionstartdate"
            type="date"
            label="Subscription Start Date"
            value={formData.subcriptionstartdate}
            onChange={handleInputChange}
            variant="outlined"
            InputLabelProps={{ shrink: true }}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} md={12} lg={6}>
          <TextField
            name="subscriptionendtdate"
            type="date"
            label="Subscription End Date"
            value={formData.subscriptionendtdate}
            onChange={handleInputChange}
            variant="outlined"
            InputLabelProps={{ shrink: true }}
            fullWidth
          />
        </Grid>
      </Grid>

      <Grid item className='mt_35px'>
        <Typography color="textPrimary" variant="h5">
          Status
        </Typography>
      </Grid>
      <Grid container spacing={2} className='mt_2px'>
        <Grid item xs={12} md={12} lg={6}>
          {/* <TextField
            select
            name="status"
            label="Status"
            value={formData.status}
            onChange={handleInputChange}
            variant="outlined"
            required
            fullWidth
            error={!errors.status ? false : true}
            helperText={errors.status}
            onFocus={() => handleError('status', null)}
          >
            <MenuItem value="active">Active</MenuItem>
            <MenuItem value="inactive">Inactive</MenuItem>
          </TextField> */}
          <FormGroup>
            <FormControlLabel
              control={<Switch checked={formData.status === 'active'} onChange={handleStatusChange} />}
              label={formData.status === 'active' ? 'Active' : 'Inactive'}
            />
          </FormGroup>
        </Grid>

        <Grid item xs={12} style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center' }}>
          <Button
            variant="contained"
            disabled={isLoading}
            onClick={handleSubmit}
            startIcon={isLoading ? <CircularProgress size={20} color="inherit" /> : null}
            style={{ width: '10%' }}
          >
            Register
          </Button>
          {isCompleted && (
            <Typography color="textPrimary" variant="h5">
              NGO registration is successfull.
            </Typography>
          )}
        </Grid>
      </Grid>
    </div>
  );
};

export default AddNgo;
