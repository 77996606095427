import React, { useRef, useState } from "react";
import { Grid, Typography, Divider, Button, IconButton } from "@mui/material";
import "../../../Style/Custom.css";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { useNavigate } from "react-router";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import { useReactToPrint } from 'react-to-print';
import NGOLogo from "component/Logo";

const ViewSinglePurpose = () => {
  const navigate = useNavigate();

  const handleClickBack = () => {
    navigate("/display-all-purpose");
  };
  const componentPDF = useRef();
  const [downloadInProgress, setDownloadInProgress] = useState(false);

  const handleDownloadClick = () => {
    setDownloadInProgress(true);

    setTimeout(() => {
      generatePDF();
    }, 50);
  };


  const generatePDF = () => {
    setDownloadInProgress(true);
    generatePDFPrint();
  }
  const generatePDFPrint = useReactToPrint({
    content: () => componentPDF.current,
    documentTitle: "userData",
    onAfterPrint: () => setDownloadInProgress(false),
  });

  return (
    <div className="main-container-single">
      <div spacing={0} className="d-flex-space-bw">
        <div>
          <Button onClick={handleClickBack} variant="text">
            <IconButton
              onClick={handleClickBack}
              aria-label="edit"
              color="primary"
            >
              <ArrowBackIosIcon />
            </IconButton>
            Back
          </Button>
        </div>

        <div>
          <Button onClick={handleDownloadClick} variant="text">
            <IconButton onClick={handleDownloadClick} aria-label="edit" color="primary">
              <ArrowDownwardIcon />
            </IconButton>
            Download
          </Button>
        </div>
      </div>

      <Grid container spacing={3} className="pdf-grid" ref={componentPDF}>
        {downloadInProgress && (
          <div className="pdf-topbar">
            <div className="pdf-top-div">
              <Typography color="#fff" gutterBottom variant="h4">
                NGO-org pvt. ltd.
              </Typography>
              <div>address line 1</div>
              <div>address line 2</div>
            </div>
            <NGOLogo />
          </div>
        )}
        <div className="d-flex-center95" >
          <Typography variant="h5">Purpose Details</Typography>
        </div>
        <Grid container>
          <Grid item xs={12}>
            <Divider />
          </Grid>
        </Grid>
        <Grid item xs={6} lg={6}>
          <Typography variant="h6">Purpose Type:</Typography>
          <Typography variant="body1">For an Event</Typography>
        </Grid>
        <Grid item xs={6} lg={6}>
          <Typography variant="h6">Event Type:</Typography>
          <Typography variant="body1">Webinar</Typography>
        </Grid>
        <Grid item xs={6} lg={6}>
          <Typography variant="h6">Purpose Title:</Typography>
          <Typography variant="body1">
            Empowering Digital Education for Underprivileged Youth
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h6">Description about the Purpose:</Typography>
          <Typography variant="body1">
            <p>
              We are passionately committed to a cause that aims to empower
              underprivileged youth through digital education. In recognizing
              the transformative power of technology, we invite you to join our
              initiative in bridging the digital divide. Your support will
              enable these young minds to access crucial digital learning
              resources, unlocking a world of opportunities and preparing them
              for success in the digital age.
            </p>
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Divider />
        </Grid>
      </Grid>
    </div>
  );
};

export default ViewSinglePurpose;
