import React, { useEffect, useState } from "react";
import "react-big-calendar/lib/css/react-big-calendar.css";
import { Calendar, momentLocalizer } from "react-big-calendar";
import moment from "moment";
import { getAllEvent } from "api/auth";

const CalendarComponent = () => {
  moment.locale("en-GB");
  const localizer = momentLocalizer(moment);
  const events = [
    {
      title: "All Day Event very long title",
      allDay: true,
      start: new Date(2024, 3, 0),
      end: new Date(2024, 3, 1),
    },
    {
      title: "Long Event",
      start: new Date(2015, 3, 7),
      end: new Date(2015, 3, 10),
    },

    {
      title: "All Day Event very long title",
      allDay: true,
      start: new Date(2015, 3, 0),
      end: new Date(2015, 3, 1),
    },

    {
      title: "DTS ENDS",
      start: new Date(2016, 10, 6, 0, 0, 0),
      end: new Date(2016, 10, 13, 0, 0, 0),
    },

    {
      title: "Some Event",
      start: new Date(2015, 3, 9, 0, 0, 0),
      end: new Date(2015, 3, 9, 0, 0, 0),
    },
    {
      title: "Conference",
      start: new Date(2015, 3, 11),
      end: new Date(2015, 3, 13),
      desc: "Big conference for important people",
    },
  ];
  const [allEventList, setAllEventList] = React.useState([]);

  const [eventsData, setEventsData] = useState(events);
  useEffect(() => {
    const fetchData = async () => {
      const res = await getAllEvent();
      const eventsWithId = res?.data?.map((event, index) => ({
        ...event,
        id: index + 1,
        start: new Date(event.event_date_time),
        end: new Date(event.event_date_time),
      }));
      setAllEventList(eventsWithId);
    };
    fetchData();
  }, []);

  const handleSelect = ({ start, end }) => {
    const title = window.prompt("New Event name");
    if (title)
      setEventsData([
        ...eventsData,
        {
          start,
          end,
          title,
        },
      ]);
  };
  return (
    <div style={{ height: 700 }}>
      <Calendar
        views={["day", "agenda", "work_week", "month"]}
        selectable
        localizer={localizer}
        defaultDate={new Date()}
        defaultView="month"
        events={allEventList}
        style={{ height: "100vh" }}
        onSelectEvent={(event) => alert(event.title)}
        onSelectSlot={handleSelect}
      />
    </div>
  );
};

export default CalendarComponent;
