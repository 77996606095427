import React, { useRef, useState } from "react";
import { Grid, Button, TextField, Typography, Divider } from "@mui/material";

import "../../Style/Custom.css";
import { CheckBox } from "@mui/icons-material";

const Permission = ({ selectedCheckboxes, setSelectedCheckboxes, onChange }) => {
  const handleCheckboxChange = (parentId, childId) => {
    setSelectedCheckboxes((prevSelectedCheckboxes) => ({
      ...prevSelectedCheckboxes,
      [parentId]: {
        ...prevSelectedCheckboxes[parentId],
        [childId]: !prevSelectedCheckboxes[parentId]?.[childId],
      },
    }));
  };

  // const handleCheckboxChange = (parentId, childId) => {
  //   setSelectedCheckboxes((prevSelectedCheckboxes) => {
  //     const updatedCheckboxes = { ...prevSelectedCheckboxes };
  //     const parentName = InitialLink.find((item) => item.id === parentId)?.name;

  //     if (!updatedCheckboxes[parentName]) {
  //       updatedCheckboxes[parentName] = [];
  //     }
  //     const childLnk = `/${InitialLink.find((item) => item.id === parentId)?.childLnk.find((child) => child.id === childId)?.lnk}`;

  //     if (updatedCheckboxes[parentName].includes(childLnk)) {
  //       updatedCheckboxes[parentName] = updatedCheckboxes[parentName].filter(
  //         (item) => item !== childLnk
  //       );
  //     } else {
  //       updatedCheckboxes[parentName].push(childLnk);
  //     }
  //     return { ...updatedCheckboxes };
  //   });
  // };

  const InitialLink = [
    {
      mainLnk: "",
      childLnk: [
        { name: "Display Member", lnk: "/display-member", id: "1" },
        { name: "Add Member", lnk: "/add-member", id: "2" },
        { name: "Add Member Type", lnk: "/add-member-type", id: "3" },
      ],
      name: "Member",
      id: "1",
    },
    {
      mainLnk: "",
      childLnk: [
        { name: "Display Employees", lnk: "/display-employee", id: "1" },
        { name: "Add Empolyee", lnk: "/add-employee", id: "2" },
        { name: "Employee Attendence", lnk: "/employee-attendance", id: "3" },
      ],
      name: "Employee",
      id: "2",
    },
    {
      mainLnk: "",
      childLnk: [
        { name: "Display Donors", lnk: "/display-donors", id: "1" },
        { name: "Add Donor", lnk: "/donor-register", id: "2" },
      ],
      name: "Donor",
      id: "3",
    },
  ];

  return (
    <div className="main-container">
      <Grid item style={{ marginTop: "35px", marginBottom: "15px" }}>
        <div
          style={{
            marginTop: "20px",
            border: "1px solid #e8e3e3",
            borderRadius: "5px",
          }}
        >
          {InitialLink?.map((ele, i) => (
            <div
              key={i}
              // style={{
              //   display: "flex",
              //   padding: "0px 3px 0px 17px",
              //   gap: " 15px",

              //   borderBottom: "1px solid #e8e3e3",
              // }}
              className="permission1"
            >
              <div className="permission">
                <div>
                  {" "}
                  <input type="checkbox" style={{ marginTop: "5px" }} />
                </div>
                <div>{ele?.name}</div>
              </div>
              {ele?.childLnk?.map((content, i) => (
                <div key={i} className="permission">
                  <div>
                    <input
                      type="checkbox"
                      style={{ marginTop: "5px" }}
                      checked={selectedCheckboxes?.[ele?.mainLnk]?.[content?.lnk]}
                      onChange={(e) => {
                        onChange && onChange(ele, content, e);
                        handleCheckboxChange(ele.mainLnk, content.lnk);
                      }}
                    />
                  </div>
                  <div>{content?.name}</div>
                </div>
              ))}
            </div>
          ))}
        </div>
      </Grid>
    </div>
  );
};

export default Permission;
