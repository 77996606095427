import * as React from 'react';
import { DataGrid } from '@mui/x-data-grid';
import { Typography, IconButton } from '@mui/material';
import EditNoteIcon from '@mui/icons-material/EditNote';
import { useNavigate } from 'react-router';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { useEffect, useState } from 'react';
import { getNgo } from 'api/ngo';

const ViewNgo = () => {
  const navigate = useNavigate();
  const [data, setData] = useState([]);

  const fetchNGO = async () => {
    try {
      const res = await getNgo();
      const _data = res?.map((item, index) => {
        return { ...item, id: item?._id, index: index + 1 }
      })
      setData(_data);
    } catch (err) {
      console.log(err)
    }
  }

  useEffect(() => {
    fetchNGO();
  }, [])


  const columns = [
    { field: 'index', headerName: 'S.NO', width: 125, minWidth: 125, maxWidth: 200 },
    { field: 'ngo_name', headerName: 'NGO Name', width: 125, minWidth: 125, maxWidth: 200 },
    { field: 'ngo_address', headerName: 'Address', width: 125, minWidth: 125, maxWidth: 200 },

    { field: 'ngo_mobile_number', headerName: 'Contact Number', width: 125, minWidth: 125, maxWidth: 200 },
    { field: 'ngo_website', headerName: 'Website', width: 125, minWidth: 125, maxWidth: 200 },

    {
      field: 'action',
      headerName: 'Actions',
      width: 125, minWidth: 125, maxWidth: 200,
      renderCell: (item) => (
        <div>
          <IconButton color="warning" aria-label="edit" onClick={() => handleEdit(item)}>
            <EditNoteIcon />
          </IconButton>
          <IconButton color="primary"aria-label="view" onClick={() => handleView(item)}>
            <VisibilityIcon />
          </IconButton>
        </div>
      )
    }
  ];

  const handleEdit = (item) => {
    navigate('/ngo-register', { state: { item: item?.row || {} } });
  };
  const handleView = (item) => {
    navigate('/view-ngo-details', { state: { item: item?.row || {} } });
  };
  return (
    <div
      // style={{ height: 400, width: '100%', backgroundColor: 'white', padding: '10px', borderRadius: '8px' }}
      style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
    >
      <div className='table-common'>
        <Typography variant="h5" className='heading1'>
          NGO Information
        </Typography>
        <div style={{ height: 'calc(100% - 48px)',width:'100%',overflow:'auto' }}>
          <DataGrid sx={{minWidth:'750px !important'}} rows={data} columns={columns} pageSize={5} />
        </div>
      </div>
    </div>
  );
};

export default ViewNgo;
