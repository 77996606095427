import * as React from "react";
import { DataGrid } from "@mui/x-data-grid";
import {
  Typography,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  Grid,
  TextField,
  MenuItem,
  FormControl,
  InputLabel,
  Select,
  DialogActions,
  Button,
  Divider,
} from "@mui/material";
import EditNoteIcon from "@mui/icons-material/EditNote";
import { useNavigate } from "react-router";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { useEffect, useState, useRef } from "react";
import { getPayments } from "api/donor";
import { Download } from "@mui/icons-material";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import { useReactToPrint } from 'react-to-print';
import { CSVLink } from "react-csv";
import SearchIcon from '@mui/icons-material/Search';
import InputAdornment from '@mui/material/InputAdornment';
import DownloadHeader from "component/DownloadHeader";

const ViewPayments = () => {
  const [searchInput, setSearchInput] = useState("");

  const handleSearchChange = (e) => {
    setSearchInput(e.target.value);
  };
  const componentPDF = useRef();
  const componentPDFTable = useRef();

  const navigate = useNavigate();
  const [downloadInProgress, setDownloadInProgress] = useState(false);

  const [rows, setRows] = React.useState([]);
  const [open, setOpen] = useState(false);

  const handleClickOpen = (rowData) => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const fetchUsers = async () => {
    try {
      const _user = JSON.parse(sessionStorage.getItem("user"));
      const res = await getPayments(_user.schema_name);
      const res_ = res?.data?.map((item, i) => {
        return {
          ...item,
          id: item._id,
          index: i,
        };
      });
      setRows(res_);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    fetchUsers();
  }, []);


  const generatePDF = useReactToPrint({
    content: () => componentPDF.current,
    documentTitle: "userData",
    // onAfterPrint:()=>alert("PDF generated successfully")
  });

  // table
  const handleDownloadClickTable = () => {
    setDownloadInProgress(true);
    setTimeout(() => {
      generatePDFPrintTable();
    }, 50);
  };
  const generatePDFPrintTable = useReactToPrint({
    content: () => componentPDFTable.current,
    documentTitle: "payments_data",
    onStart: () => setDownloadInProgress(true),
    onAfterPrint: () => setDownloadInProgress(false),
  });
  const formatDataForCSV = () => {
    const formattedData = rows.map((row) => ({
      "S.No": row.index,
      "Payment Amount": row.amount,
      "Payment Date": row.date,
      "Contact Number": row.mobile_number,
      "Payment Type": row.type,
      "Note": row.note,
    }));
    return formattedData;
  };

  const columns = [
    { field: "index", headerName: "S.No", width: 125, minWidth: 125, maxWidth: 200 },
    { field: "type", headerName: "Payment Type", width: 125, minWidth: 125, maxWidth: 200 },
    { field: "amount", headerName: "Payment Amount", width: 125, minWidth: 125, maxWidth: 200 },
    { field: "date", headerName: "Payment Date", width: 125, minWidth: 125, maxWidth: 200 },
    { field: "note", headerName: "Payment Note", width: 125, minWidth: 125, maxWidth: 200 },
  ];
  if (!downloadInProgress) {
    columns.push(
      {
        field: "action",
        headerName: "Action",
        width: 125, minWidth: 125, maxWidth: 200,
        renderCell: (item) => (
          <div>
            <IconButton color="primary" aria-label="edit" onClick={() => handleEdit(item)}>
              <EditNoteIcon />
            </IconButton>
          </div>
        ),
      },
    )
  }
  const handleEdit = (item) => {
    navigate("/payment-update", {
      state: { item: item?.row || {}, isUpdate: true },
    });
  };
  // const handleView = (item) => {
  //   navigate("/view-member-details", { state: { item: item?.row || {} } });
  // };
  //   const filteredPayment = rows.filter((payment) =>
  //   payment.payment_type.toLowerCase().includes(searchInput.toLowerCase())
  // );

  return (
    <div
      className="dFlex justifyCenter alignCenter">
      <div className="table-common">
        <div spacing={0} className="d-flex-space-bw">
          <div >
            <TextField
              type="text"
              size="small"
              placeholder="Search"
              value={searchInput}
              onChange={handleSearchChange}
              variant="outlined"
              fullWidth
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
            />
          </div>
          <div>
            <Typography variant="h5">
              Payment Information
            </Typography>
          </div>
          <div>
            <Button onClick={handleDownloadClickTable} variant="text">
              <IconButton onClick={handleDownloadClickTable} aria-label="edit" color="primary">
                <ArrowDownwardIcon />
              </IconButton>
              Download
            </Button>
            <Button variant="text">
              <CSVLink
                data={formatDataForCSV()}
                filename={"All_Payments.csv"}
                className="csv-link"
                target="_blank"
              >
                <IconButton aria-label="edit" color="primary">
                  <ArrowDownwardIcon />
                </IconButton>
                CSV
              </CSVLink>
            </Button>
          </div>
        </div>

        <div ref={componentPDFTable} style={{ padding: downloadInProgress ? "20px" : "0px" }}>
          {downloadInProgress && (
            <DownloadHeader />
          )}
          <div style={{ height: "calc(100% - 48px)",width:'100%',overflow:'auto' }}>
            <DataGrid sx={{minWidth:'750px !important'}} rows={rows} columns={columns} pageSize={5} />
          </div>
        </div>
      </div>

      <Dialog
        // fullScreen={fullScreen}
        open={open}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">
          {"All Member Details"}
        </DialogTitle>
        <DialogContent>
          <div ref={componentPDF}>
            {/* credit receipt*/}
            <div className="main-container-single">
              <div spacing={0} className="d-flex-space-bw">
                <div className="d-flex-center95">
                  <Typography variant="h5">Member Information</Typography>
                </div>
              </div>
              <Grid container>
                <Grid item xs={12}>
                  <Divider />
                </Grid>
              </Grid>
            </div>
          </div>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleClose}>
            Cancel
          </Button>
          <Button onClick={generatePDF} autoFocus>Download receipt</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default ViewPayments;
