import React, { useRef, useState } from "react";
import {
  Grid,
  Button,
  TextField,
  Typography,
  MenuItem,
  Divider,
} from "@mui/material";
import { DropzoneArea } from "react-mui-dropzone";

const initalState = {
  purposeType: "",
  title: "",
  description: "",
};

const DonationPurpose = () => {
  const [formData, setFormData] = useState(initalState);

  const handleInputChange = (e) => {
    if (e.target) {
      const { name, value } = e.target;
      setFormData((prevData) => ({ ...prevData, [name]: value }));
    }
  };

  const fileInputRef = useRef(null);

  const handleSubmit = () => {
    if (validateForm()) {
      const { purposeType, title, description } = formData;
      const formDataApi = new FormData();
      formDataApi.append("purposeType", purposeType);
      formDataApi.append("title", title);
      formDataApi.append("description", description);

      if (fileInputRef.current?.files[0]) {
        formDataApi.append(
          "gstRegistrationImageUrl",
          fileInputRef.current.files[0]
        );
      }
      // if (updateForm) {
      //   formDataApi.append("schemaName", loanData?.schemaName);
      // }

      setFormData(initalState);
    } else {
      console.log("Form validation failed");
    }
  };

  const [errors, setErrors] = useState({});

  const handleError = (inputs, value) => {
    setErrors((prev) => ({ ...prev, [inputs]: value }));
  };

  // Validation function
  const validateForm = () => {
    let isValid = true;
    if (!formData.title) {
      handleError("title", "Please Enter the Title/Cause");
      isValid = false;
    }
    if (!formData.purposeType) {
      handleError("purposeType", "Please Select Purpose Type ");
      isValid = false;
    }
    if (!formData.description) {
      handleError("description", "Please Enter a description ");
      isValid = false;
    }
    return isValid;
  };
  return (
    <div className="main-container">
      <Grid item>
        <Typography color="textPrimary" variant="h5" className="heading2">
          Donation Purpose
        </Typography>
        <Divider />
      </Grid>
      <Grid item className="mt_35px">
        {/* <Typography color="textPrimary" variant="h5">
          General Information
        </Typography> */}
      </Grid>
      <Grid container className="mt_2px" spacing={2}>
        <Grid item xs={12} md={12} lg={6}>
          <TextField
            select
            name="purposeType"
            label="Select Purpose"
            value={formData.purposeType}
            onChange={handleInputChange}
            variant="outlined"
            fullWidth
            error={!errors.purposeType ? false : true}
            helperText={errors.purposeType}
            onFocus={() => handleError("purposeType", null)}
          >
            <MenuItem disabled value="individual">
              Select Purpose
            </MenuItem>
            <MenuItem value="individual">For an Event</MenuItem>
            <MenuItem value="organization">For Donation</MenuItem>
          </TextField>
        </Grid>
        <Grid item xs={12} md={12} lg={6}>
          <TextField
            select
            name="purposeType"
            label="Select Event/Cause Type"
            value={formData.purposeType}
            onChange={handleInputChange}
            variant="outlined"
            fullWidth
            error={!errors.purposeType ? false : true}
            helperText={errors.purposeType}
            onFocus={() => handleError("purposeType", null)}
          >
            <MenuItem disabled value="individual">
              Select Event/Cause Type
            </MenuItem>
            <MenuItem value="individual">Webinar</MenuItem>
            <MenuItem value="organization">Seminar</MenuItem>
            <MenuItem value="organization">Workshop</MenuItem>
            <MenuItem value="organization">Other Purpose</MenuItem>
          </TextField>
        </Grid>
        <Grid item xs={12} md={12} lg={6}>
          <TextField
            name="title"
            label="Event/Cause Title"
            value={formData.title}
            onChange={handleInputChange}
            variant="outlined"
            required
            fullWidth
            error={!errors.title ? false : true}
            helperText={errors.title}
            onFocus={() => handleError("title", null)}
          />
        </Grid>

        <Grid item xs={12} md={12} lg={6}>
          <TextField
            name="description"
            label="Add Description Here"
            value={formData.description}
            onChange={handleInputChange}
            variant="outlined"
            required
            multiline
            fullWidth
            error={!errors.description ? false : true}
            helperText={errors.description}
            onFocus={() => handleError("description", null)}
          />
        </Grid>
        <Grid item xs={12}>
          <DropzoneArea
            acceptedFiles={["image/*", "video/*", "audio/*"]}
            filesLimit={3}
            dropzoneText="Drag and drop or click here to add media (audio/image/video)"
            onChange={(files) => {}}
          />
        </Grid>
        <Grid item xs={12} className="dFlex justifyCenter">
          <Button variant="contained" onClick={handleSubmit}>
            Register
          </Button>
        </Grid>
      </Grid>
    </div>
  );
};

export default DonationPurpose;
