import * as React from "react";
import { DataGrid } from "@mui/x-data-grid";
import {
  Typography,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  Grid,
  TextField,
  MenuItem,
  FormControl,
  InputLabel,
  Select,
  DialogActions,
  Button,
  Divider,
} from "@mui/material";
import EditNoteIcon from "@mui/icons-material/EditNote";
import { useNavigate } from "react-router";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { useEffect, useState, useRef } from "react";
import { getMembers } from "api/user";
import { DeleteForever, Download } from "@mui/icons-material";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import { useReactToPrint } from 'react-to-print';
import SearchIcon from '@mui/icons-material/Search';
import InputAdornment from '@mui/material/InputAdornment';
import { CSVLink } from "react-csv";

const DisplayMemberType = () => {
  const componentPDF = useRef();

  const navigate = useNavigate();
  const [rows, setRows] = React.useState([]);
  const [open, setOpen] = useState(false);
  const [searchInput, setSearchInput] = useState("");

  const handleSearchChange = (e) => {
    setSearchInput(e.target.value);
  };
  const handleClickOpen = (rowData) => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const fetchUsers = async () => {
    try {
      const res = await getMembers();
      const res_ = res?.data?.map((item, i) => {
        return {
          ...item,
          id: item._id,
          index: i,
        };
      });
      setRows(res_?.filter((i) => i.user_type === "member"));
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    fetchUsers();
  }, []);


  const generatePDF = useReactToPrint({
    content: () => componentPDF.current,
    documentTitle: "userData",
    // onAfterPrint:()=>alert("PDF generated successfully")
  });
  const formatDataForCSV = () => {
    const formattedData = rows.map((row) => ({
      "S.No": row.index,
      "member_category": row.user_type,
      "Contact Number": row.mobile_number,
      "Address": row.address_1,
      "Amount": row.amount,
    }));
    return formattedData;
  };

  const columns = [
    { field: "index", headerName: "S.No", width: 125, minWidth: 125, maxWidth: 200 },
    { field: "member_category", headerName: "Member category", width: 125, minWidth: 125, maxWidth: 200 },
    { field: "amount", headerName: "Amount", width: 125, minWidth: 125, maxWidth: 200 },

    { field: "mobile_number", headerName: "Contact Number", width: 125, minWidth: 125, maxWidth: 200 },
    // { field: "address_1", headerName: "Address", flex: 1 },
    {
      field: "action",
      headerName: "Action",
      width: 125, minWidth: 125, maxWidth: 200,
      renderCell: (item) => (
        <div>
          <IconButton color="warning" aria-label="edit" onClick={() => handleEdit(item)}>
            <EditNoteIcon />
          </IconButton>
          <IconButton color="error" aria-label="delete">
            <DeleteForever />
          </IconButton>

          {/* <IconButton
            aria-label="delete"
            color="error"
            onClick={() => handleClickOpen(item)}
          >
            <Download />
          </IconButton> */}
        </div>
      ),
    },
  ];
  const handleEdit = (item) => {
    navigate("/add-member-type", {
      state: { item: item?.row || {}, isUpdate: true },
    });
  };
  // const handleView = (item) => {
  //   navigate("/view-member-details", { state: { item: item?.row || {} } });
  // };
  const filteredMemberType = rows.filter((member) =>
    member.user_type.toLowerCase().includes(searchInput.toLowerCase())
  );


  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <div className="table-common">
        <div spacing={0} className="d-flex-space-bw">
          <div >
            <TextField
              type="text"
              size="small"
              placeholder="Search"
              value={searchInput}
              onChange={handleSearchChange}
              variant="outlined"
              fullWidth
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
            />
          </div>
          <div className='d-flex-center90'>
            <Typography variant="h5">
              Member Category Information
            </Typography>
          </div>
          <div>
            <Button variant="text" className="mr-10">
              <CSVLink
                data={formatDataForCSV()}
                filename={"member_Type.csv"}
                className="csv-link"
                target="_blank"
              >
                <IconButton aria-label="edit" color="primary">
                  <ArrowDownwardIcon />
                </IconButton>
                CSV
              </CSVLink>
            </Button>
          </div>
        </div>

        <div style={{ height: "calc(100% - 48px)", width: '100%', overflow: 'auto' }}>
          <DataGrid sx={{ minWidth: '750px !important' }} rows={filteredMemberType} columns={columns} pageSize={5} />
        </div>
      </div>

      <Dialog
        // fullScreen={fullScreen}
        open={open}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">
          {"All Member Details"}
        </DialogTitle>
        <DialogContent>
          <div ref={componentPDF}>
            {/* credit reciept*/}
            <div className="main-container-single">
              <div spacing={0} className="d-flex-space-bw">
                <div >
                  <TextField
                    type="text"
                    size="small"
                    placeholder="Search"
                    value={searchInput}
                    onChange={handleSearchChange}
                    variant="outlined"
                    fullWidth
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <SearchIcon />
                        </InputAdornment>
                      ),
                    }}
                  />
                </div>
                <div className="d-flex-center95">
                  <Typography variant="h5">Member Information</Typography>
                </div>
              </div>
              <Grid container>
                <Grid item xs={12}>
                  <Divider />
                </Grid>
              </Grid>
              <Grid container spacing={3} className="flexContainer">
                <Grid item xs={4}>
                  <Typography variant="h6">test</Typography>
                </Grid>
                <Grid item xs={8}>
                  <Typography variant="body1">test</Typography>
                </Grid>
                <Grid item xs={4}>
                  <Typography variant="h6">test</Typography>
                </Grid>
                <Grid item xs={8}>
                  <Typography variant="body1">test</Typography>
                </Grid>
                <Grid item xs={4}>
                  <Typography variant="h6">test</Typography>
                </Grid>
                <Grid item xs={8}>
                  <Typography variant="body1">test</Typography>
                </Grid>
                <Grid item xs={4}>
                  <Typography variant="h6">test</Typography>
                </Grid>
                <Grid item xs={8}>
                  <Typography variant="body1">test</Typography>
                </Grid>
                <Grid item xs={4}>
                  <Typography variant="h6">test</Typography>
                </Grid>
                <Grid item xs={8}>
                  <Typography variant="body1">test</Typography>
                </Grid>
                <Grid item xs={12}>
                  <Divider />
                </Grid>
              </Grid>
            </div>
          </div>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleClose}>
            Cancel
          </Button>
          <Button onClick={generatePDF} autoFocus>Download receipt</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default DisplayMemberType;
