import React, { useEffect, useRef, useState } from "react";
import { Grid, Typography, Divider, Button, IconButton } from "@mui/material";
import "../../../Style/Custom.css";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import { useReactToPrint } from "react-to-print";
import NGOLogo from "component/Logo";
import { DataGrid } from "@mui/x-data-grid";
import { getMembers } from "api/user";
import EditNoteIcon from "@mui/icons-material/EditNote";
import { getPayments } from "api/donor";
import { getDonor } from "api/getdonor";

const DownloadReciepts = () => {
  const componentPDF = useRef();
  const [downloadInProgress, setDownloadInProgress] = useState(false);

  const handleDownloadClick = () => {
    setDownloadInProgress(true);
    setTimeout(() => {
      generatePDF();
    }, 50);
  };

  const generatePDF = useReactToPrint({
    content: () => componentPDF.current,
    documentTitle: "userData",
    onBeforePrint: () => setDownloadInProgress(true),
    onAfterPrint: () => setDownloadInProgress(false),
  });

  // table
  const [rows, setRows] = useState([]);
  const [donars, setDonar] = useState([]);
  const [debited, setDebited] = useState([]);
  const [totalPayments, setPayments] = useState([]);
  const [totalDonars, setDonars] = useState([]);
  const [member, setMember] = useState([])

  const fetchUsers = async () => {
    try {
      const _user = JSON.parse(sessionStorage.getItem("user"));
      const res = await getPayments(_user.schema_name);
      const res_ = res?.data?.map((item, i) => ({
        ...item,
        id: item._id,
        index: i + 1,
        createdAt: item.createdAt.split("T")[0],
      }));
      setRows(res_);
      const totalPayments = res_?.reduce((a, b) => {
        return a + Number(b.amount)
      }, 0)
      setPayments(totalPayments)
    } catch (err) {
      console.log(err);
    }
  };

  const fetchDonars = async () => {
    try {
      const res = await getDonor();
      const res_ = res?.data?.map((item, i) => ({
        ...item,
        id: item._id,
        index: i + 1,
        createdAt: item.createdAt.split("T")[0],
        donations: item.donations.reduce((total, donation) => total + donation.amount, 0)
      }));
      setDonar(res_);
      const totalPayments = res?.data?.reduce((a, b) => {
        const num = Number(b?.donations?.[0]?.amount) || 0;
        return a + num
      }, 0)
      setDonars(totalPayments)
    } catch (err) {
      console.log(err);
    }
  };

  const fetchMemberUsers = async () => {
    try {
      const res = await getMembers();
      const res_ = res?.data?.map((item, i) => {
        return {
          ...item,
          id: item._id,
          index: i + 1,
        };
      });
      setMember(res_?.filter((i) => i.user_type === "member"));
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    fetchUsers();
    fetchDonars();
    fetchMemberUsers();
  }, []);

  const formatDataForCSV = () => {
    const formattedData = rows.map((row) => ({
      "S.No": row.index,
      "Member Name": row.first_name,
      "Contact Number": row.mobile_number,
      Address: row.address_1,
      Amount: row.amount,
    }));
    return formattedData;
  };

  const columns = [
    { field: "index", headerName: "S.No", width: 125, minWidth: 125, maxWidth: 200 },
    { field: "createdAt", headerName: "Date", width: 125, minWidth: 125, maxWidth: 200 },
    { field: "type", headerName: "Payment Type", width: 125, minWidth: 125, maxWidth: 200 },
    { field: "amount", headerName: "Payment Amount", width: 125, minWidth: 125, maxWidth: 200 },
    { field: "date", headerName: "Payment Date", width: 125, minWidth: 125, maxWidth: 200 },
    { field: "note", headerName: "Payment Note", width: 125, minWidth: 125, maxWidth: 200 },

  ];
  if (!downloadInProgress) {
    columns.push(
      {
        field: "action",
        headerName: "Action",
        width: 125, minWidth: 125, maxWidth: 200,
        renderCell: (item) => (
          <div>
            <IconButton color="primary" aria-label="edit" onClick={() => handleEdit(item)}>
              <EditNoteIcon />
            </IconButton>
          </div>
        ),
      },
    )
  }


  const columns_ = [
    { field: "index", headerName: "S.No", width: 125, minWidth: 125, maxWidth: 200 },
    { field: "createdAt", headerName: "Date", width: 125, minWidth: 125, maxWidth: 200 },
    { field: "full_name", headerName: "Donor Name", width: 125, minWidth: 125, maxWidth: 200 },
    { field: "user_type", headerName: "Donor Type", width: 125, minWidth: 125, maxWidth: 200 },
    { field: "mobile_number", headerName: "Contact Number", width: 125, minWidth: 125, maxWidth: 200 },
    { field: "donations", headerName: "Donations", width: 125, minWidth: 125, maxWidth: 200 },
    { field: "donated_for", headerName: "Donated For", width: 125, minWidth: 125, maxWidth: 200 },

  ];
  if (!downloadInProgress) {
    columns_.push(
      {
        field: "action",
        headerName: "Action",
        width: 125, minWidth: 125, maxWidth: 200,
        renderCell: (item) => (
          <div>
            <IconButton color="primary" aria-label="edit" onClick={() => handleEdit(item)}>
              <EditNoteIcon />
            </IconButton>
          </div>
        ),
      },
    )
  }



  //Members
  const mamberColumns = [
    { field: "index", headerName: "S.No", width: 125, minWidth: 125, maxWidth: 200 },
    { field: "first_name", headerName: "Member Name", width: 125, minWidth: 125, maxWidth: 200 },
    { field: "mobile_number", headerName: "Contact Number", width: 125, minWidth: 125, maxWidth: 200 },
    { field: "address_1", headerName: "Address", width: 125, minWidth: 125, maxWidth: 200 },
  ];
  // if (!downloadInProgress) {
  //   columns.push({
  //     field: "action",
  //     headerName: "Action",
  //     flex: 1,
  //     renderCell: (item) => (
  //       <div>
  //         <IconButton color="warning" aria-label="view" onClick={() => handleView(item)}>
  //           <VisibilityIcon />
  //         </IconButton>
  //         <IconButton color="primary" aria-label="edit" onClick={() => handleEdit(item)}>
  //           <EditNoteIcon />
  //         </IconButton>
  //         <IconButton aria-label="delete" color="error" onClick={() => handleClickOpen(item)}>
  //           <Download />
  //         </IconButton>
  //       </div>
  //     ),
  //   });
  // }

  return (
    <div ref={componentPDF}>
      {/* credit reciept*/}
      <div className="main-container-single-reciept">
        {!downloadInProgress && (
          <div className="dFlex flexEnd mAuto">
            <Button onClick={handleDownloadClick} variant="text">
              <IconButton onClick={handleDownloadClick} aria-label="edit" color="primary">
                <ArrowDownwardIcon />
              </IconButton>
              Download
            </Button>
          </div>
        )}

        {downloadInProgress && (
          <div className="pdf-topbar mb_40px">
            <div className="pdf-top-div">
              <Typography color="#fff" gutterBottom variant="h4">
                NGO-org pvt. ltd.
              </Typography>
              <div>address line 1</div>
              <div>address line 2</div>
            </div>
            <NGOLogo />
          </div>
        )}
        <div spacing={0} className="d-flex-space-bw">
          <div className="d-flex-center95">
            <Typography variant="h5">Payments - Total Payment Amount - Rs. {totalPayments}</Typography>
          </div>
        </div>
        <div className="h-300px mt_20px" style={{ width: '100%', overflow: 'auto' }}>
          <DataGrid sx={{ minWidth: '750px !important' }} rows={rows} columns={columns} pageSize={5} />
        </div>
        <br />
        <div spacing={0} className="d-flex-space-bw">
          <div className="d-flex-center95">
            <Typography variant="h5">Mambers - Total Received Amount - Rs. {totalPayments}</Typography>
          </div>
        </div>
        <div className="h-300px mt_20px" style={{ width: '100%', overflow: 'auto' }}>
          <DataGrid sx={{ minWidth: '750px !important' }} rows={member} columns={mamberColumns} pageSize={5} />
        </div>

        <br /><br />
        <div spacing={0} className="d-flex-space-bw">

          <div className="d-flex-center95">

            <Typography variant="h5">Donation - Total Recieved Amount  - Rs. {totalDonars}</Typography>

          </div>
        </div>
        <div className="h-300px mt_20px" style={{ width: '100%', overflow: 'auto' }}>
          <DataGrid sx={{ minWidth: '750px !important' }} rows={donars} columns={columns_} pageSize={5} />
        </div>
      </div >
    </div >
  );
};

export default DownloadReciepts;
