import React, { useRef, useState } from "react";
import { Grid, Typography, Divider, Button, IconButton } from "@mui/material";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { useNavigate, useLocation } from "react-router";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import { useReactToPrint } from 'react-to-print';
import NGOLogo from "component/Logo";

const ViewSingleMember = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { item } = location?.state || {};

  const handleClickBack = () => {
    navigate("/display-member");
  };

  const componentPDF = useRef();
  const [downloadInProgress, setDownloadInProgress] = useState(false);

  const handleDownloadClick = () => {
    setDownloadInProgress(true);

    setTimeout(() => {
      generatePDF();
    }, 50);
  };

  const generatePDF = useReactToPrint({
    content: () => componentPDF.current,
    documentTitle: "userData",
    onBeforePrint: () => setDownloadInProgress(true),
    onAfterPrint: () => setDownloadInProgress(false),
  });

  return (
    <div className="main-container-single">
      <div spacing={0} className="d-flex-space-bw">
        <div>
          <Button onClick={handleClickBack} variant="text">
            <IconButton
              onClick={handleClickBack}
              aria-label="edit"
              color="primary"
            >
              <ArrowBackIosIcon />
            </IconButton>
            Back
          </Button>
        </div>
        <div>
          <Button onClick={handleDownloadClick} variant="text">
            <IconButton onClick={handleDownloadClick} aria-label="edit" color="primary">
              <ArrowDownwardIcon />
            </IconButton>
            Download
          </Button>
        </div>
      </div>

      <Grid container spacing={3} className="pdf-grid" ref={componentPDF}>
        {downloadInProgress && (
          <div className="pdf-topbar">
            <div className="pdf-top-div">
              <Typography color="#fff" gutterBottom variant="h4">
                NGO-org pvt. ltd.
              </Typography>
              <div>address line 1</div>
              <div>address line 2</div>
            </div>
            <NGOLogo />
          </div>
        )}
        <div className="d-flex-center95" >
          <Typography variant="h5">Employee Details</Typography>
        </div>
        <Grid container>
          <Grid item xs={12}>
            <Divider />
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Typography color="textPrimary" gutterBottom variant="h4">
            Basic Information
          </Typography>
        </Grid>
        <Grid item xs={6} lg={6}>
          <Typography variant="h6">User Type:</Typography>
          <Typography>Member</Typography>
        </Grid>
        <Grid item xs={6} lg={6}>
          <Typography variant="h6">First Name:</Typography>
          <Typography>{item?.first_name}</Typography>
        </Grid>
        <Grid item xs={6} lg={6}>
          <Typography variant="h6">Last Name:</Typography>
          <Typography>{item?.last_name}</Typography>
        </Grid>
        <Grid item xs={6} lg={6}>
          <Typography variant="h6">Email:</Typography>
          <Typography>{item?.email}</Typography>
        </Grid>
        <Grid item xs={6} lg={6}>
          <Typography variant="h6">User Name:</Typography>
          <Typography>{item?.username}</Typography>
        </Grid>
        <Grid item xs={6} lg={6}>
          <Typography variant="h6">DIN:</Typography>
          <Typography>{item?.din_number}</Typography>
        </Grid>
        <Grid item xs={6} lg={6}>
          <Typography variant="h6">Date Of Birth:</Typography>
          <Typography>{item?.dob}</Typography>
        </Grid>
        <Grid item xs={6} lg={6}>
          <Typography variant="h6">Gender:</Typography>
          <Typography>{item?.gender}</Typography>
        </Grid>
        <Grid item xs={6} lg={6}>
          <Typography variant="h6">Contact Number:</Typography>
          <Typography>{item?.mobile_number}</Typography>
        </Grid>
        <Grid item xs={12}>
          <Divider />
        </Grid>

        <Grid item xs={12}>
          <Typography color="textPrimary" gutterBottom variant="h4">
            Address Information
          </Typography>
        </Grid>
        <Grid item xs={6} lg={6}>
          <Typography variant="h6">Address:</Typography>
          <Typography variant="body1">{item?.address_1}</Typography>
        </Grid>
        <Grid item xs={12}>
          <Divider />
        </Grid>

        <Grid item xs={12}>
          <Typography color="textPrimary" gutterBottom variant="h4">
            Bank Details
          </Typography>
        </Grid>
        <Grid item xs={6} lg={6}>
          <Typography variant="h6">Bank Name:</Typography>
          <Typography variant="body1">{item?.bank_name}</Typography>
        </Grid>
        <Grid item xs={6} lg={6}>
          <Typography variant="h6">Account Name:</Typography>
          <Typography variant="body1">{item?.account_name}</Typography>
        </Grid>
        <Grid item xs={6} lg={6}>
          <Typography variant="h6">Account Number:</Typography>
          <Typography variant="body1">{item?.account_number}</Typography>
        </Grid>
        <Grid item xs={6} lg={6}>
          <Typography variant="h6">IFSC Code:</Typography>
          <Typography variant="body1">{item?.ifsc_code}</Typography>
        </Grid>
        <Grid item xs={6} lg={6}>
          <Typography variant="h6">Branch Name:</Typography>
          <Typography variant="body1">{item?.branch_name}</Typography>
        </Grid>
        <Grid item xs={12}>
          <Divider />
        </Grid>

        <Grid item xs={12}>
          <Typography color="textPrimary" gutterBottom variant="h4">
            Qualification Details
          </Typography>
        </Grid>
        <Grid item xs={6} lg={6}>
          <Typography variant="h6">Highest Qualification:</Typography>
          <Typography variant="body1">{item?.high_qualification}</Typography>
        </Grid>
        <Grid item xs={12}>
          <Divider />
        </Grid>

        <Grid item xs={12}>
          <Typography color="textPrimary" gutterBottom variant="h4">
            Verification Details
          </Typography>
        </Grid>
        {/* <Grid item xs={6} lg={6}>
          <Typography variant="h6">ID Type:</Typography>
          <Typography variant="body1">{item?.id_proof_type}</Typography>
        </Grid>
        <Grid item xs={6} lg={6}>
          <Typography variant="h6">ID Number:</Typography>
          <Typography variant="body1">{item?.id_number}</Typography>
        </Grid> */}
        <Grid item xs={6} lg={6}>
          <Typography variant="h6">Aadhaar Number(yet to bind):</Typography>
          <Typography variant="body1">{item?.id_proof_type}</Typography>
        </Grid>
        <Grid item xs={6} lg={6}>
          <Typography variant="h6">PAN Number:(yet to bind)</Typography>
          <Typography variant="body1">{item?.id_number}</Typography>
        </Grid>
        <Grid item xs={12}>
          <Divider />
        </Grid>

        <Grid item xs={12}>
          <Typography color="textPrimary" gutterBottom variant="h4">
            Emergency Contact Information
          </Typography>
        </Grid>
        <Grid item xs={6} lg={6}>
          <Typography variant="h6">Name:</Typography>
          <Typography variant="body1">{item?.emergency_name}</Typography>
        </Grid>
        <Grid item xs={6} lg={6}>
          <Typography variant="h6">Phone Number:</Typography>
          <Typography variant="body1">{item?.emergency_number}</Typography>
        </Grid>
        <Grid item xs={6} lg={6}>
          <Typography variant="h6">Relation:</Typography>
          <Typography variant="body1">{item?.emergency_relation}</Typography>
        </Grid>
        <Grid item xs={12}>
          <Divider />
        </Grid>

        <Grid item xs={12}>
          <Typography color="textPrimary" gutterBottom variant="h4">
            Status Information
          </Typography>
        </Grid>
        <Grid item xs={6} lg={6}>
          <Typography variant="h6">Status:</Typography>
          <Typography variant="body1">Active</Typography>
        </Grid>
        <Grid item xs={12}>
          <Divider />
        </Grid>
      </Grid>
    </div>
  );
};

export default ViewSingleMember;
