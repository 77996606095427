import React, { useState, useEffect, useRef } from "react";
import { Grid, TextField, Typography, Button } from "@mui/material";
import Divider from "@mui/material/Divider";
import "../../../Style/Custom.css";
import {
  addMetaData,
  getMetaData,
  updateMetaData,
  fileUpload,
} from "api/metadata";
import { toast } from "react-toastify";

const initalState = {
  upcoming_title: "",
  upcoming_desc: "",
  upcoming_img: "",

  helping_title: "",
  helping_desc: "",
  helping_img: "",

  about_title: "",
  company_name: "",
  about_desc: "",
  mission: "",
  mission_2img: "",
  mission_img: "",
  followers: "",
  solved_problems: "",
  happy_customers: "",
  projects: "",
  support_title: "",
  support_desc: "",
  call_us_link: "",
  img: "",
  agency_title: "",
  agency_desc: "",
  fb_link: "",
  twitter_link: "",
  linkedin_link: "",
  instagram_link: "",
  copyright: "",
  address_title: "",
  address_desc: "",
};

const AddMemberType = () => {
  const [dataId, setDataId] = useState(null);

  // Hero Section
  const [heroSection, setHeroSection] = useState({
    entries: [
      {
        title: "",
        desc: "",
        get_started_link: "",
        title_img: "",
      },
    ],
  });

  //Recent Causes
  const [recentCauses, setRecentCauses] = useState({
    entries: [
      {
        title_img: "",
        title: "",
        desc: "",
        goal: "",
        raised: "",
      },
    ],
  });

  //Feature Campaigns
  const [featureCampaign, setFeatureCampaign] = useState({
    bg_image: "",
    top_title: "",
    title: "",
    desc: "",
    goal: "",
    raised: "",
    donate_link: "",
    video_link: "",
  });

  console.log("featureCampaign", featureCampaign);

  //Card Section
  const [cardSection, setCardSection] = useState({
    entries: [
      {
        title: "",
        desc: "",
        title_img: "",
      },
    ],
  });

  //Helping Section
  const [helpingSection, setHelpingSection] = useState({
    entries: [
      {
        title: "",
        desc: "",
        title_img: "",
      },
    ],
  });

  const [expanded, setExpanded] = useState(false);

  // Hero Sections Entry
  const addEntry = () => {
    setHeroSection({
      ...heroSection,
      entries: [
        ...heroSection.entries,
        {
          title: "",
          desc: "",
          get_started_link: "",
          title_img: "",
        },
      ],
    });
  };

  // Recent Causes Entry
  const addRecentCasuseEntry = () => {
    setRecentCauses({
      ...recentCauses,
      entries: [
        ...recentCauses.entries,
        {
          title: "",
          desc: "",
          title_img: "",
          goal: "",
          raised: "",
        },
      ],
    });
  };

  const toggleExpand = () => {
    setExpanded((prev) => !prev);
  };

  // Helping Entrys
  const addHelpEntry = () => {
    setHelpingSection({
      ...helpingSection,
      entries: [
        ...helpingSection.entries,
        {
          title: "",
          desc: "",
          title_img: "",
        },
      ],
    });
  };

  //Cards Entry
  const addNewCard = () => {
    setCardSection({
      ...cardSection,
      entries: [
        ...cardSection.entries,
        {
          title: "",
          desc: "",
          title_img: "",
        },
      ],
    });
  };

  //Hero handle change
  const handleheroChange = (index, event) => {
    const { name, value } = event.target;
    const newEntries = heroSection.entries.map((entry, i) => {
      if (i === index) {
        return {
          ...entry,
          [name]: value,
        };
      }
      return entry;
    });
    setHeroSection({ ...heroSection, entries: newEntries });
  };

  //Recent Cause change
  const handleRecentCauseChange = (index, event) => {
    const { name, value } = event.target;
    const newEntries = recentCauses.entries.map((entry, i) => {
      if (i === index) {
        return {
          ...entry,
          [name]: value,
        };
      }
      return entry;
    });
    setRecentCauses({ ...recentCauses, entries: newEntries });
  };

  //Card Change
  const handleCardChange = (index, event) => {
    const { name, value } = event.target;
    const newEntries = cardSection.entries.map((entry, i) => {
      if (i === index) {
        return {
          ...entry,
          [name]: value,
        };
      }
      return entry;
    });
    setCardSection({ ...cardSection, entries: newEntries });
  };

  //Helping Changes
  const handleHelpChange = (index, event) => {
    const { name, value } = event.target;
    const newEntries = helpingSection.entries.map((entry, i) => {
      if (i === index) {
        return {
          ...entry,
          [name]: value,
        };
      }
      return entry;
    });
    setHelpingSection({ ...helpingSection, entries: newEntries });
  };

  const [heroError, setHeroError] = useState([
    {
      title: "",
      desc: "",
      get_started_link: "",
      title_img: "",
    },
  ]);
  const [cardError, setCardError] = useState([
    {
      title: "",
      desc: "",
      title_img: "",
    },
  ]);
  const [helpError, setHelpError] = useState([
    {
      title: "",
      desc: "",
      title_img: "",
    },
  ]);
  const handleHeroError = (index, name, error) => {
    setHeroError({
      ...heroSection,
      entries: [
        ...heroSection.entries,
        {
          title: "",
          desc: "",
          get_started_link: "",
          title_img: "",
        },
      ],
    });
    setHeroError([
      ...heroError,
      {
        title: "",
        desc: "",
        get_started_link: "",
        title_img: "",
      },
    ]);
  };
  const handleCardError = (index, name, error) => {
    setCardError({
      ...cardSection,
      entries: [
        ...cardSection.entries,
        {
          title: "",
          desc: "",
          title_img: "",
        },
      ],
    });

    setCardError([
      ...cardError,
      {
        title: "",
        desc: "",
        title_img: "",
      },
    ]);
  };
  const handlehelpError = (index, name, error) => {
    setHelpError({
      ...helpingSection,
      entries: [
        ...helpingSection.entries,
        {
          title: "",
          desc: "",
          title_img: "",
        },
      ],
    });

    setHelpError([
      ...helpError,
      {
        title: "",
        desc: "",
        title_img: "",
      },
    ]);
  };

  const [formData, setFormData] = useState(initalState);

  const [errors, setErrors] = useState({});

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleFeatureCampaings = (e) => {
    const { name, value } = e.target;
    setFeatureCampaign({
      ...featureCampaign,
      [name]: value,
    });
  };

  const handleSubmit = async () => {
    const payload = {
      home_section: heroSection,
      recent_causes: recentCauses,
      feature_campaign: featureCampaign,
      home_crd_data: cardSection,
      today_help_data: helpingSection,
      home_about_us: {
        title: formData.about_title,
        company_name: formData.company_name,
        mission_2img: formData.mission_2img,
        desc: formData.about_desc,
        mission: formData.mission,
        mission_img: formData.mission_img,
        followers: formData.followers,
        solved_problems: formData.solved_problems,
        happy_customers: formData.happy_customers,
        projects: formData.projects,
      },
      support: {
        title: formData.support_title,
        desc: formData.support_desc,
        call_us_link: formData.call_us_link,
        img: formData.img,
      },
      upcoming: {
        upcoming_title: formData.upcoming_title,
        upcoming_desc: formData.upcoming_desc,
        upcoming_img: formData.upcoming_img,
      },
      help: {
        helping_title: formData.helping_title,
        helping_desc: formData.helping_desc,
        helping_img: formData.helping_img,
      },
      footer: {
        agency_title: formData.agency_title,
        agency_desc: formData.agency_desc,
        fb_link: formData.fb_link,
        twitter_link: formData.twitter_link,
        linkedin_link: formData.linkedin_link,
        instagram_link: formData.instagram_link,
        copyright: formData.copyright,
        address_title: formData.address_title,
        address_desc: formData.address_desc,
      },
    };
    const _payload = JSON.stringify(payload);

    console.log("_payload", _payload);
    try {
      if (dataId) {
        const data = JSON.parse(sessionStorage.getItem("user"));
        console.log("data", data);
        await updateMetaData({
          id: dataId,
          data: { home: _payload, website: data.ngo_id.ngo_website },
        });
        toast.success("Home Page data updated successfull.");
      } else {
        const data = JSON.parse(sessionStorage.getItem("user"));
        await addMetaData({ home: _payload, website: data.ngo_id.ngo_website });
        toast.success("Home Page data addedd successfull.");
      }
    } catch (err) {
      toast.error("Failed to update/add data");
    }
  };

  const handleError = (inputs, value) => {
    setErrors((prev) => ({ ...prev, [inputs]: value }));
  };

  const fetchMetaData = async () => {
    try {
      const data = JSON.parse(sessionStorage.getItem("user"));
      const res = await getMetaData(data.ngo_id.ngo_website);
      setDataId(res?.data?._id);
      const homePageData = JSON.parse(res?.data?.home);
      setHeroSection({
        entries: homePageData.home_section.entries,
      });
      setRecentCauses({
        entries: homePageData.recent_causes.entries,
      });
      setFeatureCampaign(homePageData?.feature_campaign);
      setHelpingSection({
        entries: homePageData.today_help_data.entries,
      });
      setCardSection({
        entries: homePageData.home_crd_data.entries,
      });
      setFormData({
        img_1: homePageData?.home_section?.img_1,
        img_2: homePageData?.home_section?.img_2,
        img_3: homePageData?.home_section?.img_3,
        img_4: homePageData?.home_section?.img_4,
        upcoming_title: homePageData?.upcoming?.upcoming_title,
        upcoming_desc: homePageData?.upcoming?.upcoming_desc,
        upcoming_img: homePageData?.upcoming?.upcoming_img,
        helping_title: homePageData?.help?.helping_title,
        helping_desc: homePageData?.help?.helping_desc,
        helping_img: homePageData?.help?.helping_img,
        company_name: homePageData?.home_about_us?.company_name,
        about_title: homePageData?.home_about_us?.title,
        mission_2img: homePageData?.home_about_us?.mission_2img,
        about_desc: homePageData?.home_about_us?.desc,
        mission: homePageData?.home_about_us?.mission,
        mission_img: homePageData?.home_about_us?.mission_img,
        followers: homePageData?.home_about_us?.followers,
        solved_problems: homePageData?.home_about_us?.solved_problems,
        happy_customers: homePageData?.home_about_us?.happy_customers,
        projects: homePageData?.home_about_us?.projects,
        support_title: homePageData?.support?.title,
        support_desc: homePageData?.support?.desc,
        call_us_link: homePageData?.support?.call_us_link,
        img: homePageData?.support?.img,
        agency_title: homePageData?.footer?.agency_title,
        agency_desc: homePageData?.footer?.agency_desc,
        fb_link: homePageData?.footer?.fb_link,
        twitter_link: homePageData?.footer?.twitter_link,
        linkedin_link: homePageData?.footer?.linkedin_link,
        instagram_link: homePageData?.footer?.instagram_link,
        copyright: homePageData?.footer?.copyright,
        address_title: homePageData?.footer?.address_title,
        address_desc: homePageData?.footer?.address_desc,
      });
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    fetchMetaData();
  }, []);

  return (
    <div>
      <div className="main-container">
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography color="textPrimary" variant="h5" className="heading2">
              Add Home Page Data
            </Typography>
            <Divider />
          </Grid>
          <Typography color="textPrimary" variant="h5" className="heading2">
            Home Section
          </Typography>
          <Divider />
          {heroSection.entries &&
            heroSection.entries.map((entry, index) => (
              <Grid container spacing={2} key={index}>
                <Grid item xs={12} md={12} lg={6}>
                  <TextField
                    name="title"
                    label="Title"
                    value={entry.title}
                    onChange={(e) => handleheroChange(index, e)}
                    variant="outlined"
                    fullWidth
                    error={!heroError[index]?.title ? false : true}
                    helperText={heroError[index]?.title}
                    onFocus={() => handleHeroError(index, "title", null)}
                  />
                </Grid>
                <Grid item xs={12} md={12} lg={6}>
                  <TextField
                    name="desc"
                    label="Description"
                    value={entry.desc}
                    onChange={(e) => handleheroChange(index, e)}
                    variant="outlined"
                    fullWidth
                    error={!heroError[index]?.desc ? false : true}
                    helperText={heroError[index]?.desc}
                    onFocus={() => handleHeroError(index, "desc", null)}
                  />
                </Grid>
                <Grid item xs={12} md={12} lg={6}>
                  <TextField
                    name="get_started_link"
                    label="Get Started Link"
                    value={entry.get_started_link}
                    onChange={(e) => handleheroChange(index, e)}
                    variant="outlined"
                    fullWidth
                    error={!heroError[index]?.get_started_link ? false : true}
                    helperText={heroError[index]?.get_started_link}
                    onFocus={() =>
                      handleHeroError(index, "get_started_link", null)
                    }
                  />
                </Grid>
                <Grid item xs={12} md={12} lg={6}>
                  <FileInputs
                    label="Title Image"
                    index={index}
                    setHeroSection={setHeroSection}
                    heroSection={heroSection}
                    _key="title_img"
                  />
                </Grid>
              </Grid>
            ))}
          <Grid item xs={12}>
            <Button onClick={addEntry}>Add More...</Button>
          </Grid>
          <Typography
            onClick={() => toggleExpand()}
            color="textPrimary"
            variant="h5"
            className="heading2"
            style={{ cursor: "pointer" }}
          >
            Recent Causes
          </Typography>
          <Divider />
          {/* {expanded && (
            <> */}
          {recentCauses.entries &&
            recentCauses.entries.map((entry, index) => (
              <Grid container spacing={2} key={index}>
                <Grid item xs={12} md={12} lg={6}>
                  <TextField
                    name="title"
                    label="Title"
                    value={entry.title}
                    onChange={(e) => handleRecentCauseChange(index, e)}
                    variant="outlined"
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} md={12} lg={6}>
                  <TextField
                    name="desc"
                    label="Description"
                    value={entry.desc}
                    onChange={(e) => handleRecentCauseChange(index, e)}
                    variant="outlined"
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} md={12} lg={6}>
                  <TextField
                    name="goal"
                    label="Goal"
                    value={entry.goal}
                    onChange={(e) => handleRecentCauseChange(index, e)}
                    variant="outlined"
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} md={12} lg={6}>
                  <TextField
                    name="raised"
                    label="Raised Amount"
                    value={entry.raised}
                    onChange={(e) => handleRecentCauseChange(index, e)}
                    variant="outlined"
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} md={12} lg={6}>
                  <FileInputs
                    label="Title Image"
                    index={index}
                    setHeroSection={setRecentCauses}
                    heroSection={recentCauses}
                    _key="title_img"
                  />
                </Grid>
              </Grid>
            ))}
          <Grid item xs={12}>
            <Button onClick={addRecentCasuseEntry}>Add More...</Button>
          </Grid>
          {/* </>
          )} */}

          {/* Feature Campaign */}
          <Typography
            onClick={() => toggleExpand()}
            color="textPrimary"
            variant="h5"
            className="heading2"
            style={{ cursor: "pointer" }}
          >
            Feature Campaign
          </Typography>
          <Divider />
          {/* {expanded && (
            <> */}
          {/* {recentCauses.entries &&
                recentCauses.entries.map((entry, index) => ( */}
          <Grid container spacing={2}>
            <Grid item xs={12} md={12} lg={6}>
              <FileInput
                // {/*  label="Background Image"
                //   // index={}
                //   setHeroSection={setFeatureCampaign}
                //   heroSection={featureCampaign}
                //   _key="bg_image"
                // /> */}
                label="Background Image"
                setFormData={setFeatureCampaign}
                _key="bg_image"
                formData={featureCampaign}
              />
            </Grid>
            <Grid item xs={12} md={12} lg={6}>
              <TextField
                name="top_title"
                label="Top Title"
                value={featureCampaign.top_title}
                onChange={(e) => handleFeatureCampaings(e)}
                variant="outlined"
                fullWidth
              />
            </Grid>
            <Grid item xs={12} md={12} lg={6}>
              <TextField
                name="title"
                label="Title"
                value={featureCampaign.title}
                onChange={(e) => handleFeatureCampaings(e)}
                variant="outlined"
                fullWidth
              />
            </Grid>
            <Grid item xs={12} md={12} lg={6}>
              <TextField
                name="desc"
                label="Description"
                value={featureCampaign.desc}
                onChange={(e) => handleFeatureCampaings(e)}
                variant="outlined"
                fullWidth
              />
            </Grid>
            <Grid item xs={12} md={12} lg={6}>
              <TextField
                name="goal"
                label="Goal"
                value={featureCampaign.goal}
                onChange={(e) => handleFeatureCampaings(e)}
                variant="outlined"
                fullWidth
              />
            </Grid>
            <Grid item xs={12} md={12} lg={6}>
              <TextField
                name="raised"
                label="Raised Amount"
                value={featureCampaign.raised}
                onChange={(e) => handleFeatureCampaings(e)}
                variant="outlined"
                fullWidth
              />
            </Grid>
            <Grid item xs={12} md={12} lg={6}>
              <TextField
                name="donate_link"
                label="Donate Button Link"
                value={featureCampaign.donate_link}
                onChange={(e) => handleFeatureCampaings(e)}
                variant="outlined"
                fullWidth
              />
            </Grid>
            <Grid item xs={12} md={12} lg={6}>
              <TextField
                name="video_link"
                label="Youtube Video Link"
                value={featureCampaign.video_link}
                onChange={(e) => handleFeatureCampaings(e)}
                variant="outlined"
                fullWidth
              />
            </Grid>
          </Grid>
          {/* ))}  */}
          {/* </>
          )} */}

          {/* Card Data */}
          <Typography color="textPrimary" variant="h5" className="heading2">
            CardData
          </Typography>
          <Divider />
          {cardSection?.entries &&
            cardSection?.entries.map((entry, index) => (
              <Grid container spacing={2} key={index}>
                <Grid item xs={12} md={12} lg={6}>
                  <TextField
                    name="title"
                    label="Title"
                    value={entry.title}
                    onChange={(e) => handleCardChange(index, e)}
                    variant="outlined"
                    fullWidth
                    error={!cardError[index]?.title ? false : true}
                    helperText={cardError[index]?.title}
                    onFocus={() => handleCardError(index, "title", null)}
                  />
                </Grid>
                <Grid item xs={12} md={12} lg={6}>
                  <TextField
                    name="desc"
                    label="Description"
                    value={entry.desc}
                    onChange={(e) => handleCardChange(index, e)}
                    variant="outlined"
                    fullWidth
                    error={!cardError[index]?.desc ? false : true}
                    helperText={cardError[index]?.desc}
                    onFocus={() => handleCardError(index, "desc", null)}
                  />
                </Grid>

                <Grid item xs={12} md={12} lg={6}>
                  <FileInputs
                    label="Title Image"
                    index={index}
                    setHeroSection={setCardSection}
                    heroSection={cardSection}
                    _key="title_img"
                  />
                </Grid>
              </Grid>
            ))}
          <Grid item xs={12}>
            {cardSection.entries.length < 4 && (
              <Button onClick={addNewCard}>Add At least 3 Cards...</Button>
            )}
          </Grid>

          <Typography color="textPrimary" variant="h5" className="heading2">
            Home About Us
          </Typography>
          <Divider />
          <Grid container spacing={2}>
            <Grid item xs={12} md={12} lg={6}>
              <TextField
                name="company_name"
                label="Company Name"
                value={formData.company_name}
                onChange={handleInputChange}
                variant="outlined"
                fullWidth
                error={!errors.company_name ? false : true}
                helperText={errors.company_name}
                onFocus={() => handleError("company_name", null)}
              />
            </Grid>
            <Grid item xs={12} md={12} lg={6}>
              <TextField
                name="about_title"
                label="Title"
                value={formData.about_title}
                onChange={handleInputChange}
                variant="outlined"
                fullWidth
                error={!errors.about_title ? false : true}
                helperText={errors.about_title}
                onFocus={() => handleError("about_title", null)}
              />
            </Grid>
            <Grid item xs={12} md={12} lg={6}>
              <TextField
                name="about_desc"
                label="Description"
                value={formData.about_desc}
                onChange={handleInputChange}
                variant="outlined"
                fullWidth
                error={!errors.about_desc ? false : true}
                helperText={errors.about_desc}
                onFocus={() => handleError("about_desc", null)}
              />
            </Grid>
            <Grid item xs={12} md={12} lg={6}>
              <TextField
                name="mission"
                label="Mission"
                value={formData.mission}
                onChange={handleInputChange}
                variant="outlined"
                fullWidth
                error={!errors.mission ? false : true}
                helperText={errors.mission}
                onFocus={() => handleError("mission", null)}
              />
            </Grid>
            <Grid item xs={12} md={12} lg={6}>
              <FileInput
                label="Mission Image"
                setFormData={setFormData}
                _key="mission_img"
                formData={formData}
              />
            </Grid>
            <Grid item xs={12} md={12} lg={6}>
              <FileInput
                label="Mission 2nd Image"
                setFormData={setFormData}
                _key="mission_2img"
                formData={formData}
              />
            </Grid>
            <Grid item xs={12} md={12} lg={6}>
              <TextField
                name="followers"
                label="Follwers"
                value={formData.followers}
                onChange={handleInputChange}
                variant="outlined"
                fullWidth
                error={!errors.followers ? false : true}
                helperText={errors.followers}
                onFocus={() => handleError("followers", null)}
              />
            </Grid>
            <Grid item xs={12} md={12} lg={6}>
              <TextField
                name="solved_problems"
                label="Solve Problems"
                value={formData.solved_problems}
                onChange={handleInputChange}
                variant="outlined"
                fullWidth
                error={!errors.solved_problems ? false : true}
                helperText={errors.solved_problems}
                onFocus={() => handleError("solved_problems", null)}
              />
            </Grid>{" "}
            <Grid item xs={12} md={12} lg={6}>
              <TextField
                name="happy_customers"
                label="Happy Customers"
                value={formData.happy_customers}
                onChange={handleInputChange}
                variant="outlined"
                fullWidth
                error={!errors.happy_customers ? false : true}
                helperText={errors.happy_customers}
                onFocus={() => handleError("happy_customers", null)}
              />
            </Grid>{" "}
            <Grid item xs={12} md={12} lg={6}>
              <TextField
                name="projects"
                label="Projects"
                value={formData.projects}
                onChange={handleInputChange}
                variant="outlined"
                fullWidth
                error={!errors.projects ? false : true}
                helperText={errors.projects}
                onFocus={() => handleError("projects", null)}
              />
            </Grid>{" "}
          </Grid>
          <Typography color="textPrimary" variant="h5" className="heading2">
            Support
          </Typography>
          <Divider />
          <Grid container spacing={2}>
            <Grid item xs={12} md={12} lg={6}>
              <TextField
                name="support_title"
                label="Title"
                value={formData.support_title}
                onChange={handleInputChange}
                variant="outlined"
                fullWidth
                error={!errors.support_title ? false : true}
                helperText={errors.support_title}
                onFocus={() => handleError("support_title", null)}
              />
            </Grid>
            <Grid item xs={12} md={12} lg={6}>
              <TextField
                name="support_desc"
                label="Description"
                value={formData.support_desc}
                onChange={handleInputChange}
                variant="outlined"
                fullWidth
                error={!errors.support_desc ? false : true}
                helperText={errors.support_desc}
                onFocus={() => handleError("support_desc", null)}
              />
            </Grid>
            <Grid item xs={12} md={12} lg={6}>
              <TextField
                name="call_us_link"
                label="Call Us link"
                value={formData.call_us_link}
                onChange={handleInputChange}
                variant="outlined"
                fullWidth
                error={!errors.call_us_link ? false : true}
                helperText={errors.call_us_link}
                onFocus={() => handleError("call_us_link", null)}
              />
            </Grid>
            <Grid item xs={12} md={12} lg={6}>
              <FileInput
                label="Title Image"
                setFormData={setFormData}
                _key="img"
                formData={formData}
              />
            </Grid>
          </Grid>
          <Typography color="textPrimary" variant="h5" className="heading2">
            Upcoming Programs
          </Typography>
          <Divider />
          <Grid container spacing={2}>
            <Grid item xs={12} md={12} lg={6}>
              <TextField
                name="upcoming_title"
                label="Title"
                value={formData.upcoming_title}
                onChange={handleInputChange}
                variant="outlined"
                fullWidth
                error={!errors.upcoming_title ? false : true}
                helperText={errors.upcoming_title}
                onFocus={() => handleError("upcoming_title", null)}
              />
            </Grid>
            <Grid item xs={12} md={12} lg={6}>
              <TextField
                name="upcoming_desc"
                label="Upcoming Description"
                value={formData.upcoming_desc}
                onChange={handleInputChange}
                variant="outlined"
                fullWidth
                error={!errors.upcoming_desc ? false : true}
                helperText={errors.upcoming_desc}
                onFocus={() => handleError("upcoming_desc", null)}
              />
            </Grid>
            <Grid item xs={12} md={12} lg={6}>
              <FileInput
                label="Mission Image"
                setFormData={setFormData}
                _key="upcoming_img"
                formData={formData}
              />
            </Grid>
          </Grid>
          <Typography color="textPrimary" variant="h5" className="heading2">
            Helping Information
          </Typography>
          <Divider />
          <Grid container spacing={2}>
            <Grid item xs={12} md={12} lg={6}>
              <TextField
                name="helping_title"
                label="Title"
                value={formData.helping_title}
                onChange={handleInputChange}
                variant="outlined"
                fullWidth
                error={!errors.helping_title ? false : true}
                helperText={errors.helping_title}
                onFocus={() => handleError("helping_title", null)}
              />
            </Grid>
            <Grid item xs={12} md={12} lg={6}>
              <TextField
                name="helping_desc"
                label="Helping Description"
                value={formData.helping_desc}
                onChange={handleInputChange}
                variant="outlined"
                fullWidth
                error={!errors.helping_desc ? false : true}
                helperText={errors.helping_desc}
                onFocus={() => handleError("helping_desc", null)}
              />
            </Grid>
            <Grid item xs={12} md={12} lg={6}>
              <FileInput
                label="Mission Image"
                setFormData={setFormData}
                _key="helping_img"
                formData={formData}
              />
            </Grid>
          </Grid>
          <Typography color="textPrimary" variant="h5" className="heading2">
            Helping Today
          </Typography>
          <Divider />
          {helpingSection?.entries &&
            helpingSection?.entries.map((entry, index) => (
              <Grid container spacing={2} key={index}>
                <Grid item xs={12} md={12} lg={6}>
                  <TextField
                    name="title"
                    label="Title"
                    value={entry.title}
                    onChange={(e) => handleHelpChange(index, e)}
                    variant="outlined"
                    fullWidth
                    error={!cardError[index]?.title ? false : true}
                    helperText={cardError[index]?.title}
                    onFocus={() => handleHeroError(index, "title", null)}
                  />
                </Grid>
                <Grid item xs={12} md={12} lg={6}>
                  <TextField
                    name="desc"
                    label="Description"
                    value={entry.desc}
                    onChange={(e) => handleHelpChange(index, e)}
                    variant="outlined"
                    fullWidth
                    error={!cardError[index]?.desc ? false : true}
                    helperText={cardError[index]?.desc}
                    onFocus={() => handleHeroError(index, "desc", null)}
                  />
                </Grid>

                <Grid item xs={12} md={12} lg={6}>
                  <FileInputs
                    label="Title Image"
                    index={index}
                    setHeroSection={setHelpingSection}
                    heroSection={helpingSection}
                    _key="title_img"
                  />
                </Grid>
              </Grid>
            ))}
          <Grid item xs={12}>
            {helpingSection.entries.length < 4 && (
              <Button onClick={addHelpEntry}>Add More...</Button>
            )}
          </Grid>
          <Typography color="textPrimary" variant="h5" className="heading2">
            Footer
          </Typography>
          <Divider />
          <Grid container spacing={2}>
            <Grid item xs={12} md={12} lg={6}>
              <TextField
                name="agency_title"
                label="Title"
                value={formData.agency_title}
                onChange={handleInputChange}
                variant="outlined"
                fullWidth
                error={!errors.agency_title ? false : true}
                helperText={errors.agency_title}
                onFocus={() => handleError("agency_title", null)}
              />
            </Grid>
            <Grid item xs={12} md={12} lg={6}>
              <TextField
                name="agency_desc"
                label="Description"
                value={formData.agency_desc}
                onChange={handleInputChange}
                variant="outlined"
                fullWidth
                error={!errors.agency_desc ? false : true}
                helperText={errors.agency_desc}
                onFocus={() => handleError("agency_desc", null)}
              />
            </Grid>
            <Grid item xs={12} md={12} lg={6}>
              <TextField
                name="fb_link"
                label="Facebook Link"
                value={formData.fb_link}
                onChange={handleInputChange}
                variant="outlined"
                fullWidth
                error={!errors.fb_link ? false : true}
                helperText={errors.fb_link}
                onFocus={() => handleError("fb_link", null)}
              />
            </Grid>
            <Grid item xs={12} md={12} lg={6}>
              <TextField
                name="twitter_link"
                label="Twitter Link"
                value={formData.twitter_link}
                onChange={handleInputChange}
                variant="outlined"
                fullWidth
                error={!errors.twitter_link ? false : true}
                helperText={errors.twitter_link}
                onFocus={() => handleError("twitter_link", null)}
              />
            </Grid>
            <Grid item xs={12} md={12} lg={6}>
              <TextField
                name="linkedin_link"
                label="LinkedIn Link"
                value={formData.linkedin_link}
                onChange={handleInputChange}
                variant="outlined"
                fullWidth
                error={!errors.linkedin_link ? false : true}
                helperText={errors.linkedin_link}
                onFocus={() => handleError("linkedin_link", null)}
              />
            </Grid>
            <Grid item xs={12} md={12} lg={6}>
              <TextField
                name="instagram_link"
                label="Instagram Link"
                value={formData.instagram_link}
                onChange={handleInputChange}
                variant="outlined"
                fullWidth
                error={!errors.instagram_link ? false : true}
                helperText={errors.instagram_link}
                onFocus={() => handleError("instagram_link", null)}
              />
            </Grid>
            <Grid item xs={12} md={12} lg={6}>
              <TextField
                name="copyright"
                label="Copyright"
                value={formData.copyright}
                onChange={handleInputChange}
                variant="outlined"
                fullWidth
                error={!errors.copyright ? false : true}
                helperText={errors.copyright}
                onFocus={() => handleError("copyright", null)}
              />
            </Grid>
            <Grid item xs={12} md={12} lg={6}>
              <TextField
                name="address_title"
                label="Address Title"
                value={formData.address_title}
                onChange={handleInputChange}
                variant="outlined"
                fullWidth
                error={!errors.address_title ? false : true}
                helperText={errors.address_title}
                onFocus={() => handleError("address_title", null)}
              />
            </Grid>
            <Grid item xs={12} md={12} lg={6}>
              <TextField
                name="address_desc"
                label="Address Description"
                value={formData.address_desc}
                onChange={handleInputChange}
                variant="outlined"
                fullWidth
                error={!errors.address_desc ? false : true}
                helperText={errors.address_desc}
                onFocus={() => handleError("address_desc", null)}
              />
            </Grid>
          </Grid>
          <Grid item xs={12} className="dFlex justifyCenter">
            <Button variant="contained" onClick={handleSubmit}>
              {dataId ? "Update" : "Add"}
            </Button>
          </Grid>
        </Grid>
      </div>
    </div>
  );
};

export default AddMemberType;

const FileInput = (props) => {
  const { errors = {}, label, setFormData, _key, formData } = props;
  const fileInputRef = useRef(null);
  const [value, setValue] = useState(null);

  const handleInputChange = async (event) => {
    const file = event.target.files[0];
    const _formData = new FormData();
    _formData.append("file", file);
    try {
      const res = await fileUpload(_formData);
      setValue(res?.data?.Location);
      setFormData({
        ...formData,
        [_key]: res?.data?.Location,
      });
    } catch (err) {
      console.log(err);
    }
  };

  const handleTextFieldClick = () => {
    fileInputRef.current.click();
  };
  return (
    <>
      <TextField
        label={label}
        fullWidth
        onClick={handleTextFieldClick}
        value={value ? value : formData?.[_key]}
        InputProps={{
          readOnly: true,
        }}
        error={!errors.copyright ? false : true}
      />
      <div>
        <img className="wh-100" src={formData?.[_key]} alt="" />
      </div>
      <input
        type="file"
        ref={fileInputRef}
        name="title_img"
        onChange={handleInputChange}
        style={{ display: "none" }}
        accept="image/*" // Only allow image files
      />
    </>
  );
};

const FileInputs = ({
  index,
  label,
  setHeroSection,
  heroSection,
  _key,
  errors = {},
}) => {
  const fileInputRef = useRef(null);
  const [value, setValue] = useState(null);

  const handleInputChange = async (event) => {
    const file = event.target.files[0];
    const _formData = new FormData();
    _formData.append("file", file);
    try {
      const res = await fileUpload(_formData);
      const imageUrl = res?.data?.Location;
      setValue(imageUrl);

      // Update the specific entry within the heroSection.entries array
      const newEntries = heroSection.entries.map((entry, i) => {
        if (i === index) {
          return {
            ...entry,
            [_key]: imageUrl,
          };
        }
        return entry;
      });

      setHeroSection({
        ...heroSection,
        entries: newEntries,
      });
    } catch (err) {
      console.log(err);
    }
  };

  const handleTextFieldClick = () => {
    fileInputRef.current.click();
  };

  return (
    <>
      <TextField
        label={label}
        fullWidth
        onClick={handleTextFieldClick}
        value={value || heroSection.entries[index][_key] || ""}
        InputProps={{
          readOnly: true,
        }}
        error={Boolean(errors[_key])}
        helperText={errors[_key]}
      />
      <div>
        {heroSection.entries[index][_key] && (
          <img
            className="wh-100"
            src={heroSection.entries[index][_key]}
            alt=""
          />
        )}
      </div>
      <input
        type="file"
        ref={fileInputRef}
        name={_key}
        onChange={handleInputChange}
        style={{ display: "none" }}
        accept="image/*" // Only allow image files
      />
    </>
  );
};
