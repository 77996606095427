import React from 'react';
import { Grid, Typography, Divider, Button, IconButton } from '@mui/material';
import '../../../Style/Custom.css';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { useNavigate, useLocation } from 'react-router';

const ViewSingleNGO = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { item } = location?.state || {};

  const handleClickBack = () => {
    navigate('/display-ngo');
  };

  return (
    <div className="main-container-single">
      <div spacing={0} className="d-flex-space-bw">
        <div className='d-flex-center95'>
          <Typography variant="h5">
            NGO Details
          </Typography>
        </div>
        <div>
          <Button onClick={handleClickBack} variant="text">
            <IconButton onClick={handleClickBack} aria-label="edit" color="primary">
              <ArrowBackIosIcon />
            </IconButton>
            Back
          </Button>
        </div>
      </div>
      <Grid container><Grid item xs={12}><Divider /></Grid></Grid>
      <Grid container spacing={3} className="mt-4" style={{ marginTop: '8px' }}>

        <Grid item xs={12}>
          <Typography color="textPrimary" gutterBottom variant="h4">
            Basic Information
          </Typography>
        </Grid>
        <Grid item xs={12} md={12} lg={6}>
          <Typography variant="h6">NGO Name:</Typography>
          <Typography variant="body1">{item?.ngo_name}</Typography>
        </Grid>
        <Grid item xs={12} md={12} lg={6}>
          <Typography variant="h6">Email:</Typography>
          <Typography variant="body1">{item?.ngo_email}</Typography>
        </Grid>
        <Grid item xs={12} md={12} lg={6}>
          <Typography variant="h6">Phone:</Typography>
          <Typography variant="body1">{item?.ngo_mobile_number}</Typography>
        </Grid>
        <Grid item xs={12} md={12} lg={6}>
          <Typography variant="h6">Address:</Typography>
          <Typography variant="body1">{item?.ngo_address}</Typography>
        </Grid>
        <Grid item xs={12} md={12} lg={6}>
          <Typography variant="h6">Area:</Typography>
          <Typography variant="body1">Area Name</Typography>
        </Grid>

        <Grid item xs={12}>
          <Typography color="textPrimary" gutterBottom variant="h4">
            Official Details
          </Typography>
        </Grid>
        <Grid item xs={12} md={12} lg={6}>
          <Typography variant="h6">GST Number:</Typography>
          <Typography variant="body1">ABCDE1234F</Typography>
        </Grid>
        <Grid item xs={12} md={12} lg={6}>
          <Typography variant="h6">CIN Number:</Typography>
          <Typography variant="body1">GST1234567890</Typography>
        </Grid>

        <Grid item xs={12} md={12} lg={6}>
          <Typography variant="h6">PAN Number:</Typography>
          <Typography variant="body1">{item?.ngo_pan_number}</Typography>
        </Grid>
        <Grid item xs={12} md={12} lg={6}>
          <Typography variant="h6">GST Number:</Typography>
          <Typography variant="body1">{item?.ngo_gst_number}</Typography>
        </Grid>
        <Grid item xs={12} md={12} lg={6}>
          <Typography variant="h6">NGO Website:</Typography>
          <Typography variant="body1">{item?.ngo_website}</Typography>
        </Grid>
        <Grid item xs={12}><Divider /></Grid>


        <Grid item xs={12}>
          <Typography color="textPrimary" gutterBottom variant="h4">
            Bank Details
          </Typography>
        </Grid>
        <Grid item xs={12} md={12} lg={6}>
          <Typography variant="h6">Bank Name:</Typography>
          <Typography variant="body1">ABCDE1234F</Typography>
        </Grid>
        <Grid item xs={12} md={12} lg={6}>
          <Typography variant="h6">Account Name:</Typography>
          <Typography variant="body1">GST1234567890</Typography>
        </Grid>
        <Grid item xs={12} md={12} lg={6}>
          <Typography variant="h6">Account Number:</Typography>
          <Typography variant="body1">ABCDE1234F</Typography>
        </Grid>
        <Grid item xs={12} md={12} lg={6}>
          <Typography variant="h6">IFSC Code:</Typography>
          <Typography variant="body1">GST1234567890</Typography>
        </Grid>
        <Grid item xs={12} md={12} lg={6}>
          <Typography variant="h6">Branch Name:</Typography>
          <Typography variant="body1">GST1234567890</Typography>
        </Grid>
        <Grid item xs={12}><Divider /></Grid>

        <Grid item xs={12}>
          <Typography color="textPrimary" gutterBottom variant="h4">
            Subscription Details
          </Typography>
        </Grid>
        <Grid item xs={12} md={12} lg={6}>
          <Typography variant="h6">Subscription Name:</Typography>
          <Typography variant="body1">ABCDE1234F</Typography>
        </Grid>
        <Grid item xs={12} md={12} lg={6}>
          <Typography variant="h6">Subscription Duration:</Typography>
          <Typography variant="body1">GST1234567890</Typography>
        </Grid>
        <Grid item xs={12} md={12} lg={6}>
          <Typography variant="h6">Subscription Start Date:</Typography>
          <Typography variant="body1">ABCDE1234F</Typography>
        </Grid>
        <Grid item xs={12} md={12} lg={6}>
          <Typography variant="h6">Subscription End Date:</Typography>
          <Typography variant="body1">GST1234567890</Typography>
        </Grid>
        <Grid item xs={12}><Divider /></Grid>

        <Grid item xs={12}>
          <Typography color="textPrimary" gutterBottom variant="h4">
            Status Information
          </Typography>
        </Grid>
        <Grid item xs={12} md={12} lg={6}>
          <Typography variant="h6">Status:</Typography>
          <Typography variant="body1">GST1234567890</Typography>
        </Grid>
        <Grid item xs={12}><Divider /></Grid>

      </Grid>
    </div>
  );
};

export default ViewSingleNGO;
