import * as React from 'react';
import { DataGrid } from '@mui/x-data-grid';
import {
  Typography,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  Grid,
  TextField,
  // MenuItem,
  // FormControl,
  // InputLabel,
  // Select,
  DialogActions,
  Button,
  Divider,
} from '@mui/material';
import EditNoteIcon from '@mui/icons-material/EditNote';
import { useNavigate } from 'react-router';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { useEffect, useState, useRef } from "react";
import { getMembers } from 'api/user';
import { Download } from '@mui/icons-material';
import { useReactToPrint } from 'react-to-print';
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import SearchIcon from '@mui/icons-material/Search';
import InputAdornment from '@mui/material/InputAdornment';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';

import { CSVLink } from "react-csv";
import NGOLogo from "component/Logo";
import DownloadHeader from 'component/DownloadHeader';

const DisplayMember = () => {
  const componentPDF = useRef();
  const componentPDFTable = useRef();

  const navigate = useNavigate();
  const [downloadInProgress, setDownloadInProgress] = useState(false);
  const [rows, setRows] = React.useState([])
  const [open, setOpen] = useState(false);
  const [searchInput, setSearchInput] = useState("");
  const [dataUser, setDataUser] = useState("")

  useEffect(() => {
    const localStorageData = typeof window !== 'undefined' && localStorage.getItem('user');
    if (localStorageData) { setDataUser(localStorageData); }
  }, []);


  const handleSearchChange = (e) => {
    setSearchInput(e.target.value);
  };
  const handleClickOpen = (rowData) => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };


  const fetchUsers = async () => {
    try {
      const res = await getMembers();
      const res_ = res?.data?.map((item, i) => {
        return {
          ...item,
          id: item._id.slice(-5),
          index: i,
        }
      })
      setRows(res_?.filter(i => i.user_type === "employee"))
    } catch (err) {
      console.log(err)
    }
  }

  useEffect(() => {
    fetchUsers();
  }, [])

  const generatePDF = useReactToPrint({
    content: () => componentPDF.current,
    documentTitle: "userData",
    // onAfterPrint:()=>alert("PDF generated successfully")
  });

  // table
  const handleDownloadClickTable = () => {
    setDownloadInProgress(true);
    setTimeout(() => {
      generatePDFPrintTable();
    }, 50);
  };

  const generatePDFPrintTable = useReactToPrint({
    content: () => componentPDFTable.current,
    documentTitle: "userData",
    onStart: () => setDownloadInProgress(true),
    onAfterPrint: () => setDownloadInProgress(false),
  });

  const formatDataForCSV = () => {
    const formattedData = rows.map((row) => ({
      "S.No": row.index,
      "First Name": row.first_name,
      "Last Name": row.last_name,
      "Mobile Number": row.mobile_number,
      "Email": row.email,
      "Date of Birth": row.dob,
      "Address": row.address,
      "Bank Account Number": row.bank_account_number,
      "IFSC Code": row.ifsc_code,
      "PAN Number": row.pan_number,
      "Employee Basic Salary": row.emp_basic_salary,
      "Employee CTC": row.emp_ctc,
      "Employee Joining Date": row.emp_joining_date,
      "Employee Qualification": row.emp_qualification,
      "User Type": row.user_type,
      "ID Proof Type": row.id_proof_type,
      "ID Number": row.id,
    }));
    return formattedData;
  };
  const columns = [
    { field: 'index', headerName: 'S.No' },
    { field: 'id', headerName: 'Id' },
    { field: 'first_name', headerName: 'Employee Name', width: 150, minWidth: 185, maxWidth: 200 },
    { field: 'position', headerName: 'Position', width: 150, minWidth: 185, maxWidth: 200 },
    { field: 'mobile_number', headerName: 'Contact Number', width: 150, minWidth: 185, maxWidth: 200 },
    { field: 'address_1', headerName: 'Address', width: 150, minWidth: 270, maxWidth: 220 },
  ];

  if (!downloadInProgress) {
    columns.push(
      {
        field: 'action',
        headerName: 'Action',
        width: 125, minWidth: 185, maxWidth: 200,
        renderCell: (item) => (
          <div>
            <IconButton aria-label="view" color='warning' onClick={() => handleView(item)}>
              <VisibilityIcon />
            </IconButton>
            <IconButton aria-label="edit" color='primary' onClick={() => handleEdit(item)}>
              <EditNoteIcon />
            </IconButton>
            <IconButton
              aria-label="delete"
              color="primary"
              onClick={() => handleClickOpen(item)}
            >
              <Download />
            </IconButton>
            {/* <IconButton aria-label="delete" color='error'>
              <CloseOutlinedIcon />
            </IconButton> */}
          </div>
        )
      }
    );
  }

  const handleEdit = (item) => {
    navigate('/employee-update', { state: { item: item?.row || {}, isUpdate: true } });
  };
  const handleView = (item) => {
    navigate('/view-employee-details', { state: { item: item?.row || {} } });
  };

  const filteredEmployeeData = rows.filter((employee) =>
    employee.first_name.toLowerCase().includes(searchInput.toLowerCase())
  );

  // const slicedColumns = columns.map(column => {
  //   if (column.field === '_id') {
  //     return { ...column, field: '_id', headerName: 'Id', flex: 0, valueGetter: (params) => params.getValue('_id').slice(-5) };
  //   }
  //   return column;
  // });

  return (
    <div className='dFlex justifyCenter alignCenter'>
      <div className="table-common">
        <div spacing={0} className="d-flex-space-bw">
          <div >
            <TextField
              type="text"
              size="small"
              placeholder="Search"
              value={searchInput}
              onChange={handleSearchChange}
              variant="outlined"
              fullWidth
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
            />
          </div>
          <div>
            <Typography variant="h5">
              Employee Information
            </Typography>
          </div>
          <div>
            <Button onClick={handleDownloadClickTable} variant="text">
              <IconButton onClick={handleDownloadClickTable} aria-label="edit" color="primary">
                <ArrowDownwardIcon />
              </IconButton>
              Download
            </Button>

            <Button variant="text">
              <CSVLink
                data={formatDataForCSV()}
                filename={"employee.csv"}
                className="csv-link"
                target="_blank"
              >
                <IconButton aria-label="edit" color="primary">
                  <ArrowDownwardIcon />
                </IconButton>
                CSV
              </CSVLink>
            </Button>
          </div>
        </div>
        <div>
          <div ref={componentPDFTable} style={{ padding: downloadInProgress ? "20px" : "0px" }}>
            {downloadInProgress && (
              <DownloadHeader />
            )}
            <div style={{ height: "calc(100% - 48px)", width: '100%', overflow: 'auto' }}>
              <DataGrid sx={{ minWidth: '900px !important' }} rows={filteredEmployeeData} columns={columns} pageSize={5} />
            </div>
          </div>
        </div>

      </div>

      <Dialog
        // fullScreen={fullScreen}
        open={open}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">
          {"All Employee Details"}
        </DialogTitle>
        <DialogContent>
          <div ref={componentPDF}>
            {/* credit receipt*/}
            <div className="main-container-single">
              <div spacing={0} className="d-flex-space-bw">
                <div>
                  <Typography variant="h5">Employee Information</Typography>
                </div>
              </div>
              <Grid container>
                <Grid item xs={12}>
                  <Divider />
                </Grid>
              </Grid>
              <Grid container spacing={3} className="flexContainer">
                <Grid item xs={4}>
                  <Typography variant="h6">test</Typography>
                </Grid>
                <Grid item xs={8}>
                  <Typography variant="body1">test</Typography>
                </Grid>
                <Grid item xs={4}>
                  <Typography variant="h6">test</Typography>
                </Grid>
                <Grid item xs={8}>
                  <Typography variant="body1">test</Typography>
                </Grid>
                <Grid item xs={4}>
                  <Typography variant="h6">test</Typography>
                </Grid>
                <Grid item xs={8}>
                  <Typography variant="body1">test</Typography>
                </Grid>
                <Grid item xs={4}>
                  <Typography variant="h6">test</Typography>
                </Grid>
                <Grid item xs={8}>
                  <Typography variant="body1">test</Typography>
                </Grid>
                <Grid item xs={4}>
                  <Typography variant="h6">test</Typography>
                </Grid>
                <Grid item xs={8}>
                  <Typography variant="body1">test</Typography>
                </Grid>
                <Grid item xs={12}>
                  <Divider />
                </Grid>
              </Grid>
            </div>
          </div>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleClose}>
            Cancel
          </Button>
          <Button onClick={generatePDF} autoFocus>Download receipt</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default DisplayMember;

