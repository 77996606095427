import React, { useEffect, useState, useRef } from "react";
import { DataGrid } from "@mui/x-data-grid";
import { Typography, IconButton, Button, TextField, InputAdornment } from "@mui/material";
import EditNoteIcon from "@mui/icons-material/EditNote";
import { useNavigate } from "react-router";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { getAllEvent } from "api/auth";
import { CSVLink } from 'react-csv';
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import SearchIcon from '@mui/icons-material/Search';
import { useReactToPrint } from "react-to-print";
import { DeleteForever } from "@mui/icons-material";
import CommonPopup from "component/CommonPopup";
import { deleteEvent } from "api/eventApi";

const DisplayEvents = () => {
  const [allEventList, setAllEventList] = useState([]);
  const [searchInput, setSearchInput] = useState("");
  const [downloadInProgress, setDownloadInProgress] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [selectedId, setSelectedId] = useState(null);
  const componentPDF = useRef();
  const componentPDFTable = useRef();
  const navigate = useNavigate();

  const fetchData = async () => {
    const res = await getAllEvent();
    const eventsWithId = res?.data?.map((event, index) => ({ ...event, id: index + 1 }));
    setAllEventList(eventsWithId);
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleSearchChange = (e) => setSearchInput(e.target.value);

  const handleEdit = (row) => {
    const queryParams = new URLSearchParams();
    Object.entries(row).forEach(([key, value]) => queryParams.append(key, value));
    navigate(`/add-event?${queryParams.toString()}`);
  };

  const handleView = (row) => {
    const queryParams = new URLSearchParams();
    Object.entries(row).forEach(([key, value]) => queryParams.append(key, value));
    navigate(`/view-event-details?${queryParams.toString()}`);
  };

  const handleDelete = async (id) => {
    try {
      const response = await deleteEvent(id);
      if (response.status) {
        alert('Event deleted successfully');
        fetchData();
      }
    } catch (error) {
      alert('Error deleting item:', error);
    }
    handleCloseModal();
  };

  const handleOpenModal = (id) => {
    setSelectedId(id);
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
    setSelectedId(null);
  };

  const formatDataForCSV = () => allEventList.map((event) => ({
    "S.NO": event.id,
    "Event Title": event.title,
    "Description": event.event_description,
    "Location": event.event_location,
    "Event Date Time": event.event_date_time,
    "Event Media Title": event.event_media,
    "Event Media URL": event.media_url,
  }));

  const generatePDFTable = useReactToPrint({
    content: () => componentPDFTable.current,
    documentTitle: "events_data",
    onStart: () => setDownloadInProgress(true),
    onAfterPrint: () => setDownloadInProgress(false),
  });

  const handleDownloadClickTable = () => {
    setDownloadInProgress(true);
    setTimeout(() => generatePDFTable(), 50);
  };

  const filteredEventList = allEventList.filter((event) => event.title.toLowerCase().includes(searchInput.toLowerCase()));

  const columns = [
    { field: "id", headerName: "S.NO", width: 125 },
    { field: "title", headerName: "Event Title", width: 125 },
    { field: "event_description", headerName: "Description", width: 125 },
    { field: "event_location", headerName: "Location", width: 125 },
    { field: "event_date_time", headerName: "Date Time", width: 125 },
    {
      field: "action", headerName: "Actions", width: 125, renderCell: (params) => (
        <div>
          <IconButton color="error" onClick={() => handleOpenModal(params.row._id)}>
            <DeleteForever />
          </IconButton>
          <IconButton color="warning" onClick={() => handleView(params.row)}>
            <VisibilityIcon />
          </IconButton>
          <IconButton color="primary" onClick={() => handleEdit(params.row)}>
            <EditNoteIcon />
          </IconButton>
        </div>
      )
    }
  ];

  return (
    <div className="dFlex justifyCenter alignCenter">
      <div className="table-common">
        <div className="d-flex-space-bw">
          <TextField
            size="small"
            placeholder="Search"
            value={searchInput}
            onChange={handleSearchChange}
            variant="outlined"
            fullWidth
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
          />
          <Typography variant="h5">Event Information</Typography>
          <div>
            <Button onClick={handleDownloadClickTable} variant="text" startIcon={<ArrowDownwardIcon />}>
              Download
            </Button>
            <Button variant="text">
              <CSVLink
                data={formatDataForCSV()}
                filename={"Event_information.csv"}
                className="csv-link"
                target="_blank"
                style={{ textDecoration: 'none', color: 'inherit' }}
              >
                <Button variant="text" startIcon={<ArrowDownwardIcon />}>
                  CSV
                </Button>
              </CSVLink>
            </Button>
          </div>
        </div>
        <div ref={componentPDFTable} style={{ padding: downloadInProgress ? "20px" : "0px" }}>
          {downloadInProgress && <DownloadHeader />}
          <div style={{ height: "calc(100% - 70px)", width: '100%', overflow: 'auto' }}>
            <DataGrid sx={{ minWidth: '750px !important' }} rows={filteredEventList} columns={columns} pageSize={5} getRowId={(row) => row._id} />
          </div>
        </div>
      </div>
      <CommonPopup
        title="Delete Confirmation"
        bodyContent={<p>Are you sure you want to delete this Event?</p>}
        open={openModal}
        handleClose={handleCloseModal}
        onConfirm={() => handleDelete(selectedId)}
      />
    </div>
  );
};

export default DisplayEvents;