import * as React from "react";
import { DataGrid } from "@mui/x-data-grid";
import {
  Typography,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  Grid,
  TextField,
  MenuItem,
  FormControl,
  InputLabel,
  Select,
  DialogActions,
  Button,
  Divider,
} from "@mui/material";
import EditNoteIcon from "@mui/icons-material/EditNote";
import { useNavigate } from "react-router";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { useEffect, useState, useRef } from "react";
import { getMembers } from "api/user";
import { Download } from "@mui/icons-material";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import { useReactToPrint } from "react-to-print";
import SearchIcon from "@mui/icons-material/Search";
import InputAdornment from "@mui/material/InputAdornment";
import { CSVLink } from "react-csv";
import NGOLogo from "component/Logo";
import DownloadHeader from "component/DownloadHeader";

const DisplayMember = () => {
  const componentPDF = useRef();
  const componentPDFTable = useRef();

  const navigate = useNavigate();
  const [downloadInProgress, setDownloadInProgress] = useState(false);
  const [downloadRecieptProcess, setDownloadRecieptProcess] = useState(false);
  const [rows, setRows] = React.useState([]);
  const [open, setOpen] = useState(false);
  const [selectedMemberId, setSelectedMemberId] = useState(null);
  const [selectedMemberData, setSelectedMemberData] = useState(null);
  const [searchInput, setSearchInput] = useState("");


  const handleSearchChange = (e) => {
    setSearchInput(e.target.value);
  };
  const handleClickOpen = (rowData) => {
    setSelectedMemberId(rowData.row.id);
    const selectedMember = rows.find((member) => member.id === rowData.row.id);
    setSelectedMemberData(selectedMember);
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const fetchUsers = async () => {
    try {
      const res = await getMembers();
      const res_ = res?.data?.map((item, i) => {
        return {
          ...item,
          id: item._id,
          index: i + 1,
        };
      });
      setRows(res_?.filter((i) => i.user_type === "member"));
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    fetchUsers();
  }, []);

  const handleDownloadClickDialog = () => {
    setDownloadRecieptProcess(true);
    setTimeout(() => {
      generatePDF();
    }, 50);
  };

  const generatePDF = useReactToPrint({
    content: () => componentPDF.current,
    documentTitle: "member_receipt",
  });
  // table
  const handleDownloadClickTable = () => {
    setDownloadInProgress(true);
    setTimeout(() => {
      generatePDFPrintTable();
    }, 50);
  };
  const generatePDFPrintTable = useReactToPrint({
    content: () => componentPDFTable.current,
    documentTitle: "userData",
    onAfterPrint: () => setDownloadInProgress(false),
  });
  const formatDataForCSV = () => {
    const formattedData = rows.map((row) => ({
      "S.No": row.index,
      Username: row.username,
      "Member Name": `${row.first_name} ${row.last_name}`,
      "Contact Number": row.mobile_number,
      Gender: row.gender,
      "D.O.B.": row.dob,
      "E-mail": row.email,
      "Emergency Name": row.emergency_name,
      "Emergency Number": row.emergency_number,
      "High Qualification": row.high_qualification,
      "Address 1": row.address_1,
      "Address 2": row.address_2,
      Area: row.area,
      City: row.city,
      State: row.state,
      PanNumber: row.panNumber,
      "Account Name": row.account_name,
      "Account Number": row.account_number,
      "Bank Name": row.bank_name,
      // "DIN Number": row.din_number,
      "IFSC Code": row.ifsc_code,
      "ID Proof Type": row.id_proof_type,
      "ID Number": row.id_number,
    }));
    return formattedData;
  };


  const columns = [
    { field: "index", headerName: "S.No", resizable: false },
    {
      field: "first_name",
      headerName: "Member Name",
      width: 150,
      minWidth: 185,
      maxWidth: 200,
    },

    {
      field: "mobile_number",
      headerName: "Contact Number",
      width: 150,
      minWidth: 185,
      maxWidth: 200,
    },
    {
      field: "address_1",
      headerName: "Address",
      width: 150,
      minWidth: 270,
      maxWidth: 200,
    },
  ];
  if (!downloadInProgress) {
    columns.push({
      field: "action",
      headerName: "Action",
      width: 150,
      minWidth: 185,
      maxWidth: 200,
      renderCell: (item) => (
        <div>
          <IconButton
            color="warning"
            aria-label="view"
            onClick={() => handleView(item)}
          >
            <VisibilityIcon />
          </IconButton>
          <IconButton
            color="primary"
            aria-label="edit"
            onClick={() => handleEdit(item)}
          >
            <EditNoteIcon />
          </IconButton>
          <IconButton
            aria-label="delete"
            color="error"
            onClick={() => handleClickOpen(item)}
          >
            <Download />
          </IconButton>
        </div>
      ),
    });
  }

  const handleEdit = (item) => {
    navigate("/member-update", {
      state: { item: item?.row || {}, isUpdate: true },
    });
  };
  const handleView = (item) => {
    navigate("/view-member-details", { state: { item: item?.row || {} } });
  };

  let filteredRows = rows.filter((row) =>
    row.first_name.toLowerCase().includes(searchInput.toLowerCase())
  );
  filteredRows = filteredRows.map((v, i) => {
    return { ...v, index: i + 1 };
  });

  const dob = new Date(selectedMemberData?.dob);
  const createdAt = new Date(selectedMemberData?.dob);
  const formattedDob = dob?.toLocaleDateString("en-GB", {
    day: "numeric",
    month: "long",
    year: "numeric",
  });
  const formattedJoining = createdAt?.toLocaleDateString("en-GB", {
    day: "numeric",
    month: "long",
    year: "numeric",
  });
  return (
    <div className="dFlex justifyCenter alignCenter">
      <div className="table-common">
        <div spacing={0} className="d-flex-space-bw">
          <div>
            <TextField
              type="text"
              size="small"
              placeholder="Search"
              value={searchInput}
              onChange={handleSearchChange}
              variant="outlined"
              fullWidth
              InputProps={{
                startAdornment: (
                  <IconButton>
                    <SearchIcon />
                  </IconButton>
                ),
              }}
            />
          </div>
          <div>
            <Typography variant="h5">Member Information</Typography>
          </div>
          <div>
            <Button onClick={handleDownloadClickTable} variant="text">
              <IconButton
                onClick={handleDownloadClickTable}
                aria-label="edit"
                color="primary"
              >
                <ArrowDownwardIcon />
              </IconButton>
              Download
            </Button>
            <Button variant="text">
              <CSVLink
                data={formatDataForCSV()}
                filename={"All_members.csv"}
                className="csv-link"
                target="_blank"
              >
                <IconButton aria-label="edit" color="primary">
                  <ArrowDownwardIcon />
                </IconButton>
                CSV
              </CSVLink>
            </Button>
          </div>
        </div>

        <div
          ref={componentPDFTable}
          style={{
            padding: downloadInProgress ? "20px" : "0px",
            height: "100%",
          }}
        >
          {downloadInProgress && <DownloadHeader />}
          <div style={{ height: "calc(100% - 48px)", width: "100%", overflow: 'auto' }}>
            <DataGrid
              sx={{ minWidth: "900px !important" }}
              rows={filteredRows}
              columns={columns}
              pageSize={5}
              pagination={!downloadInProgress}
            />
          </div>
        </div>
      </div>

      <Dialog
        // fullScreen={fullScreen}
        open={open}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title"></DialogTitle>

        <DialogContent>
          {selectedMemberData && (
            <div>
              <Grid container spacing={3} style={{ padding: downloadRecieptProcess ? '20px' : '0px' }}>
                <Grid item xs={12}>
                  <Typography variant="h4" style={{ color: '#fff', textAlign: 'center', backgroundColor: 'purple', padding: '30px 0px' }}>
                    Thanks for shopping with us
                  </Typography>
                </Grid>

                <Grid item xs={12}>
                  <Typography variant="h6">
                    Hi {selectedMemberData?.first_name}{" "}
                    {selectedMemberData?.last_name},</Typography>
                  <Typography variant="h6">
                    We have finished processing your order.
                  </Typography>
                </Grid>

                <Grid item xs={12}>
                  <Typography variant="h6" style={{ marginBottom: '10px', color: 'purple' }}>
                    <strong>Order #164617</strong> (07/06/2024)
                  </Typography>
                </Grid>

                <Grid item xs={4} style={{ border: '1px solid #c0c0c0' }}>
                  <Typography variant="h6">
                    <strong>Product</strong>
                  </Typography>
                </Grid>
                <Grid item xs={4} style={{ border: '1px solid #c0c0c0' }}>
                  <Typography variant="h6">
                    <strong>Quantity</strong>
                  </Typography>
                </Grid>
                <Grid item xs={4} style={{ border: '1px solid #c0c0c0' }}>
                  <Typography variant="h6">
                    <strong>Price</strong>
                  </Typography>
                </Grid>

                <Grid item xs={4} style={{ border: '1px solid #c0c0c0' }}>
                  <Typography variant="h6">WASender PRO (India) - 1 month</Typography>
                </Grid>
                <Grid item xs={4} style={{ border: '1px solid #c0c0c0' }}>
                  <Typography variant="h6">1</Typography>
                </Grid>
                <Grid item xs={4} style={{ border: '1px solid #c0c0c0' }}>
                  <Typography variant="h6">₹592.37 (ex. VAT)</Typography>
                </Grid>



                <Grid item xs={6} style={{ border: '1px solid #c0c0c0', borderBottom: '1px solid #c0c0c0' }}>
                  <Typography variant="h6">Subtotal:</Typography>
                </Grid>
                <Grid item xs={6} style={{ border: '1px solid #c0c0c0' }}>
                  <Typography variant="h6">₹592.37 (ex. VAT)</Typography>
                </Grid>

                <Grid item xs={6} style={{ border: '1px solid #c0c0c0' }}>
                  <Typography variant="h6">IGST:</Typography>
                </Grid>
                <Grid item xs={6} style={{ border: '1px solid #c0c0c0' }}>
                  <Typography variant="h6">₹106.83</Typography>
                </Grid>

                <Grid item xs={6} style={{ border: '1px solid #c0c0c0' }}>
                  <Typography variant="h6">Payment method:</Typography>
                </Grid>
                <Grid item xs={6} style={{ border: '1px solid #c0c0c0' }}>
                  <Typography variant="h6">Credit Card/Debit Card/NetBanking</Typography>
                </Grid>

                <Grid item xs={6} style={{ border: '1px solid #c0c0c0', borderBottom: '2px solid #c0c0c0' }}>
                  <Typography variant="h6">Total:</Typography>
                </Grid>
                <Grid item xs={6} style={{ border: '1px solid #c0c0c0', borderBottom: '2px solid #c0c0c0' }}>
                  <Typography variant="h6">₹699.00</Typography>
                </Grid>

                <Grid item xs={12}>
                  <Typography variant="h6" style={{ color: 'purple' }}>
                    <strong>Member Details</strong>
                  </Typography>
                </Grid>

                <Grid item xs={12} style={{ marginTop: '20px', border: '2px solid #c0c0c0' }}>
                  {/* <Typography variant="h6">08AAECJ1444K1ZF</Typography> */}
                  <Typography variant="h6"> Member Name:{selectedMemberData?.first_name}{" "}
                    {selectedMemberData?.last_name}</Typography>
                  {/* <Typography variant="h6">53, SHREE GANESH MAHAR NADI KA FATAK</Typography> */}
                  <Typography variant="h6">Address Line 1:{selectedMemberData.address_1}</Typography>
                  <Typography variant="h6">Address Line 2:{selectedMemberData.address_2}</Typography>
                  <Typography variant="h6"> Complete Address: {selectedMemberData.area}{" "}
                    {selectedMemberData.city} {selectedMemberData.state}</Typography>
                  <Typography variant="h6"> Mobile Number:{selectedMemberData.mobile_number}</Typography>
                  <Typography variant="h6">E-mail Address:{selectedMemberData.email}</Typography>
                </Grid>

                <Grid item xs={12}>
                  <Typography variant="h6" style={{ color: 'purple' }}>
                    <strong>Donation Details</strong>
                  </Typography>
                </Grid>
                <Grid item xs={12} style={{ marginTop: '20px', border: '2px solid #c0c0c0' }}>
                  <Typography variant="h6">Amount of Donation : {selectedMemberData.amount_donated}</Typography>
                </Grid>

                <Grid item xs={12}>
                  <Typography variant="h6" style={{ color: 'purple' }}>
                    <strong>Bank Details</strong>
                  </Typography>
                </Grid>
                <Grid item xs={12} style={{ marginTop: '20px', border: '2px solid #c0c0c0' }}>
                  <Typography variant="h6">Bank Name: {selectedMemberData.bank_name}</Typography>
                  <Typography variant="h6">IFSC Code: {selectedMemberData.ifsc_code}</Typography>
                  <Typography variant="h6">PAN Number: DUMMY123</Typography>
                </Grid>

                <Grid item xs={12}>
                  <Typography variant="h6" style={{ color: 'purple' }}>
                    <strong>Identification</strong>
                  </Typography>
                </Grid>
                <Grid item xs={12} style={{ marginTop: '20px', border: '2px solid #c0c0c0' }}>
                  <Typography variant="h6">ID Proof Type: {selectedMemberData.id_proof_type}</Typography>
                  <Typography variant="h6">ID Number: {selectedMemberData.id_number}</Typography>
                </Grid>

                <Grid item xs={12}>
                  <Typography variant="h6" style={{ color: 'purple' }}>
                    <strong>Emergency Contact</strong>
                  </Typography>
                </Grid>
                <Grid item xs={12} style={{ marginTop: '20px', border: '2px solid #c0c0c0' }}>
                  <Typography variant="h6">Emergency Name: {selectedMemberData.emergency_name}</Typography>
                  <Typography variant="h6">Emergency Number: {selectedMemberData.emergency_number}</Typography>
                  <Typography variant="h6">Emergency Relation:{" "}{selectedMemberData.emergency_relation}</Typography>
                </Grid>

                <Grid item xs={12}>
                  <Typography variant="h6" style={{ color: 'purple' }}>
                    <strong>Additional Information</strong>
                  </Typography>
                </Grid>
                <Grid item xs={12} style={{ marginTop: '20px', border: '2px solid #c0c0c0' }}>
                  <Typography variant="h6">DIN Number: {selectedMemberData.din_number}</Typography>
                  <Typography variant="h6">Joining Date: {formattedJoining}</Typography>
                </Grid>

              </Grid>
            </div>
          )}
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleClose}>
            Cancel
          </Button>
          <Button onClick={handleDownloadClickDialog} autoFocus>
            Download receipt
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default DisplayMember;
