import { Typography } from '@mui/material'
import NGOLogo from 'component/Logo'
import React from 'react'

const DownloadHeader = () => {
  return (
    <div className="pdf-topbar mb_40px">
      <div className="pdf-top-div">
        <Typography color="#fff" gutterBottom variant="h4">
          NGO-org pvt. ltd.
        </Typography>
        <div>address line 1</div>
        <div>address line 2</div>
      </div>
      <NGOLogo />
    </div>
  )
}

export default DownloadHeader