import React, { useState, useEffect } from "react";
import { Grid, TextField, Typography, Button } from "@mui/material";
import Divider from "@mui/material/Divider";
import "../../../Style/Custom.css";
// import FormGroup from '@mui/material/FormGroup';
// import FormControlLabel from '@mui/material/FormControlLabel'
// import Switch from '@mui/material/Switch';
import { addEmployeeCat, addMemberCat, getEmployeeCat } from "api/donor";
import { toast } from "react-toastify";
import { DataGrid } from "@mui/x-data-grid";
import { getMemberCat } from "api/donor";

const Addtype = () => {
  const [formData, setFormData] = useState({
    type: "",
    prefix: "",
    // status: ''
  });

  const [errors, setErrors] = useState({});
  const [prefixId, setPrefixId] = useState([]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  // const handleStatusChange = () => {
  //   const newStatus = formData.status === 'active' ? 'inactive' : 'active';
  //   setFormData({ ...formData, status: newStatus });
  // };

  const handleSubmit = async () => {
    if (validation()) {
      try {
        const _user = JSON.parse(sessionStorage.getItem("user"));
        const res = await addEmployeeCat({
          type: formData.type,
          prefix: formData.prefix,
          schema_name: _user.schema_name,
        });
        toast.success("Employee category added successfully.");
        setFormData({
          type: "",
          prefix: "",
        });
        setRefresh(refresh + 1);
      } catch (err) {
        toast.error("Employee category failed.");
        console.log(err);
      }
    }
  };

  const handleError = (inputs, value) => {
    setErrors((prev) => ({ ...prev, [inputs]: value }));
  };

  const validation = () => {
    var isValid = true;

    if (!formData.prefix) {
      handleError("prefix", "Please enter the prefix");
      isValid = false;
    }
    if (!formData.type) {
      handleError("type", "Please enter Member Category");
      isValid = false;
    }
    // if (!formData.status) {
    //   handleError("status", "Please enter Status");
    //   isValid = false;
    // }
    return isValid;
  };

  const [cats, setCats] = useState([]);
  const [refresh, setRefresh] = useState(0);

  const fetchCats = async () => {
    // const _user = JSON.parse(sessionStorage.getItem("user"));
    // const res = await getMemberCat({ schema_name: _user.schema_name });
    // setCats(
    //   res?.data?.map((v, i) => {
    //     return { label: v.category, prefix: v.prefix, id: i + 1 };
    //   })
    // );
  };

  const fetchPrefixs = async () => {
    const _user = JSON.parse(sessionStorage.getItem("user"));
    const res = await getEmployeeCat({ schema_name: _user.schema_name });
    const res_ = res?.data?.map((item, i) => {
      return {
        ...item,
        id: item._id.slice(-5),
        index: i + 1,
      }
    })
    // setPrefixId(
    //   res_.map((v) => {
    //     return { id: v?._id, prefix: v?.prefix, type: v?.type };
    //   })
    setPrefixId(
      res_.map((v) => {
        return { id: v?._id, prefix: v?.prefix, type: v?.type, index: v?.index };
      })

    );
  };

  useEffect(() => {
    fetchCats();
    fetchPrefixs();
  }, [refresh]);

  const columns = [
    { field: "index", headerName: "S.No", width: 125, minWidth: 255, maxWidth: 200 },
    { field: "type", headerName: "Employee Category", width: 125, minWidth: 255, maxWidth: 200 },
    { field: "prefix", headerName: "prefix", width: 125, minWidth: 255, maxWidth: 200 },
  ];

  return (
    <div>
      <div className="main-container">
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography color="textPrimary" variant="h5" className="heading2">
              Category Id Prefix
            </Typography>
            <Divider />
          </Grid>
          {/* <Grid item xs={12}>
            <Typography color="textPrimary" variant="h5">
              Basic Information
            </Typography>
          </Grid> */}
          <Grid item xs={12} md={12} lg={6}>
            <TextField
              name="type"
              label="Category Type"
              value={formData.type}
              onChange={handleInputChange}
              variant="outlined"
              required
              fullWidth
              error={!errors.type ? false : true}
              helperText={errors.type}
              onFocus={() => handleError("type", null)}
            />
            {/* <TextField
              select
              name="memberType"
              label="Member Category"
              value={formData.memberType}
              onChange={handleInputChange}
              variant="outlined"
              fullWidth
              error={!errors.memberType ? false : true}
              helperText={errors.memberType}
              onFocus={() => handleError('memberType', null)}
            >
              <MenuItem value="employee">Employee</MenuItem>
              <MenuItem value="volunteer">Volunteer</MenuItem>
            </TextField> */}
          </Grid>
          <Grid item xs={12} md={12} lg={6}>
            <TextField
              name="prefix"
              label="prefix"
              value={formData.prefix}
              onChange={handleInputChange}
              variant="outlined"
              required
              fullWidth
              error={!errors.prefix ? false : true}
              helperText={errors.prefix}
              onFocus={() => handleError("prefix", null)}
            />
          </Grid>
          <Grid item xs={12} className="dFlex justifyCenter">
            <Button variant="contained" onClick={handleSubmit}>
              Register
            </Button>
          </Grid>

          <div
            style={{
              height: "calc(100% - 48px)",
              width: "100%",
              marginTop: "1rem",
              overflow: 'auto'
            }}
          >
            <DataGrid sx={{ minWidth: '750px !important' }} rows={prefixId} columns={columns} pageSize={5} />
          </div>
        </Grid>
      </div>
    </div>
  );
};

export default Addtype;
