import React, { useState, useEffect, useRef } from "react";
import { DataGrid } from "@mui/x-data-grid";
import {
  FormControlLabel,
  FormGroup,
  Checkbox,
  Typography,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  Grid,
  TextField,
  MenuItem,
  FormControl,
  InputLabel,
  Select,
  DialogActions,
  Button,
  Divider,
} from "@mui/material";
import EditNoteIcon from "@mui/icons-material/EditNote";
import { useNavigate } from "react-router";
// import { DeleteForever } from '@mui/icons-material';
import VisibilityIcon from "@mui/icons-material/Visibility";
import { getDonor } from "api/getdonor";
import { Download } from "@mui/icons-material";
// import { updateDonor } from 'api/getdonor';
import { useReactToPrint } from "react-to-print";
import { CSVLink } from "react-csv";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import SearchIcon from "@mui/icons-material/Search";
import InputAdornment from "@mui/material/InputAdornment";
// import {loadScript} from "../../../component/razorpay/index"
import RenderRazorpay from "component/razorpay";
import DownloadHeader from "component/DownloadHeader";

const DisplayDonors = () => {
  const componentPDF = useRef();
  const navigate = useNavigate();
  const [print, setPrint] = useState(false);
  const [donorData, setDonorData] = useState([]);
  const [open, setOpen] = useState(false);
  const [openPay, setOpenPay] = useState(false);
  const [selectedDonorId, setSelectedDonorId] = useState(null);
  const [searchInput, setSearchInput] = useState(""); // State to store search input
  const componentPDFTable = useRef();
  const [printTable, setPrintTable] = useState(false);
  const [openRazor, setOpenRazorPay] = useState(false);
  const [downloadRecieptProcess, setDownloadRecieptProcess] = useState(false);
  const [downloadInProgress, setDownloadInProgress] = useState(false);
  const [paymentDetails, setPaymentDetails] = useState({
    orderId: "",
    amount: "",
  });
  const handleSearchChange = (e) => {
    setSearchInput(e.target.value);
  };

  useEffect(() => {
    const fetchDonors = async () => {
      try {
        const res = await getDonor();
        if (Array.isArray(res.data)) {
          const _data = res.data.map((item, index) => ({
            ...item,
            id: item?._id,
            index: index + 1,
          }));
          setDonorData(_data);
          countTotalRows();
        } else {
          console.error("Response data is not an array:", res.data);
        }
      } catch (error) {
        console.error("Error fetching donor data:", error);
      }
    };
    fetchDonors();
  }, []);

  const handleView = (item) => {
    navigate(`/view-donor-details`, { state: { donor: item?.row || {} } });
  };

  const handleEdit = (item) => {
    navigate(`/donor-register`, {
      state: { donor: item?.row, updateStatus: "update" || {} },
    });
  };

  const handleClickOpen = (rowData) => {
    setSelectedDonorId(rowData.row.id);
    setOpen(true);
  };
  // const handlePaymentDialog = (rowData) => {
  //   setSelectedDonorId(rowData.row.id);
  //   setOpenPay(true);
  // };

  const handleRedirect = (allData) => {
    const mobileNumber = allData.row.mobile_number;
    navigate("/donor-register", {
      state: { mobileNumber },
    });
  };

  const handleClose = () => {
    setOpen(false);
  };
  const handleClosePay = () => {
    setOpenPay(false);
  };
  const renderAddress = (item) => {
    const { street, city, state, postal_code } = item.row.address;
    return `${street}, ${city}, ${state}, ${postal_code}`;
  };

  const handleDownloadClickDialog = () => {
    setDownloadRecieptProcess(true);
    setTimeout(() => {
      generatePDF();
    }, 50);
  };
  const generatePDF = useReactToPrint({
    content: () => componentPDF.current,
    documentTitle: "donor_receipt",
  });
  // CSV ALL DONATIONS
  const formatDataForCSV = () => {
    let formattedData = [];
    let i = 1;
    donorData.forEach((row) => {
      row.donations.forEach((donation) => {
        formattedData.push({
          "S.No": i,
          "Donor Name": row.full_name,
          "User Type": row.user_type,
          "E-mail Address": row.email,
          "Contact Number": row.mobile_number,

          Country: row.address.country,
          State: row.address.state,
          City: row.address.city,
          Street: row.address.street,
          "Postal Code": row.address.postal_code,
          "Donation Cause": row.donated_for,
          "PAN Number": row.pan_number,

          "Donation Amount": donation.amount,
          "Donation Currency": donation.currency,
          "Donation Date": donation.donation_date,
          "Donation Purpose": donation.donation_purpose,
          "Donation By (Donor)": donation.donor,
          "Donation Method": donation.payment_method,
          "Donation Payment Received Date": donation.payment_received_date,
        });
        i++;
      });
    });

    return formattedData;
  };

  const columns = [
    { field: "index", headerName: "S.No.", resizable: false },
    { field: "full_name", headerName: "Donor Name", width: 150, minWidth: 185, maxWidth: 200 },
    { field: "user_type", headerName: "Donor Type", width: 150, minWidth: 185, maxWidth: 200 },
    { field: "mobile_number", headerName: "Contact Number", width: 150, minWidth: 185, maxWidth: 200 },
    {
      field: "address",
      headerName: "Address",
      valueGetter: renderAddress,
      width: 150, minWidth: 270, maxWidth: 200
    },
  ];

  if (!downloadInProgress) {
    columns.push({
      field: "action",
      headerName: "Action",
      width: 150,
      minWidth: 185,
      maxWidth: 200,
      renderCell: (item) => (
        <>
          <IconButton
            aria-label="view"
            color="warning"
            onClick={() => handleView(item)}
          >
            <VisibilityIcon />
          </IconButton>
          {/* <IconButton
            aria-label="edit"
            color="primary"
            onClick={() => handleEdit(item)}
          >
            <EditNoteIcon />
          </IconButton> */}
          {/* <IconButton
            aria-label="delete"
            color="error"
            onClick={() => handleClickOpen(item)}
          >
            <Download />
          </IconButton> */}
          <Button
            variant="outlined"
            size="small"
            onClick={() => handleRedirect(item)}
          >
            Re-Donate
          </Button>
        </>
      ),
    });
  }

  const generatePDFTable = () => {
    setPrintTable(true);
    generatePDFPrintTable();
  };

  // table
  const handleDownloadClickTable = () => {
    setDownloadInProgress(true);
    setTimeout(() => {
      generatePDFPrintTable();
    }, 50);
  };

  const generatePDFPrintTable = useReactToPrint({
    content: () => componentPDFTable.current,
    documentTitle: "donor_data",
    onStart: () => setDownloadInProgress(true),
    onAfterPrint: () => setDownloadInProgress(false),
  });

  const countTotalRows = () => {
    localStorage.setItem("donorDataLength", donorData.length);
    return donorData.length;
  };

  const selectedDonor = donorData.find((donor) => donor.id === selectedDonorId);

  const filteredDonorData = donorData.filter((donor) =>
    donor?.full_name?.toLowerCase()?.includes(searchInput?.toLowerCase())
  );

  return (
    <div className="dFlex justifyCenter alignCenter">
      {openRazor && <RenderRazorpay orderId={"1221"} amount={"100"} />}
      <div className="table-common">
        <div spacing={0} className="d-flex-space-bw">
          <div>
            <TextField
              type="text"
              size="small"
              placeholder="Search"
              value={searchInput}
              onChange={handleSearchChange}
              variant="outlined"
              fullWidth
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
            />
          </div>
          <div>
            <Typography variant="h5">Donor Information</Typography>
          </div>
          <div>
            <Button onClick={handleDownloadClickTable} variant="text">
              <IconButton
                onClick={handleDownloadClickTable}
                aria-label="edit"
                color="primary"
              >
                <ArrowDownwardIcon />
              </IconButton>
              Download
            </Button>
            <Button variant="text">
              <CSVLink
                data={formatDataForCSV()}
                filename={"Donors_information.csv"}
                className="csv-link"
                target="_blank"
              >
                <IconButton aria-label="edit" color="primary">
                  <ArrowDownwardIcon />
                </IconButton>
                CSV
              </CSVLink>
            </Button>
          </div>
        </div>
        {/* <div ref={componentPDFTable} 
          style={{ 
            padding: downloadInProgress ? "20px" : "0px", 
            // height: downloadInProgress ? "calc(100% - 48px)" : "1000px" 
            }}>
          {downloadInProgress && (
          <DownloadHeader/>
          )} */}
        <div
          style={{
            height: "calc(100% - 48px)",
            padding: downloadInProgress ? "20px" : "0px",
            overflow: 'auto',
            width: '100%',
          }}
          ref={componentPDFTable}
        >
          <DataGrid
            rows={filteredDonorData}
            columns={columns}
            pageSize={5}
            sx={{ minWidth: '900px !important' }}
          />
        </div>
        {/* </div> */}
      </div>

      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title"></DialogTitle>
        <DialogContent>
          <div ref={componentPDF}>
            <div className="main-container-single">
              {/* Render selected donor data for the receipt */}
              <Grid
                container
                spacing={2}
                style={{ padding: downloadRecieptProcess ? "20px" : "0px" }}
              >

                <Grid item lg={12}>
                  <Typography variant="h4" style={{ color: "red" }}>
                    ITVE HELP
                  </Typography>
                </Grid>
                <Grid item xs={12} lg={8}>
                  <Typography variant="h5">
                    Organization Name - Reg -122345 street- address here, City
                  </Typography>
                </Grid>
                {selectedDonor && (
                  <>
                    <Grid item xs={6}>
                      <Typography variant="h6">
                        Name: {selectedDonor.full_name}
                      </Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography variant="h6">
                        Amount: {selectedDonor.amount_donated}
                      </Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography variant="h6">
                        Donor Type: {selectedDonor.user_type}
                      </Typography>
                    </Grid>

                    <Grid item xs={6}>
                      <Typography variant="h6">
                        Contact: {selectedDonor.mobile_number}
                      </Typography>
                    </Grid>
                    <Grid item xs={12} lg={12} classname="dFlex">
                      <div classname="w60">
                        <Typography variant="h6">Address of Donor: </Typography>
                      </div>
                      <div classname="w40">
                        <Typography variant="h6">
                          {selectedDonor.address.street},{" "}
                          {selectedDonor.address.city},{" "}
                          {selectedDonor.address.state},{" "}
                          {selectedDonor.address.postal_code}
                        </Typography>
                      </div>
                    </Grid>
                    <Grid item xs={12} lg={12} classname="dFlex">
                      <div classname="w60">
                        <Typography variant="h6">
                          Mobile number of Donor :
                        </Typography>
                      </div>
                      <div classname="w40">
                        <Typography variant="h6">
                          {selectedDonor.mobile_number}
                        </Typography>
                      </div>
                    </Grid>
                    <Grid item xs={12} lg={12} classname="dFlex">
                      <div classname="w60">
                        <Typography variant="h6">Email of Donor :</Typography>
                      </div>
                      <div classname="w40">
                        <Typography variant="h6">
                          {selectedDonor.email}
                        </Typography>
                      </div>
                    </Grid>
                    <Grid item xs={12} lg={12} classname="dFlex">
                      <div classname="w60">
                        <Typography variant="h6">
                          Amount of Donation :
                        </Typography>
                      </div>
                      <div classname="w40">
                        <Typography variant="h6">
                          {selectedDonor.amount_donated}
                        </Typography>
                      </div>
                    </Grid>
                    <Grid item xs={12} lg={12} classname="dFlex">
                      <div classname="w60">
                        <Typography variant="h6">Transaction ID:</Typography>
                      </div>
                      <div classname="w40">
                        <Typography variant="h6">
                          {/* {selectedDonor.amount_donated} */}
                        </Typography>
                      </div>
                    </Grid>
                    <Grid item xs={12} lg={12} classname="dFlex">
                      <div classname="w60">
                        <Typography variant="h6">Transaction Date :</Typography>
                      </div>
                      <div classname="w40">
                        <Typography variant="h6">
                          {/* {selectedDonor.amount_donated} */}
                        </Typography>
                      </div>
                    </Grid>
                    <Grid item xs={12} lg={12} classname="dFlex">
                      <div classname="w60">
                        <Typography variant="h6">Payment Status :</Typography>
                      </div>
                      <div classname="w40">
                        <Typography variant="h6">
                          {/* {selectedDonor.amount_donated} */}
                        </Typography>
                      </div>
                    </Grid>
                    <Grid item xs={12} lg={12} classname="dFlex">
                      <div classname="w60">
                        <Typography variant="h6">Payment Mode :</Typography>
                      </div>
                      <div classname="w40">
                        <Typography variant="h6">
                          {/* {selectedDonor.amount_donated} */}
                        </Typography>
                      </div>
                    </Grid>
                    <div className="volunteer-p">
                      <p>
                        *50% of Voluntary Contribution, for the purpose of
                        renovation/repair of Mandir, to Shri Ram Janambhoomi
                        Teerth Kshetra is eligible for deduction under sec
                        80G(2)(b), subject to other conditions mentioned under
                        section 80G of the Income Tax Act, 1961(Vide
                        Notification No. 24/2020/F. No.176/8/2017/ITA-I). *Cash
                        donation in excess of Rs. 2,000 are not allowed as
                        deduction. *Note: This is a computer generated receipt
                        and does not require any signature.
                      </p>
                    </div>
                  </>
                )}
              </Grid>
            </div>
          </div>
        </DialogContent>

        <DialogActions>
          <Button autoFocus onClick={handleClose}>
            Cancel
          </Button>
          <Button onClick={handleDownloadClickDialog} autoFocus>
            Download receipt
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default DisplayDonors;
