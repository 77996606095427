import React, { useState, useEffect } from "react";
import { Grid, TextField, MenuItem, Typography, Button } from "@mui/material";
import Divider from "@mui/material/Divider";
import "../../../Style/Custom.css";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import { addMember, updateMember } from "api/user.js";
import { toast } from "react-toastify";
import { useLocation } from "react-router";
import Permission from "pages/Permissions";
import { getEmployeeCat, getMemberCat } from "api/donor";
import { createPayment } from "api/donor";
import RenderRazorpay from "component/razorpay";
import moment from "moment";

const AddMember = () => {
  const location = useLocation();
  const { item, isUpdate } = location?.state || {};

  const [selectedCheckboxes, setSelectedCheckboxes] = useState({});
  const [categoryId, setCategoryId] = useState();
  const [prefixId, setPrefixId] = useState([]);
  const [formData, setFormData] = useState({
    userType: "member",
    categoryId: categoryId,
    prefixId: prefixId,
    firstName: item?.first_name || "",
    lastName: item?.last_name || "",
    userName: "test" || "",
    //dinNumber: Math.random() || "",
    dob: moment(item?.dob).format("YYYY-MM-DD") || "",
    email: item?.email || "",
    password: "12345" || "",
    confirmpassword: item?.password || "",
    gender: item?.gender || "",
    contactNumber: item?.mobile_number || "",
    address1: item?.address_1 || "",
    address2: item?.address_2 || "",
    area: item?.area || "",
    pinCode: item?.pinCode || "",
    state: item?.state || "",
    city: item?.city || "",
    ngoName: "",
    ngoaddress: "",
    bankname: item?.bankName || "",
    accountname: item?.account_name || "",
    accountnumber: item?.account_number || "",
    branchname: item?.branch_name || "",
    ifscCode: item?.ifsc_code || "",
    reportingmanagername: "",
    highestqualification: item?.high_qualification || "",
    panNumber: "",
    aadhar_number: "",
    drivingNumber: item?.id_number || "",
    qualification: "",
    idtype: item?.pan_number || "",
    idnumber: item?.id_number || "",
    emergencyname: item?.emergency_name || "",
    emergencyphonenumber: item?.emergency_number || "",
    relation: item?.emergency_relation || "",
    status: "",
  });

  const [cats, setCats] = useState([]);
  const [amount, setAmount] = useState(0);
  const [openRazor, setOpenRazorPay] = useState(false);
  const [paymentDetails, setPaymentDetails] = useState({
    orderId: "",
    amount: "",
  });

  const fetchCats = async () => {
    const _user = JSON.parse(sessionStorage.getItem("user"));
    const res = await getMemberCat({ schema_name: _user.schema_name });
    setCats(
      res?.data?.map((v) => {
        return { label: v.category, amount: v.amount, id: v._id };
      })
    );
  };

  const fetchPrefixs = async () => {
    const _user = JSON.parse(sessionStorage.getItem("user"));
    const res = await getEmployeeCat({ schema_name: _user.schema_name });
    setPrefixId(
      res?.data?.map((v) => {
        return { id: v?._id, prefix: v?.prefix, type: v?.type };
      })
    );
  };
  useEffect(() => {
    fetchCats();
    fetchPrefixs();
  }, []);

  const [errors, setErrors] = useState({});

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (name === "categoryId") {
      setCategoryId(value?.id);
      setAmount(value?.amount);
      setFormData({
        ...formData,
        [name]: value?.id,
      });
      return;
    }
    if (name === "prefix") {
      setFormData({
        ...formData,
        [name]: value?.id,
      });
      return;
    }
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleStatusChange = () => {
    const newStatus = formData.status === "active" ? "inactive" : "active";
    setFormData({ ...formData, status: newStatus });
  };

  const newMemberAdd = async () => {
    const payload = {
      user_type: "member",
      categoryId: categoryId,
      prefixId: prefixId,
      first_name: formData.firstName,
      aadhar_number: formData.aadhar_number,
      last_name: formData.lastName,
      email: formData.email,
      username: formData.email,
      password: "12346",
      din_number: Math.random(),
      dob: formData.dob,
      gender: formData.gender,
      mobile_number: formData.contactNumber,
      address_1: formData.address1,
      address_2: formData.address2,
      area: formData.area,
      state: formData.state,
      city: formData.city,
      bank_name: formData.bankname,
      account_name: formData.accountname,
      account_number: formData.accountnumber,
      ifsc_code: formData.ifscCode,
      high_qualification: formData.highestqualification,
      pan_number: formData.panNumber,
      id_number: formData.idnumber,
      emergency_name: formData.emergencyname,
      emergency_number: formData.emergencyphonenumber,
      emergency_relation: formData.relation,
      access_routes: JSON.stringify(selectedCheckboxes?.[""]),
    };
    await addMember(payload);
    toast.success("Member added Successfull.");
  };

  const handleSubmit = async () => {

    const userSchema = JSON.parse(sessionStorage.getItem("user"));
    const payload = {
      user_type: "member",
      categoryId: categoryId,
      prefixId: prefixId,
      first_name: formData.firstName,
      aadhar_number: formData.aadhar_number,
      last_name: formData.lastName,
      email: formData.email,
      username: formData.email,
      password: "12346",
      din_number: Math.random(),
      dob: formData.dob,
      gender: formData.gender,
      mobile_number: formData.contactNumber,
      address_1: formData.address1,
      address_2: formData.address2,
      area: formData.area,
      state: formData.state,
      city: formData.city,
      bank_name: formData.bankname,
      account_name: formData.accountname,
      account_number: formData.accountnumber,
      ifsc_code: formData.ifscCode,
      high_qualification: formData.highestqualification,
      pan_number: formData.panNumber,
      id_number: formData.idnumber,
      emergency_name: formData.emergencyname,
      emergency_number: formData.emergencyphonenumber,
      emergency_relation: formData.relation,
      access_routes: JSON.stringify(selectedCheckboxes?.[""]),
    };

    const res = await createPayment({
      amount: amount,
      ngo_website: userSchema?.ngo_id?.ngo_website
    });
    if (res.order_id) {
      setPaymentDetails({
        ...paymentDetails,
        orderId: res.order_id,
        amount: res.amount,
      });
      setOpenRazorPay(true);
    }

    try {
      isUpdate && (await updateMember({ ...payload, userId: item?._id }));

      isUpdate && toast.success("Member update Successfull.");

      // if (!isUpdate) {
      //   setFormData({
      //     userType: "",
      //     prefixId: "",
      //     firstName: "",
      //     categoryId: "",
      //     lastName: "",
      //     userName: "",
      //     aadhar_number: "",
      //     dob: "",
      //     email: "",
      //     password: "",
      //     confirmpassword: "",
      //     gender: "",
      //     contactNumber: "",
      //     address1: "",
      //     address2: "",
      //     area: "",
      //     pinCode: "",
      //     state: "",
      //     city: "",
      //     ngoName: "",
      //     ngoaddress: "",
      //     bankname: "",
      //     accountname: "",
      //     accountnumber: "",
      //     branchname: "",
      //     ifscCode: "",
      //     reportingmanagername: "",
      //     highestqualification: "",
      //     panNumber: "",
      //     aadhar_number: "",
      //     drivingNumber: "",
      //     qualification: "",
      //     idtype: "",
      //     idnumber: "",
      //     emergencyname: "",
      //     emergencyphonenumber: "",
      //     relation: "",
      //     status: "",
      //   });
      // }
    } catch (err) {
      toast.error(err?.response?.data?.error || "Internal Server Error.");
    }
  };

  const handleError = (inputs, value) => {
    setErrors((prev) => ({ ...prev, [inputs]: value }));
  };

  const validation = () => {
    var isValid = true;

    if (!formData.firstName) {
      handleError("firstName", "Please enter your First Name");
      isValid = false;
    }

    if (!formData.lastName) {
      handleError("lastName", "Please enter your Last Name");
      isValid = false;
    }

    // if (!formData.userName) {
    //   handleError("userName", "Please enter User Name");
    //   isValid = false;
    // }
    if (!formData.userType) {
      handleError("userType", "Please enter User Type");
      isValid = false;
    }
    if (!formData.categoryId) {
      handleError("categoryId", "Please enter Category Type");
      isValid = false;
    }
    if (!formData.dob) {
      handleError("dob", "Please enter your DOB");
      isValid = false;
    }
    if (!formData.gender) {
      handleError("gender", "Please enter Gender");
      isValid = false;
    }
    if (!formData.email) {
      handleError("email", "Please enter your Email");
      isValid = false;
    } else if (
      !/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(formData.email)
    ) {
      handleError("email", "Please enter  valid Email");
      isValid = false;
    }

    if (!formData.contactNumber) {
      handleError("contactNumber", "Please enter your Contact Number");
      isValid = false;
    } else if (!/^[0-9]{10}$/.test(formData.contactNumber)) {
      handleError("contactNumber", "Please enter  valid Contact Number");
      isValid = false;
    }

    if (!formData.address1) {
      handleError("address1", "Please enter your Address");
      isValid = false;
    }

    if (!formData.area) {
      handleError("area", "Please enter your Area");
      isValid = false;
    }
    // if (!formData.dinNumber) {
    //   handleError("dinNumber", "Please enter your DIN Number");
    //   isValid = false;
    // }

    if (!formData.pinCode) {
      handleError("pinCode", "Please enter your PIN Code");
      isValid = false;
    } else if (!/^[0-9]{6}$/.test(formData.pinCode)) {
      handleError("pinCode", "PIN code must be a 6-Digit Number");
      isValid = false;
    }

    if (!formData.state) {
      handleError("state", "Please enter your State");
      isValid = false;
    }

    if (!formData.city) {
      handleError("city", "Please select your City");
      isValid = false;
    }

    if (!formData.basicsalary) {
      handleError("basicsalary", "Please enter your Basic Salary");
      isValid = false;
    }
    if (!formData.qualification) {
      handleError("qualification", "Please enter your Qualification");
      isValid = false;
    }
    if (!formData.designation) {
      handleError("designation", "Please enter your Designation");
      isValid = false;
    }
    if (!formData.joiningdate) {
      handleError("joiningdate", "Please enter your Joiningdate");
      isValid = false;
    }
    if (!formData.reportingmanagername) {
      handleError(
        "reportingmanagername",
        "Please enter Reporting Manager Name"
      );
      isValid = false;
    }
    if (!formData.highestqualification) {
      handleError("highestqualification", "Please enter highest Qualification");
      isValid = false;
    }
    if (!formData.idtype) {
      handleError("idtype", "Please enter id Type");
      isValid = false;
    }
    if (!formData.prefix) {
      handleError("prefix", "Please enter Prefix");
      isValid = false;
    }
    if (!formData.idnumber) {
      handleError("idnumber", "Please enter id Number");
      isValid = false;
    }
    if (!formData.emergencyname) {
      handleError("emergencyname", "Please enter Name");
      isValid = false;
    }
    if (!formData.emergencyphonenumber) {
      handleError("emergencyphonenumber", "Please enter Phone Number");
      isValid = false;
    }
    if (!formData.aadhar_number) {
      handleError("aadhar_number", "Please enter Aadhaar Number");
      isValid = false;
    }
    if (!formData.relation) {
      handleError("relation", "Please enter Relation Name");
      isValid = false;
    }

    // if (!formData.password) {
    //   handleError("password", "Please enter your Password");
    //   isValid = false;
    // }

    // if (!formData.confirmpassword) {
    //   handleError("confirmpassword", "Please confirm your Password");
    //   isValid = false;
    // } else if (formData.password !== formData.confirmpassword) {
    //   handleError("confirmpassword", "Passwords do not match");
    //   isValid = false;
    // }
    if (!formData.status) {
      handleError("status", "Please enter Status");
      isValid = false;
    }
    if (!formData.gender) {
      handleError("gender", "Please enter Gender");
      isValid = false;
    }

    return isValid;
  };
  return (
    <div>
      {openRazor && (
        <RenderRazorpay
          submitButton={newMemberAdd}
          orderId={paymentDetails.orderId}
          amount={paymentDetails.amount}
        />
      )}

      <div className="main-container">
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography color="textPrimary" variant="h5" className="heading2">
              Member Register
            </Typography>
            <Divider />
          </Grid>
          <Grid item xs={12}>
            <Typography color="textPrimary" variant="h5">
              Basic Information
            </Typography>
          </Grid>

          <Grid item xs={12} md={12} lg={6}>
            <TextField
              select
              name="categoryId"
              label="Member Category"
              value={formData.categoryId?.lable}
              onChange={handleInputChange}
              variant="outlined"
              error={!errors.categoryId ? false : true}
              helperText={errors.categoryId}
              onFocus={() => handleError("categoryId", null)}
              required
              fullWidth
            >
              {cats.map((item) => (
                <MenuItem value={item}>{item.label}</MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid item xs={12} md={12} lg={6}>
            <TextField
              select
              name="prefixId"
              label="Prefix"
              value={formData.prefixId}
              onChange={handleInputChange}
              variant="outlined"
              error={!errors.prefixId ? false : true}
              helperText={errors.prefixId}
              onFocus={() => handleError("prefixId", null)}
              required
              fullWidth
            >
              {prefixId.map((item) => (
                <MenuItem value={item}>{item.prefix}</MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid item xs={12} md={12} lg={6}>
            <TextField
              name="Amount"
              label="Donation Amount"
              value={amount}
              variant="outlined"
              required
              fullWidth
              disabled={true}
            />
          </Grid>
          <Grid item xs={12} md={12} lg={6}>
            <TextField
              name="firstName"
              label="First Name"
              value={formData.firstName}
              onChange={handleInputChange}
              variant="outlined"
              required
              fullWidth
              error={!errors.firstName ? false : true}
              helperText={errors.firstName}
              onFocus={() => handleError("firstName", null)}
            />
          </Grid>
          <Grid item xs={12} md={12} lg={6}>
            <TextField
              name="lastName"
              label="Last Name"
              value={formData.lastName}
              onChange={handleInputChange}
              variant="outlined"
              required
              fullWidth
              error={!errors.lastName ? false : true}
              helperText={errors.lastName}
              onFocus={() => handleError("lastName", null)}
            />
          </Grid>
          <Grid item xs={12} md={12} lg={6}>
            <TextField
              name="email"
              label=" Email Id"
              value={formData.email}
              onChange={handleInputChange}
              variant="outlined"
              required
              fullWidth
              error={!errors.email ? false : true}
              helperText={errors.email}
              onFocus={() => handleError("email", null)}
            />
          </Grid>

          <Grid item xs={12} md={12} lg={6}>
            <TextField
              name="dob"
              label="Date Of Birth"
              type="date"
              value={formData.dob}
              onChange={handleInputChange}
              variant="outlined"
              required
              fullWidth
              InputLabelProps={{ shrink: true }}
              error={!errors.dob ? false : true}
              helperText={errors.dob}
              onFocus={() => handleError("dob", null)}
            />
          </Grid>

          <Grid item xs={12} md={12} lg={6}>
            <TextField
              select
              name="gender"
              label="Gender"
              value={formData.gender}
              onChange={handleInputChange}
              variant="outlined"
              error={!errors.gender ? false : true}
              helperText={errors.gender}
              onFocus={() => handleError("gender", null)}
              required
              fullWidth
            >
              <MenuItem value="male">Male</MenuItem>
              <MenuItem value="female">Female</MenuItem>
              <MenuItem value="other">Other</MenuItem>
            </TextField>
          </Grid>
          <Grid item xs={12} md={12} lg={6}>
            <TextField
              name="contactNumber"
              label=" Contact Number "
              value={formData.contactNumber}
              onChange={handleInputChange}
              variant="outlined"
              required
              fullWidth
              error={!errors.contactNumber ? false : true}
              helperText={errors.contactNumber}
              onFocus={() => handleError("contactNumber", null)}
            />
          </Grid>
        </Grid>

        <Grid item className="mt_35px">
          <Typography color="textPrimary" variant="h5">
            Address Information
          </Typography>
        </Grid>
        <Grid container spacing={2} className="mt_2px">
          <Grid item xs={12} md={12} lg={6}>
            <TextField
              name="address1"
              label="Address Line 1"
              value={formData.address1}
              onChange={handleInputChange}
              variant="outlined"
              required
              fullWidth
              error={!errors.address1 ? false : true}
              helperText={errors.address1}
              onFocus={() => handleError("address1", null)}
            />
          </Grid>
          <Grid item xs={12} md={12} lg={6}>
            <TextField
              name="address2"
              label="Address Line 2"
              value={formData.address2}
              onChange={handleInputChange}
              variant="outlined"
              fullWidth
            />
          </Grid>
          <Grid item xs={12} md={12} lg={6}>
            <TextField
              name="area"
              label="Area"
              value={formData.area}
              onChange={handleInputChange}
              variant="outlined"
              required
              fullWidth
              error={!errors.area ? false : true}
              helperText={errors.area}
              onFocus={() => handleError("area", null)}
            />
          </Grid>

          <Grid item xs={12} md={12} lg={6}>
            <TextField
              name="state"
              label="State"
              value={formData.state}
              onChange={handleInputChange}
              variant="outlined"
              required
              fullWidth
              error={!errors.state ? false : true}
              helperText={errors.state}
              onFocus={() => handleError("state", null)}
            />
          </Grid>
          <Grid item xs={12} md={12} lg={6}>
            <TextField
              name="city"
              label="City"
              value={formData.city}
              onChange={handleInputChange}
              variant="outlined"
              required
              fullWidth
              error={!errors.city ? false : true}
              helperText={errors.city}
              onFocus={() => handleError("city", null)}
            ></TextField>
          </Grid>

          <Grid item xs={12} md={12} lg={6}>
            <TextField
              name="pinCode"
              label="PIN Code"
              value={formData.pinCode}
              onChange={handleInputChange}
              variant="outlined"
              required
              fullWidth
              error={!errors.pinCode ? false : true}
              helperText={errors.pinCode}
              onFocus={() => handleError("pinCode", null)}
            />
          </Grid>
        </Grid>

        {/* <Grid item style={{ marginTop: "35px" }}>
          <Typography color="textPrimary" variant="h5">
            Bank Details
          </Typography>
        </Grid>
        <Grid container spacing={2} style={{ marginTop: "2px" }}>
          <Grid item xs={12} md={12} lg={6}>
            <TextField
              name="bankname"
              label="Bank Name "
              value={formData.bankname}
              onChange={handleInputChange}
              variant="outlined"
              fullWidth
            />
          </Grid>
          <Grid item xs={12} md={12} lg={6}>
            <TextField
              name="accountname"
              label="Account Name"
              value={formData.accountname}
              onChange={handleInputChange}
              variant="outlined"
              fullWidth
            />
          </Grid>
          <Grid item xs={12} md={12} lg={6}>
            <TextField
              name="accountnumber"
              label="Account Number"
              value={formData.accountnumber}
              onChange={handleInputChange}
              variant="outlined"
              fullWidth
            />
          </Grid>

          <Grid item xs={12} md={12} lg={6}>
            <TextField
              name="ifscCode"
              label="IFSC Code "
              value={formData.ifscCode}
              onChange={handleInputChange}
              variant="outlined"
              fullWidth
            />
          </Grid>
          <Grid item xs={12} md={12} lg={6}>
            <TextField
              name="branchname"
              label="Branch Name (Autofetched from IFSC Code)"
              value={formData.branchname}
              onChange={handleInputChange}
              variant="outlined"
              disabled
              fullWidth
            />
          </Grid>
        </Grid> */}
        <Grid item className="mt_35px">
          <Typography color="textPrimary" variant="h5">
            Qualification Details
          </Typography>
        </Grid>
        <Grid container spacing={2} className="mt_2px">
          <Grid item xs={12} md={12} lg={6}>
            <TextField
              name="highestqualification"
              label="Highest Qualification"
              value={formData.highestqualification}
              onChange={handleInputChange}
              variant="outlined"
              error={!errors.highestqualification ? false : true}
              helperText={errors.highestqualification}
              onFocus={() => handleError("highestqualification", null)}
              required
              fullWidth
            />
          </Grid>
        </Grid>
        <Grid item className="mt_35px">
          <Typography color="textPrimary" variant="h5">
            Verification Details
          </Typography>
        </Grid>
        <Grid container spacing={2} className="mt_2px">
          {/* <Grid item xs={12} md={12} lg={6}>
            <TextField
              select
              name="idtype"
              label="ID Type"
              value={formData.idtype}
              onChange={handleInputChange}
              variant="outlined"
              error={!errors.idtype ? false : true}
              helperText={errors.idtype}
              onFocus={() => handleError("idtype", null)}
              required
              fullWidth
            >
              <MenuItem value="aadhar">Aadhaar Number</MenuItem>
              <MenuItem value="pan">PAN Number</MenuItem>
            </TextField>
          </Grid>

          <Grid item xs={12} md={12} lg={6}>
            <TextField
              name="idnumber"
              label="ID Number"
              value={formData.idnumber}
              onChange={handleInputChange}
              variant="outlined"
              error={!errors.idnumber ? false : true}
              helperText={errors.idnumber}
              onFocus={() => handleError("idnumber", null)}
              required
              fullWidth
            />
          </Grid> */}
          {/* <Grid item xs={12} md={12} lg={6}>
            <TextField
              name="aadhar_number"
              label="Aadhar Number"
              value={formData.aadhar_number}
              onChange={handleInputChange}
              variant="outlined"
              error={!errors.aadhar_number ? false : true}
              helperText={errors.aadhar_number}
              onFocus={() => handleError("aadhar_number", null)}
              required
              fullWidth
            />
          </Grid> */}
          <Grid item xs={12} md={12} lg={6}>
            <TextField
              name="panNumber"
              label="PAN Number"
              value={formData.panNumber}
              onChange={handleInputChange}
              variant="outlined"
              error={!errors.panNumber ? false : true}
              helperText={errors.panNumber}
              onFocus={() => handleError("panNumber", null)}
              required
              fullWidth
            />
          </Grid>
          <Grid item xs={12} md={12} lg={6}>
            <TextField
              type="number"
              name="aadhar_number"
              label="Aadhaar Number"
              value={formData.aadhar_number}
              onChange={handleInputChange}
              variant="outlined"
              error={!errors.aadhar_number ? false : true}
              helperText={errors.aadhar_number}
              onFocus={() => handleError("aadhar_number", null)}
              required
              fullWidth
            />
          </Grid>
        </Grid>

        <Grid item className="mt_35px">
          <Typography color="textPrimary" variant="h5">
            Emergency Contact Information
          </Typography>
        </Grid>
        <Grid container spacing={2} className="mt_2px">
          <Grid item xs={12} md={12} lg={6}>
            <TextField
              name="emergencyname"
              label="Name"
              value={formData.emergencyname}
              onChange={handleInputChange}
              variant="outlined"
              error={!errors.emergencyname ? false : true}
              helperText={errors.emergencyname}
              onFocus={() => handleError("emergencyname", null)}
              required
              fullWidth
            />
          </Grid>

          <Grid item xs={12} md={12} lg={6}>
            <TextField
              name="emergencyphonenumber"
              label="Phone Number"
              value={formData.emergencyphonenumber}
              onChange={handleInputChange}
              variant="outlined"
              error={!errors.emergencyphonenumber ? false : true}
              helperText={errors.emergencyphonenumber}
              onFocus={() => handleError("emergencyphonenumber", null)}
              required
              fullWidth
            />
          </Grid>

          <Grid item xs={12} md={12} lg={6}>
            <TextField
              name="relation"
              label="Relation"
              value={formData.relation}
              onChange={handleInputChange}
              variant="outlined"
              error={!errors.relation ? false : true}
              helperText={errors.relation}
              onFocus={() => handleError("relation", null)}
              required
              fullWidth
            />
          </Grid>
        </Grid>

        <Grid item className="mt_35px">
          <Typography color="textPrimary" variant="h5">
            Status
          </Typography>
        </Grid>
        <Grid container spacing={2} className="mt_2px">
          <Grid item xs={12} md={12} lg={6}>
            <FormGroup>
              <FormControlLabel
                control={
                  <Switch
                    checked={formData.status === "active"}
                    onChange={handleStatusChange}
                  />
                }
                label={formData.status === "active" ? "Active" : "Inactive"}
              />
            </FormGroup>
          </Grid>
          {/* <Grid item style={{ marginTop: "35px" }}>
            <Typography color="textPrimary" variant="h5">
              Set Permissions
            </Typography>
          </Grid>
          <Permission
            setSelectedCheckboxes={setSelectedCheckboxes}
            selectedCheckboxes={selectedCheckboxes}
          /> */}
          <Grid item xs={12} className="dFlex justifyCenter">
            <Button variant="contained" onClick={handleSubmit}>
              {isUpdate ? "Update" : "Register"}
            </Button>
          </Grid>
        </Grid>
      </div>
    </div>
  );
};

export default AddMember;
