import React, { useRef, useState } from "react";
import {
  Grid, Typography, Divider, Button, IconButton,
  DialogTitle,
  DialogContent,
  Dialog,
  DialogActions,
} from "@mui/material";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { useNavigate, useLocation } from "react-router";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import { useReactToPrint } from 'react-to-print';
import NGOLogo from "component/Logo";
import { DataGrid } from "@mui/x-data-grid";
import { Download } from "@mui/icons-material";

const ViewSingleMember = () => {
  const componentPDFTable = useRef();
  const [open, setOpen] = useState(false);

  const navigate = useNavigate();
  const location = useLocation();
  const { item } = location?.state || {};
  const handleClickBack = () => {
    navigate("/display-member");
  };

  const componentPDF = useRef();
  const componentPDFReceipt = useRef();

  const [downloadInProgress, setDownloadInProgress] = useState(false);

  const handleClose = () => {
    setOpen(false);
  };
  const handleDownloadClick = () => {
    setDownloadInProgress(true);

    setTimeout(() => {
      generatePDF();
    }, 50);
  };
  const handleClickOpen = () => {
    setOpen(true);
  };
  const dummyData = [
    { id: 1, username: "Credit Card", amount_donation: 100, donation_date: "2024-05-03" },
    { id: 2, username: "Bank Transfer", amount_donation: 150, donation_date: "2024-05-04" },
  ];

  const columns = [
    { field: "id", headerName: "S.no", width: 125, minWidth: 125, maxWidth: 200 },
    { field: "username", headerName: "Payment Method", width: 125, minWidth: 125, maxWidth: 200 },
    { field: "amount_donation", headerName: "Payment Amount", width: 125, minWidth: 125, maxWidth: 200 },
    { field: "donation_date", headerName: "Payment Date", width: 125, minWidth: 125, maxWidth: 200 },
    // { field: "note", headerName: "Payment Note", flex: 1 },
    {
      field: "action",
      headerName: "Action",
      width: 125, minWidth: 125, maxWidth: 200,
      renderCell: (item) => (
        <div>
          <IconButton
            aria-label="delete"
            color="error"
            onClick={() => handleClickOpen(item)}
          >
            <Download />
          </IconButton>
        </div>
      ),
    },
  ];

  const generatePDF = useReactToPrint({
    content: () => componentPDF.current,
    documentTitle: "userData",
    onAfterPrint: () => setDownloadInProgress(false),
  });
  const generatePDFReceipt = useReactToPrint({
    content: () => componentPDFReceipt.current,
    documentTitle: "Receipt",
    onAfterPrint: () => setOpen(false),
  });
  const generatePDFPrintTable = useReactToPrint({
    content: () => componentPDFTable.current,
    documentTitle: "userData",
    onAfterPrint: () => setDownloadInProgress(false),
  });
  return (
    <div className="main-container-single">
      <div spacing={0} className="d-flex-space-bw">
        <div>
          <Button onClick={handleClickBack} variant="text">
            <IconButton
              onClick={handleClickBack}
              aria-label="edit"
              color="primary"
            >
              <ArrowBackIosIcon />
            </IconButton>
            Back
          </Button>
        </div>
        <div>
          <Button onClick={handleDownloadClick} variant="text">
            <IconButton aria-label="edit" color="primary">
              <ArrowDownwardIcon />
            </IconButton>
            Download
          </Button>
        </div>
      </div>

      <Grid container spacing={3} className="pdf-grid" ref={componentPDF}>
        {downloadInProgress && (
          <div className="pdf-topbar">
            <div className="pdf-top-div">
              <Typography color="#fff" gutterBottom variant="h4">
                NGO-org pvt. ltd.
              </Typography>
              <div>address line 1</div>
              <div>address line 2</div>
            </div>
            <NGOLogo />
          </div>
        )}
        <div className="d-flex-center95" >
          <Typography variant="h5">Member Details</Typography>
        </div>
        <Grid container>
          <Grid item xs={12}>
            <Divider />
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <Typography color="textPrimary" gutterBottom variant="h4">
            Basic Information
          </Typography>
        </Grid>
        <Grid item xs={6} lg={6}>
          <Typography variant="h6">User Type:</Typography>
          <Typography>Member</Typography>
        </Grid>
        <Grid item xs={6} lg={6}>
          <Typography variant="h6">First Name:</Typography>
          <Typography>{item?.first_name}</Typography>
        </Grid>
        <Grid item xs={6} lg={6}>
          <Typography variant="h6">Last Name:</Typography>
          <Typography>{item?.last_name}</Typography>
        </Grid>
        <Grid item xs={6} lg={6}>
          <Typography variant="h6">Email:</Typography>
          <Typography>{item?.email}</Typography>
        </Grid>
        <Grid item xs={6} lg={6}>
          <Typography variant="h6">User Name:</Typography>
          <Typography>{item?.username}</Typography>
        </Grid>
        <Grid item xs={6} lg={6}>
          <Typography variant="h6">DIN:</Typography>
          <Typography>{item?.din_number}</Typography>
        </Grid>
        <Grid item xs={6} lg={6}>
          <Typography variant="h6">Date Of Birth:</Typography>
          <Typography>{item?.dob}</Typography>
        </Grid>
        <Grid item xs={6} lg={6}>
          <Typography variant="h6">Gender:</Typography>
          <Typography>{item?.gender}</Typography>
        </Grid>
        <Grid item xs={6} lg={6}>
          <Typography variant="h6">Contact Number:</Typography>
          <Typography>{item?.mobile_number}</Typography>
        </Grid>
        <Grid item xs={12}>
          <Divider />
        </Grid>

        <Grid item xs={12}>
          <Typography color="textPrimary" gutterBottom variant="h4">
            Address Information
          </Typography>
        </Grid>
        <Grid item xs={12} md={12} lg={6}>
          <Typography variant="h6">Address:</Typography>
          <Typography variant="body1">{item?.address_1}</Typography>
        </Grid>
        <Grid item xs={12}>
          <Divider />
        </Grid>

        <Grid item xs={12}>
          <Typography color="textPrimary" gutterBottom variant="h4">
            Bank Details
          </Typography>
        </Grid>
        <Grid item xs={6} lg={6}>
          <Typography variant="h6">Bank Name:</Typography>
          <Typography variant="body1">{item?.bank_name}</Typography>
        </Grid>
        <Grid item xs={6} lg={6}>
          <Typography variant="h6">Account Name:</Typography>
          <Typography variant="body1">{item?.account_name}</Typography>
        </Grid>
        <Grid item xs={6} lg={6}>
          <Typography variant="h6">Account Number:</Typography>
          <Typography variant="body1">{item?.account_number}</Typography>
        </Grid>
        <Grid item xs={6} lg={6}>
          <Typography variant="h6">IFSC Code:</Typography>
          <Typography variant="body1">{item?.ifsc_code}</Typography>
        </Grid>

        <Grid item xs={12}>
          <Divider />
        </Grid>

        <Grid item xs={12}>
          <Typography color="textPrimary" gutterBottom variant="h4">
            Qualification Details
          </Typography>
        </Grid>
        <Grid item xs={12} md={12} lg={6}>
          <Typography variant="h6">Highest Qualification:</Typography>
          <Typography variant="body1">{item?.high_qualification}</Typography>
        </Grid>
        <Grid item xs={12}>
          <Divider />
        </Grid>

        <Grid item xs={12}>
          <Typography color="textPrimary" gutterBottom variant="h4">
            Verification Details
          </Typography>
        </Grid>
        {/* <Grid item xs={6} lg={6}>
          <Typography variant="h6">ID Type:</Typography>
          <Typography variant="body1">{item?.id_proof_type}</Typography>
        </Grid>
        <Grid item xs={6} lg={6}>
          <Typography variant="h6">ID Number:</Typography>
          <Typography variant="body1">{item?.id_number}</Typography>
        </Grid> */}
        <Grid item xs={6} lg={6}>
          <Typography variant="h6">Aadhaar Number(yet to bind):</Typography>
          <Typography variant="body1">{item?.id_proof_type}</Typography>
        </Grid>
        <Grid item xs={6} lg={6}>
          <Typography variant="h6">PAN Number:(yet to bind)</Typography>
          <Typography variant="body1">{item?.id_number}</Typography>
        </Grid>
        <Grid item xs={12}>
          <Divider />
        </Grid>

        <Grid item xs={12}>
          <Typography color="textPrimary" gutterBottom variant="h4">
            Emergency Contact Information
          </Typography>
        </Grid>
        <Grid item xs={6} lg={6}>
          <Typography variant="h6">Name:</Typography>
          <Typography variant="body1">{item?.emergency_name}</Typography>
        </Grid>
        <Grid item xs={6} lg={6}>
          <Typography variant="h6">Phone Number:</Typography>
          <Typography variant="body1">{item?.emergency_number}</Typography>
        </Grid>
        <Grid item xs={6} lg={6}>
          <Typography variant="h6">Relation:</Typography>
          <Typography variant="body1">{item?.emergency_relation}</Typography>
        </Grid>
        <Grid item xs={12}>
          <Divider />
        </Grid>

        <Grid item xs={12}>
          <Typography color="textPrimary" gutterBottom variant="h4">
            Status Information
          </Typography>
        </Grid>
        <Grid item xs={12} md={12} lg={6}>
          <Typography variant="h6">Status:</Typography>
          <Typography variant="body1">Active</Typography>
        </Grid>
        <Grid item xs={12}>
          <Divider />
        </Grid>
        <div className="table-common">
          <div spacing={0} className="d-flex-space-bw">
            {/* <div >
            <TextField
              type="text"
              size="small"
              placeholder="Search"
              value={searchInput}
              onChange={handleSearchChange}
              variant="outlined"
              fullWidth
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
            />
          </div> */}
            <div className="d-flex-center90">
              <Typography variant="h5">Payment Information</Typography>
            </div>
            <div>
              <Button onClick={generatePDFPrintTable} variant="text">
                <IconButton
                  onClick={generatePDFPrintTable}
                  aria-label="edit"
                  color="primary"
                >
                  <ArrowDownwardIcon />
                </IconButton>
                Download
              </Button>
              {/* <Button variant="text" className="mr-10">
            <CSVLink
              data={formatDataForCSV()}
              filename={"members.csv"}
              className="csv-link"
              target="_blank"
            >
              <IconButton aria-label="edit" color="primary">
                <ArrowDownwardIcon />
              </IconButton>
              CSV
            </CSVLink>
          </Button> */}
            </div>
          </div>
          <div ref={componentPDFTable}>
            <div style={{ height: "calc(100% - 48px)", width: '100%', overflow: 'auto' }}>
              <DataGrid sx={{ minWidth: '750px !important' }} rows={dummyData} columns={columns} pageSize={5} />
            </div>
          </div>
        </div>
      </Grid>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title"></DialogTitle>
        <DialogContent>
          <div ref={componentPDFReceipt}>
            <div className="main-container-single">
              {/* Render selected donor data for the receipt */}
              <Grid
                container
                spacing={2}
                style={{ padding: print ? "20px" : "0px" }}
              >
                <Grid item lg={12}>
                  <Typography variant="h4" style={{ color: "red" }}>
                    ITVE HELP
                  </Typography>
                </Grid>


                <Grid item xs={12} lg={8}>
                  <Typography variant="h5">
                    Organization Name - Reg -122345 street- address here, City
                  </Typography>
                </Grid>

                <>
                  <Grid item xs={6}>
                    <Typography variant="h6">
                      Member Name:
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant="h6">
                      E-mail Address:
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant="h6">
                      Mobile Number:
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant="h6">
                      Address:
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant="h6">
                      PAN Number:
                    </Typography>

                  </Grid>
                  <Grid item xs={6}>

                    <Typography variant="h6">
                      Amount of Donation :
                    </Typography>

                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant="h6">
                      Transaction ID:
                    </Typography>

                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant="h6">
                      Transaction Date  :
                    </Typography>

                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant="h6">
                      Payment Status :
                    </Typography>

                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant="h6">
                      Payment Mode  :
                    </Typography>

                  </Grid>

                </>
                <div
                  style={{
                    width: "100%",
                    textAlign: "center",
                    margin: "20px",
                  }}
                >
                  <p>
                    *50% of Voluntary Contribution, for the purpose of
                    renovation/repair of Mandir, to Shri Ram Janambhoomi
                    Teerth Kshetra is eligible for deduction under sec
                    80G(2)(b), subject to other conditions mentioned under
                    section 80G of the Income Tax Act, 1961(Vide
                    Notification No. 24/2020/F. No.176/8/2017/ITA-I). *Cash
                    donation in excess of Rs. 2,000 are not allowed as
                    deduction. *Note: This is a computer generated receipt
                    and does not require any signature.
                  </p>
                </div>
              </Grid>
            </div>
          </div>
        </DialogContent>

        <DialogActions>
          <Button autoFocus onClick={handleClose}>
            Cancel
          </Button>
          <Button onClick={generatePDFReceipt} autoFocus>
            Download receipt
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default ViewSingleMember;

