import { useRoutes } from "react-router-dom";
import { useEffect, useState } from "react";

// routes
import MainRoutes from "./MainRoutes";
import AuthenticationRoutes from "./AuthenticationRoutes";
import _ from "lodash";

// ===============//===============|| ROUTING RENDER ||============================== //

export default function ThemeRoutes() {
  const [routes, setRoutes] = useState([]);
  const userRout = JSON.parse(sessionStorage.getItem("user"));

  if (userRout?.role !== "ngo-admin" && userRout?.role !== "super-admin") {
    const userRoutes = userRout?.me?.[0]?.access_routes
      ? JSON.parse(userRout?.me?.[0]?.access_routes)
      : [];

    const MainRoutes_ = MainRoutes?.children?.filter((v) =>
      userRoutes.includes(v.path)
    );
    MainRoutes.children = MainRoutes_;
  }

  if (userRout?.role === "super-admin") {
    const userRoutes = ['/ngo-register', '/display-ngo']
    const MainRoutes_ = MainRoutes?.children?.filter((v) =>
      userRoutes.includes(v.path)

    );
    MainRoutes.children = MainRoutes_;
  }

  useEffect(() => {
    const isLoggedIn = !_.isEmpty(sessionStorage.getItem("token"));
    if (isLoggedIn) {
      setRoutes([MainRoutes]);
    } else {
      setRoutes([AuthenticationRoutes]);
    }
  }, []);

  return useRoutes(routes);
}
