// assets
import NavigationOutlinedIcon from "@mui/icons-material/NavigationOutlined";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import AccountTreeOutlinedIcon from "@mui/icons-material/AccountTreeOutlined";
import AppsOutlinedIcon from "@mui/icons-material/AppsOutlined";
import ContactSupportOutlinedIcon from "@mui/icons-material/ContactSupportOutlined";
import BlockOutlinedIcon from "@mui/icons-material/BlockOutlined";
import ChromeReaderModeOutlinedIcon from "@mui/icons-material/ChromeReaderModeOutlined";
import SecurityOutlinedIcon from "@mui/icons-material/SecurityOutlined";
import HelpOutlineOutlinedIcon from "@mui/icons-material/HelpOutlineOutlined";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import Diversity2OutlinedIcon from "@mui/icons-material/Diversity2Outlined";
import VolunteerActivismOutlinedIcon from "@mui/icons-material/VolunteerActivismOutlined";
import CardMembershipOutlinedIcon from "@mui/icons-material/CardMembershipOutlined";
import BadgeOutlinedIcon from "@mui/icons-material/BadgeOutlined";
import PersonOutlinedIcon from "@mui/icons-material/PersonOutlined";
import WorkspacesOutlinedIcon from "@mui/icons-material/WorkspacesOutlined";
import WhereToVoteOutlinedIcon from "@mui/icons-material/WhereToVoteOutlined";
import CalendarMonthOutlinedIcon from "@mui/icons-material/CalendarMonthOutlined";
import ReceiptLongOutlinedIcon from "@mui/icons-material/ReceiptLongOutlined";
import PaymentsOutlinedIcon from "@mui/icons-material/PaymentsOutlined";
import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined";
import ViewListOutlinedIcon from "@mui/icons-material/ViewListOutlined";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import DashboardOutlinedIcon from "@mui/icons-material/DashboardOutlined";
import ApprovalIcon from "@mui/icons-material/Approval";
import ArticleOutlinedIcon from "@mui/icons-material/ArticleOutlined";
import LanguageOutlinedIcon from "@mui/icons-material/LanguageOutlined";
const icons = {
  NavigationOutlinedIcon: NavigationOutlinedIcon,
  HomeOutlinedIcon: HomeOutlinedIcon,
  ChromeReaderModeOutlinedIcon: ChromeReaderModeOutlinedIcon,
  HelpOutlineOutlinedIcon: HelpOutlineOutlinedIcon,
  SecurityOutlinedIcon: SecurityOutlinedIcon,
  AccountTreeOutlinedIcon: AccountTreeOutlinedIcon,
  BlockOutlinedIcon: BlockOutlinedIcon,
  AppsOutlinedIcon: AppsOutlinedIcon,
  ContactSupportOutlinedIcon: ContactSupportOutlinedIcon,
  FavoriteBorderIcon: FavoriteBorderIcon,
  Diversity2OutlinedIcon: Diversity2OutlinedIcon,
  CardMembershipOutlinedIcon: CardMembershipOutlinedIcon,
  VolunteerActivismOutlinedIcon: VolunteerActivismOutlinedIcon,
  BadgeOutlinedIcon: BadgeOutlinedIcon,
  PersonOutlinedIcon: PersonOutlinedIcon,
  WorkspacesOutlinedIcon: WorkspacesOutlinedIcon,
  WhereToVoteOutlinedIcon: WhereToVoteOutlinedIcon,
  CalendarMonthOutlinedIcon: CalendarMonthOutlinedIcon,
  ReceiptLongOutlinedIcon: ReceiptLongOutlinedIcon,
  PaymentsOutlinedIcon: PaymentsOutlinedIcon,
  AddCircleOutlineOutlinedIcon: AddCircleOutlineOutlinedIcon,
  ViewListOutlinedIcon: ViewListOutlinedIcon,
  InfoOutlinedIcon: InfoOutlinedIcon,
  DashboardOutlinedIcon: DashboardOutlinedIcon,
  ApprovalIcon: ApprovalIcon,
  ArticleOutlinedIcon: ArticleOutlinedIcon,
  LanguageOutlinedIcon: LanguageOutlinedIcon,
};

// eslint-disable-next-lin

export default {
  items: [
    {
      id: "navigation",
      // title: 'Shruti Patel',
      // caption: 'Manager',
      type: "group",
      icon: icons["NavigationOutlinedIcon"],
      children: [
        {
          id: "dashboard",
          title: "Dashboard",
          type: "item",
          icon: icons["DashboardOutlinedIcon"],
          url: "/dashboard/default",
          access: ["ngo-admin"],
        },
      ],
    },
    {
      id: "pages",
      type: "group",
      access: ["super-admin"],
      icon: icons["NavigationOutlinedIcon"],
      children: [
        {
          id: "ngo",
          title: "NGO",
          type: "collapse",
          icon: icons["Diversity2OutlinedIcon"],
          access: ["super-admin"],
          children: [
            {
              id: "add-ngo",
              title: "Add NGO",
              type: "item",
              url: "/ngo-register",
              access: ["super-admin"],
              icon: icons["AddCircleOutlineOutlinedIcon"],
            },
            {
              id: "view-ngo",
              title: "Display NGO",
              type: "item",
              access: ["super-admin"],
              url: "/display-ngo",
              icon: icons["ViewListOutlinedIcon"],
            },
          ],
        },

        {
          id: "prefix",
          title: "Id Prefix",
          type: "collapse",
          icon: icons["CardMembershipOutlinedIcon"],
          access: ["ngo-admin"],
          children: [
            {
              id: "prefix-id",
              title: "Add ID Prefix",
              type: "item",
              url: "/prefix-id",
              icon: icons["AddCircleOutlineOutlinedIcon"],
              access: ["ngo-admin"],
            },
          ],
        },
        {
          id: "member",
          title: "Member",
          type: "collapse",
          icon: icons["CardMembershipOutlinedIcon"],
          access: ["ngo-admin"],
          children: [
            {
              id: "add-member-type",
              title: "Add Member Category",
              type: "item",
              url: "/add-member-type",
              icon: icons["AddCircleOutlineOutlinedIcon"],
              access: ["ngo-admin"],
            },
            {
              id: "add-member",
              title: "Add Member",
              type: "item",
              url: "/add-member",
              icon: icons["AddCircleOutlineOutlinedIcon"],
            },
            {
              id: "display-member",
              title: "Members List",
              type: "item",
              url: "/display-member",
              icon: icons["ViewListOutlinedIcon"],
            },
            // {
            //   id: 'display-member-type',
            //   title: 'Display Member Category',
            //   type: 'item',
            //   url: '/display-member-type',
            //   icon: icons['ViewListOutlinedIcon'],
            //   access: ["ngo-admin"],
            // },
          ],
        },
        {
          id: "employee",
          title: "Employee",
          type: "collapse",
          icon: icons["BadgeOutlinedIcon"],
          access: ["ngo-admin"],
          children: [
            {
              id: "add-employee",
              title: "Add Empolyee",
              type: "item",
              url: "/add-employee",
              icon: icons["AddCircleOutlineOutlinedIcon"],
            },
            {
              id: "display-employee",
              title: "Display Employees",
              type: "item",
              url: "/display-employee",
              icon: icons["ViewListOutlinedIcon"],
            },
          ],
        },
        {
          id: "donor",
          title: "Donor",
          type: "collapse",
          icon: icons["PersonOutlinedIcon"],
          access: ["ngo-admin"],
          children: [
            {
              id: "add-donor",
              title: "Get Donation",
              type: "item",
              url: "/donor-register",
              icon: icons["AddCircleOutlineOutlinedIcon"],
            },
            {
              id: "display-donors",
              title: "Display Donors",
              type: "item",
              url: "/display-donors",
              icon: icons["ViewListOutlinedIcon"],
            },
          ],
        },

        // {
        //   id: "purpose",
        //   title: "Add Donation Purpose",
        //   type: "collapse",
        //   icon: icons["VolunteerActivismOutlinedIcon"],
        //   access: ["ngo-admin"],
        //   children: [
        //     {
        //       id: "add-donation-purpose",
        //       title: "Add Purpose",
        //       type: "item",
        //       url: "/add-donation-purpose",
        //       icon: icons["AddCircleOutlineOutlinedIcon"],
        //     },
        //     {
        //       id: "display-all-purpose",
        //       title: "Display Purposes",
        //       type: "item",
        //       url: "/display-all-purpose",
        //       icon: icons["ViewListOutlinedIcon"],
        //     },
        //   ],
        // },
        {
          id: "metadata",
          title: "Website Data",
          type: "collapse",
          url: "/metadata",
          icon: icons["LanguageOutlinedIcon"],
          access: ["ngo-admin"],
          children: [
            {
              id: "add-event",
              title: "Home Page",
              type: "item",
              url: "/metadata-home",
              icon: icons["HomeOutlinedIcon"],
            },
            {
              id: "about-us",
              title: "About Us Page",
              type: "item",
              url: "/metadata-about-us",
              icon: icons["InfoOutlinedIcon"],
            },
          ],
        },
        {
          id: "event",
          title: "Event",
          type: "collapse",
          url: "/event",
          icon: icons["WhereToVoteOutlinedIcon"],
          access: ["ngo-admin"],
          children: [
            {
              id: "add-event",
              title: "Create an Event",
              type: "item",
              url: "/add-event",
              icon: icons["AddCircleOutlineOutlinedIcon"],
            },
            {
              id: "view-event",
              title: "View All Events",
              type: "item",
              url: "/display-events",
              icon: icons["ViewListOutlinedIcon"],
            },
          ],
        },
        {
          id: "calendar",
          title: "Calendar",
          type: "item",
          url: "/calender",
          icon: icons["CalendarMonthOutlinedIcon"],
          access: ["ngo-admin"],
        },
        {
          id: "blog",
          title: "Blog",
          type: "collapse",
          url: "/event",
          icon: icons["ArticleOutlinedIcon"],
          access: ["ngo-admin"],
          children: [
            {
              id: "create-blog",
              title: "Create a Blog",
              type: "item",
              url: "/add-blog",
              icon: icons["AddCircleOutlineOutlinedIcon"],
            },
            {
              id: "display-blogs",
              title: "Display Blogs",
              type: "item",
              url: "/display-blogs",
              icon: icons["ViewListOutlinedIcon"],
            },
          ],
        },
        {
          id: "Finance",
          title: "Finance",
          type: "collapse",
          url: "/add-payment",
          icon: icons["ReceiptLongOutlinedIcon"],
          access: ["ngo-admin"],
          children: [
            {
              id: "add-payment",
              title: "Create Payment",
              type: "item",
              url: "/add-payment",
              icon: icons["AddCircleOutlineOutlinedIcon"],
              access: ["ngo-admin"],
            },
            {
              id: "payment-details",
              title: "Payment Details",
              type: "item",
              url: "/payment-details",
              icon: icons["ViewListOutlinedIcon"],
              access: ["ngo-admin"],
            },
            {
              id: "financial-report",
              title: "Financial Report",
              type: "item",
              url: "/download-receipts",
              icon: icons["ReceiptLongOutlinedIcon"],
              access: ["ngo-admin"],
            },
          ],
        },
      ],
    },
  ],
};
