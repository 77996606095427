import React, { useState, useEffect } from "react";
import { Grid, Button, TextField, MenuItem, Typography } from "@mui/material";

import "../../../Style/Custom.css";
import { addDonor } from "api/donor";
import { useLocation } from "react-router";
import { updateDonor } from "api/getdonor";
// import { getDonor } from "api/getdonor";
// import 'react-phone-input-2/lib/style.css';
// import { MuiTelInput } from 'mui-tel-input'
import { createPayment, addDonarDetails } from "api/donor";
import RenderRazorpay from "component/razorpay";
import { getDonorByNo } from "api/getdonor";
import Divider from "@mui/material/Divider";

const initalState = {
  user_type: "",
  full_name: "",
  email: "",
  mobile_number: "",
  street: "",
  city: "",
  state: "",
  country: "",
  postal_code: "",
  payment_method: "",
  currency: "₹",
  // payment_received_date: "",
  amount: "",
  donation_purpose: "",
  pan_number: "",
  donated_for: "",
};

const AddDonor = () => {
  const location = useLocation();
  const { donor, updateStatus } = location?.state || {};

  const [refresh, setRefresh] = useState(true);
  const [formData, setFormData] = useState(initalState);
  const [addresss, setAddress] = useState(initalState);

  const [isCompleted, setIsCompleted] = useState(false);
  const [isCompletedUpdate, setIsCompletedUpdate] = useState(false);

  const [submitStatus, setSubmitStatus] = useState("");
  const [openRazor, setOpenRazorPay] = useState(false);
  const [paymentDetails, setPaymentDetails] = useState({
    orderId: "",
    amount: "",
  });

  const handleInputChange = (e) => {
    if (e.target) {
      const { name, value } = e.target;
      setFormData((prevData) => ({ ...prevData, [name]: value }));
    }
  };
  const handleAddressInput = (e) => {
    if (e.target) {
      const { name, value } = e.target;
      setAddress((prevData) => ({ ...prevData, [name]: value }));
    }
  };
  useEffect(() => {
    if (donor) {
      setFormData(donor);
      setAddress(donor?.address);
    }
  }, [donor]);

  useEffect(() => {
    if (location?.state && location?.state?.mobileNumber) {
      const mobileNumber = location?.state?.mobileNumber;
      setNumber(mobileNumber);
      alert("button click");
      handleExist();
    }
  }, [location?.state]);

  const submitButton = async () => {
    const cd = new Date();
    const rec_date = `${cd.getFullYear()}/${cd.getMonth() + 1}/${cd.getDate()} ${cd.getHours()}:${cd.getMinutes()}:${cd.getSeconds()}`;

    // Construct the payload with form data
    const payload = {
      user_type: formData.user_type,
      full_name: formData.full_name,
      email: formData.email,
      pan_number: formData.pan_number,
      mobile_number: formData.mobile_number,
      address: {
        street: addresss.street,
        city: addresss.city || "temp",
        state: addresss.state,
        country: addresss.country,
        postal_code: addresss.postal_code,
      },

      donation: {
        payment_method: formData.payment_method,
        currency: formData.currency,
        payment_received_date: rec_date,
        amount: formData.amount,
        donation_purpose: "Test",
      },

      donated_for: formData.donated_for,
    };

    await addDonor(payload);
    alert("Successfully registered");
  };

  const handleSubmit = async () => {
    try {
      // Construct the current date and time
      const cd = new Date();
      const rec_date = `${cd.getFullYear()}/${cd.getMonth() + 1}/${cd.getDate()} ${cd.getHours()}:${cd.getMinutes()}:${cd.getSeconds()}`;
      const userSchema = JSON.parse(sessionStorage.getItem("user"));
      // Construct the payload with form data
      const payload = {
        user_type: formData.user_type,
        full_name: formData.full_name,
        email: formData.email,
        pan_number: formData.pan_number,
        mobile_number: formData.mobile_number,
        address: {
          street: addresss.street,
          city: addresss.city || "temp",
          state: addresss.state,
          country: addresss.country,
          postal_code: addresss.postal_code,
        },
        donation: {
          payment_method: formData.payment_method,
          currency: formData.currency,
          payment_received_date: rec_date,
          amount: formData.amount,
          donation_purpose: "Test",
        },
        donated_for: formData.donated_for,
      };

      // Proceed only if the payment method is UPI
      if (formData.payment_method === "UPI") {
        try {
          // Create the payment
          const res = await createPayment({ amount: formData.amount, ngo_website: userSchema?.ngo_id?.ngo_website });
          if (res.order_id) {
            // Update the payment details state
            setPaymentDetails({
              ...paymentDetails,
              orderId: res.order_id,
              amount: res.amount,
            });

            // Open RazorPay for user to complete payment
            setOpenRazorPay(true);

            // Assuming you have a way to check when payment is completed (e.g., Razorpay payment success callback)
            // Here's a placeholder function for payment completion (implement this as per your payment gateway's documentation)
            const paymentSuccess = await waitForPaymentCompletion();

            if (paymentSuccess) {
              // Parse user details from session storage
              const _user = JSON.parse(sessionStorage.getItem("user"));

              // Add donor details to the payment
              const donarRes = await addDonarDetails({
                email: payload.email,
                phone: payload.mobile_number,
                name: payload.full_name,
                pan: payload.pan_number,
                amount: res.amount,
                schema: _user.schema_name,
              });

              // Check if donor exists or if we need to create a new donor
              if (isExist) {
                await addDonor(payload);
                alert("Successfully registered");
                setIsCompleted(true);
                return;
              }

              if (submitStatus === "create") {
                await addDonor(payload);
                alert("Successfully registered");
                setIsCompleted(true);
              } else {
                await updateDonor(donor.id, payload);
                alert("Successfully updated");
                setIsCompletedUpdate(true);
              }
            } else {
              console.log("Payment was not successful");
            }
          }
        } catch (err) {
          console.error("Error during payment or donor addition process:", err);
        }
      } else {
        console.log("Form validation failed or unsupported payment method");
      }
    } catch (err) {
      console.error("Unexpected error:", err);
    }
  };

  // Placeholder function for waiting for payment completion
  // Implement this as per your payment gateway's documentation
  const waitForPaymentCompletion = async () => {
    // Logic to wait for payment to complete
    // Return true if payment is successful, false otherwise
    return new Promise((resolve) => {
      // Simulate payment success for demonstration purposes
      setTimeout(() => resolve(true), 9000);
    });
  };
  const [errors, setErrors] = useState({});

  const handleError = (inputs, value) => {
    setErrors((prev) => ({ ...prev, [inputs]: value }));
  };

  // Validation function
  const validateForm = () => {
    let isValid = true;

    if (!formData.full_name) {
      handleError("full_name", "Please Enter the Full Name");
      isValid = false;
    }

    if (!formData.user_type) {
      handleError("user_type", "Please Enter Donor Type ");

      isValid = false;
    }

    if (!formData.payment_method) {
      handleError("payment_method", "Please Select the Payment Method");

      isValid = false;
    }

    // if (!formData.utr) {
    //   handleError("utr", "Please Enter the UTR Number");

    //   isValid = false;
    // }

    // if (!formData.chequeNo) {
    //   handleError("chequeNo", "Please Enter the Cheque Number");

    //   isValid = false;
    // }

    if (!formData.email) {
      handleError("email", "Please Enter Donor Email Address");

      isValid = false;
    } else if (
      !/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(formData.email)
    ) {
      handleError("email", "Please Enter a Valid Email Address");

      isValid = false;
    }

    // if (!formData.website) {
    //   handleError('website', 'Please enter a website');
    //   isValid = false;
    // } else if (!/^(https?:\/\/)?(www\.)?[a-zA-Z0-9-]+(\.[a-zA-Z]{2,})+(\S*)?$/.test(formData.website)) {
    //   handleError('website', 'Please enter a valid website address');
    //   isValid = false;
    // }

    if (!formData.mobile_number) {
      handleError("mobile_number", "Please Enter a Phone Number");
      isValid = false;
    } else if (!/^[0-9]{10}$/.test(formData.mobile_number)) {
      handleError("mobile_number", "Please Enter a valid Phone Number");

      isValid = false;
    }

    if (!addresss.street) {
      handleError("street", "Please Enter the Address");

      isValid = false;
    }
    if (!addresss.country) {
      handleError("country", "Please Enter the Country");

      isValid = false;
    }
    if (!addresss.state) {
      handleError("state", "Please Enter the State");
      isValid = false;
    }

    if (!addresss.city) {
      handleError("city", "Please Select the City");
      isValid = false;
    }
    if (!addresss.postal_code) {
      handleError("postal_code", "Please Enter the PIN code");
      isValid = false;
    } else if (!/^[0-9]{6}$/.test(addresss.postal_code)) {
      handleError("postal_code", "PIN code must be a 6-digit number");
      isValid = false;
    }

    if (!formData.panNumber) {
      handleError("panNumber", "Please Enter the PAN Number");
      isValid = false;
      // } else if (!/^[A-Za-z]{5}[0-9]{4}[A-Za-z]{1}$/.test(formData.panNumber)) {
      //   handleError("panNumber", "Please Enter a valid PAN Number");
      //   isValid = false;
    }

    // // if (!formData.aadharNumber) {
    //   handleError("aadharNumber", "Please Enter the Aadhar Number");
    //   isValid = false;
    // } else if (!/^[0-9]{12}$/.test(formData.aadharNumber)) {
    //   handleError("aadharNumber", "Please Enter a Valid Aadhar Number");
    //   isValid = false;
    // }

    if (!formData.amount) {
      handleError("amount", "Please Enter the Donation Amount");
      isValid = false;
    }

    if (!formData.donated_for) {
      handleError("donated_for", "Please Enter the Donation Purpose");
      isValid = false;
    }

    if (!formData.currency) {
      handleError("currency", "Please Enter a Currency");
      isValid = false;
    }

    // if (!formData.idType) {
    //   handleError("idType", "Please Enter the ID Type");
    //   isValid = false;
    // }

    // if (!formData.pan_number) {
    //   handleError("pan_number", "Please Enter the ID Number");
    //   isValid = false;
    // }

    return isValid;
  };

  const [isExist, setIsExist] = useState(false);
  const [isTapped, setIsTapped] = useState(false);
  const [number, setNumber] = useState("");
  const [showNo, setShowNo] = useState(false);

  const handleExist = async () => {
    setShowNo(true);
    if (!number) {
      console.error("No mobile number provided.");
      return;
    } else {
      setRefresh(!refresh);
    }
    setIsExist(true);
    setIsTapped(true);

    try {
      const res = await getDonorByNo(number);
      const data = res.data;
      setFormData({
        user_type: "individual",
        full_name: data.full_name,
        email: data?.email,
        mobile_number: data?.mobile_number,
        payment_method: data?.payment_method,
        currency: "₹",
        amount: data?.amount,
        donation_purpose: "test",
        pan_number: data?.pan_number,
        donated_for: data?.donated_for,
      });
      setAddress({
        city: data?.address?.city,
        state: data?.address?.state,
        country: data?.address?.country,
        postal_code: data?.address?.postal_code,
        street: data?.address?.street,
      });
    } catch (err) {
      console.log("error:donor details not fetched properly", err);
    }
  };

  return (
    <div>
      {isTapped ? (
        <div className="main-container">
          {openRazor && (
            <RenderRazorpay
              orderId={paymentDetails.orderId}
              submitButton={submitButton}
              amount={paymentDetails.amount}
            />
          )}
          <Grid item>
            <Typography color="textPrimary" variant="h5" className="heading1">
              Donor Registration
            </Typography>
            <Divider />
          </Grid>

          <Grid item className="mt_35px mb_15px">
            <Typography color="textPrimary" variant="h5">
              General Information
            </Typography>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={12} md={12} lg={6}>
              <TextField
                select
                name="user_type"
                label="Donor Type"
                value={formData.user_type}
                onChange={handleInputChange}
                variant="outlined"
                fullWidth
                error={!errors.user_type ? false : true}
                helperText={errors.user_type}
                onFocus={() => handleError("user_type", null)}
                disabled={isExist}
              >
                <MenuItem value="individual">Individual</MenuItem>
                <MenuItem value="organization">Organization</MenuItem>
              </TextField>
            </Grid>
            <Grid item xs={12} md={12} lg={6}>
              <TextField
                name="full_name"
                label="Full Name"
                value={formData.full_name}
                onChange={handleInputChange}
                variant="outlined"
                required
                fullWidth
                error={!errors.full_name ? false : true}
                helperText={errors.full_name}
                onFocus={() => handleError("full_name", null)}
                disabled={isExist}
              />
            </Grid>
            {/* <Grid item xs={12} md={12} lg={6}>
           <TextField
             name="middleName"
             label="Middle Name"
             value={formData.middleName}
             onChange={handleInputChange}
             variant="outlined"
             required
             fullWidth
             error={!errors.middleName ? false : true}
             helperText={errors.middleName}
             onFocus={() => handleError("middleName", null)}
           />
         </Grid>
         <Grid item xs={12} md={12} lg={6}>
           <TextField
             name="lastName"
             label="Last Name"
             value={formData.lastName}
             onChange={handleInputChange}
             variant="outlined"
             required
             fullWidth
             error={!errors.lastName ? false : true}
             helperText={errors.lastName}
             onFocus={() => handleError("lastName", null)}
           />
         </Grid>  */}
            {/* <Grid item xs={12} md={12} lg={6}>
           <TextField
             name="dob"
             label="DOB"
             type="date"
             value={formData.dob}
             onChange={handleInputChange}
             variant="outlined"
             required
             fullWidth
             InputLabelProps={{ shrink: true }}
             error={!errors.dob ? false : true}
             helperText={errors.dob}
             onFocus={() => handleError("dob", null)}
           />
         </Grid> */}
            {/* <Grid item xs={12} md={12} lg={6}>
           <TextField
             select
             name="gender"
             label="Gender"
             value={formData.gender}
             onChange={handleInputChange}
             variant="outlined"
             fullWidth
             error={!errors.gender ? false : true}
             helperText={errors.gender}
             onFocus={() => handleError("gender", null)}
           >
             <MenuItem value="male">Male</MenuItem>
             <MenuItem value="female">Female</MenuItem>
           </TextField>
         </Grid> */}
            <Grid item xs={12} md={12} lg={6}>
              <TextField
                name="email"
                label="Email"
                type="email"
                value={formData.email}
                onChange={handleInputChange}
                variant="outlined"
                required
                fullWidth
                error={!errors.email ? false : true}
                helperText={errors.email}
                onFocus={() => handleError("email", null)}
                disabled={isExist}
              />
            </Grid>
            {/* <Grid item xs={12} md={12} lg={6}>
           <TextField
             name="username"
             label="User Name"
             type="username"
             value={"autogenerated-username"}
             onChange={handleInputChange}
             variant="outlined"
             required
             disabled
             fullWidth
           />
         </Grid> */}
            {/* <Grid item xs={12} md={12} lg={6}>
           <TextField
             name="website"
             label="Company Website"
             type="text"
             value={formData.website}
             onChange={handleInputChange}
             variant="outlined"
             fullWidth
           />
         </Grid> */}

            <Grid item xs={12} md={12} lg={6}>
              <TextField
                name="mobile_number"
                label="Phone"
                value={formData.mobile_number}
                onChange={handleInputChange}
                variant="outlined"
                required
                fullWidth
                error={!errors.mobile_number ? false : true}
                helperText={errors.mobile_number}
                onFocus={() => handleError("mobile_number", null)}
                disabled={isExist}
              />
            </Grid>
          </Grid>
          <Grid item className="mt_35px mb_15px">
            <Typography color="textPrimary" variant="h5">
              Address Information
            </Typography>
          </Grid>
          <Grid container spacing={2}>
            {/* <Grid item xs={12} md={12} lg={6}>
           <TextField
             name="address.street"
             label="Address Line - 1"
             value={formData.address.street}
             onChange={handleInputChange}
             variant="outlined"
             required
             fullWidth
             error={!errors.street ? false : true}
             helperText={errors.street}
             onFocus={() => handleError("street", null)}
           />
         </Grid>       */}

            <Grid item xs={12} md={12} lg={6}>
              <TextField
                name="country"
                label="Country"
                value={addresss.country}
                onChange={handleAddressInput}
                variant="outlined"
                required
                fullWidth
                error={!errors.country ? false : true}
                helperText={errors.country}
                onFocus={() => handleError("country", null)}
                disabled={isExist}
              />
            </Grid>

            <Grid item xs={12} md={12} lg={6}>
              <TextField
                name="street"
                label="Area/Street"
                value={addresss.street}
                onChange={handleAddressInput}
                variant="outlined"
                required
                fullWidth
                error={!errors.street ? false : true}
                helperText={errors.street}
                onFocus={() => handleError("street", null)}
                disabled={isExist}
              />
            </Grid>
            <Grid item xs={12} md={12} lg={6}>
              <TextField
                name="state"
                label="State"
                value={addresss.state}
                onChange={handleAddressInput}
                variant="outlined"
                required
                fullWidth
                error={!errors.state ? false : true}
                helperText={errors.state}
                onFocus={() => handleError("state", null)}
                disabled={isExist}
              />
            </Grid>
            <Grid item xs={12} md={12} lg={6}>
              <TextField
                // select
                name="city"
                label="City"
                value={addresss.city}
                onChange={handleAddressInput}
                variant="outlined"
                required
                fullWidth
                error={!errors.city ? false : true}
                helperText={errors.city}
                onFocus={() => handleError("city", null)}
                disabled={isExist}
              >
                {/* <MenuItem value="Indore">Indore</MenuItem>
                <MenuItem value="Jaipur">Jaipur</MenuItem> */}
              </TextField>
            </Grid>
            <Grid item xs={12} md={12} lg={6}>
              <TextField
                type="number"
                name="postal_code"
                label="PIN Code"
                value={addresss.postal_code}
                onChange={handleAddressInput}
                variant="outlined"
                required
                fullWidth
                error={!errors.postal_code ? false : true}
                helperText={errors.postal_code}
                onFocus={() => handleError("postal_code", null)}
                disabled={isExist}
              />
            </Grid>
          </Grid>
          <Grid item className="mt_35px mb_15px">
            <Typography color="textPrimary" variant="h5">
              User Identification Details
            </Typography>
          </Grid>
          <Grid container spacing={2}>
            {/* <Grid item xs={12} md={12} lg={6}>
           <TextField
             select
             name="idType"
             label="ID Proof"
             value={formData.idType}
             onChange={handleInputChange}
             variant="outlined"
             required
             fullWidth
             error={!errors.idType ? false : true}
             helperText={errors.idType}
             onFocus={() => handleError("idType", null)}
           >
             <MenuItem value="aadhar">Aadhar Number</MenuItem>
             <MenuItem value="pan">PAN Number</MenuItem>
             <MenuItem value="pan">Driving License</MenuItem>
           </TextField>
         </Grid> */}
            <Grid item xs={12} md={12} lg={6}>
              <TextField
                name="pan_number"
                label="PAN Number *"
                value={formData.pan_number}
                onChange={handleInputChange}
                variant="outlined"
                required
                fullWidth
                error={!errors.pan_number ? false : true}
                helperText={errors.pan_number}
                onFocus={() => handleError("pan_number", null)}
                disabled={isExist}
              />
            </Grid>
          </Grid>
          <Grid item className="mt_35px mb_15px">
            <Typography color="textPrimary" variant="h5">
              Payment Details
            </Typography>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={12} md={12} lg={6}>
              <TextField
                select
                name="currency"
                label="Currency"
                value={formData?.currency}
                onChange={handleInputChange}
                variant="outlined"
                fullWidth
                error={!errors.currency ? false : true}
                helperText={errors.currency}
                onFocus={() => handleError("currency", null)}
                disabled={true}
              >
                {/* <MenuItem value="$">$</MenuItem>
             <MenuItem value="€">€</MenuItem>
             <MenuItem value="¥">¥</MenuItem> */}
                <MenuItem value="₹">₹</MenuItem>
              </TextField>
            </Grid>
            <Grid item xs={12} md={12} lg={6}>
              <TextField
                name="amount"
                label="Donation Amount"
                value={formData?.amount}
                onChange={handleInputChange}
                variant="outlined"
                required
                fullWidth
                error={!errors.amount ? false : true}
                helperText={errors.amount}
                onFocus={() => handleError("amount", null)}
              />
            </Grid>
            {/* <Grid item xs={12} md={12} lg={6}>
              <TextField
                name="donation_purpose"
                label="Donation Purpose"
                value={formData?.donation_purpose}
                onChange={handleInputChange}
                variant="outlined"
                required
                fullWidth
                error={!errors.donateFor ? false : true}
                helperText={errors.donateFor}
                onFocus={() => handleError("donateFor", null)}
              />
            </Grid> */}
            <Grid item xs={12} md={12} lg={6}>
              <TextField
                name="donated_for"
                label="Donate For"
                value={formData.donated_for}
                onChange={handleInputChange}
                variant="outlined"
                required
                fullWidth
                error={!errors.donated_for ? false : true}
                helperText={errors.donated_for}
                onFocus={() => handleError("donated_for", null)}
              />
              {/* </Grid>
            <Grid item xs={12} md={12} lg={6}> */}
              <TextField
                select
                name="payment_method"
                label="Payment Method"
                value={formData?.payment_method}
                onChange={handleInputChange}
                variant="outlined"
                required
                fullWidth
                error={!errors.paymentMethod ? false : true}
                helperText={errors.paymentMethod}
                onFocus={() => handleError("paymentMethod", null)}
              >
                <MenuItem value="UPI">UPI</MenuItem>
                <MenuItem value="Cash">Cash</MenuItem>
              </TextField>
            </Grid>
            {/* <Grid item xs={12} md={12} lg={6}>
           <TextField
             name="utr"
             label="UTR Number"
             value={formData.utr}
             onChange={handleInputChange}
             variant="outlined"
             required
             fullWidth
             error={!errors.utr ? false : true}
             helperText={errors.utr}
             onFocus={() => handleError("utr", null)}
           />
         </Grid>

         <Grid item xs={12} md={12} lg={6}>
           <TextField
             name="chequeNo"
             label="Cheque Number"
             value={formData.chequeNo}
             onChange={handleInputChange}
             variant="outlined"
             required
             fullWidth
             error={!errors.chequeNo ? false : true}
             helperText={errors.chequeNo}
             onFocus={() => handleError("chequeNo", null)}
           />
         </Grid> */}
            {/* <Grid item xs={12} md={12} lg={6}>
           <TextField
             name="payment_received_date"
             label="Amount Received Date and Time"
             type="datetime-local"
             value={formData?.donation?.payment_received_date}
             onChange={handleInputChange}
             InputLabelProps={{
               shrink: true,
             }}
             fullWidth
             variant="outlined"
           />
         </Grid> */}

            <Grid
              item
              xs={12}
              style={{ display: "flex", justifyContent: "center" }}
            >
              <Button variant="contained" onClick={handleSubmit}>
                Submit
              </Button>
              {isCompleted && (
                <Typography color="textPrimary" variant="h5">
                  Donor Registration is Successfull.
                </Typography>
              )}
              {isCompletedUpdate && (
                <Typography color="textPrimary" variant="h5">
                  Donor Updation is Successfull.
                </Typography>
              )}
            </Grid>
          </Grid>
        </div>
      ) : (
        <div className="donor-prompt">
          <Button
            className="prompt-btn"
            variant="contained"
            onClick={() => {
              setIsExist(false);
              setIsTapped(true);
            }}
          >
            New Donor Register
          </Button>
          <div className="prompt-bar" />
          <div className="prompt-div2">
            {showNo && (
              <TextField
                value={number}
                label="Mobile Number"
                onChange={(e) => setNumber(e.target.value)}
              />
            )}
            {/* &nbsp; &nbsp; &nbsp; */}
            <Button
              variant="contained"
              onClick={handleExist}
              className="prompt-btn2"
            >
              {showNo ? "Submit" : "Existing Donor"}
            </Button>
          </div>
        </div>
      )}
    </div>
  );
};

export default AddDonor;
