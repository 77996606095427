import React, { useRef, useState } from "react";
import { Grid, Button, TextField, Typography, Divider } from "@mui/material";

import "../../../Style/Custom.css";
import { addBlog } from "api/auth";
import { createPayment_, updatePayment } from "api/donor";
import { useLocation } from "react-router-dom";

const AddPayment = (props) => {
  const location = useLocation();
  const item = location?.state?.item || {};
  const initalState = {
    paymentDate: item?.date || "",
    paymentAmount: item?.amount || "",
    paymentType: item?.type || "",
    paymentNote: item?.note || "",
    id: item?.id,
  };
  const [formData, setFormData] = useState(initalState);

  const handleInputChange = (e) => {
    if (e.target) {
      const { name, value } = e.target;
      setFormData((prevData) => ({ ...prevData, [name]: value }));
    }
  };
  const santialRef = useRef(null);
  const handleSubmit = async () => {
    if (validateForm()) {
      // const payload = new FormData();

      // payload.append("paymentType", formData.paymentType);
      // payload.append("paymentAmount", formData.paymentAmount);
      // payload.append("paymentNote", formData.paymentNote);
      // payload.append("paymentDate", formData.paymentDate);

      const payload = {
        type: formData.paymentType,
        amount: Number(formData.paymentAmount),
        date: formData.paymentDate,
        note: formData.paymentNote,
      };

      try {
        const _user = JSON.parse(sessionStorage.getItem("user"));
        if (formData?.id) {
          const res2 = await updatePayment({
            ...payload,
            schema: _user.schema_name,
            id: formData?.id,
          });
          return;
        }
        await createPayment_({ ...payload, schema: _user.schema_name }).then(
          (res) => {}
        );
      } catch (err) {
        if (err?.response?.status === 404) {
          //   toast.error("some-thing went wrong");
        } else {
          //   toast.error(err?.response?.message);
        }
      }
      setFormData(initalState);
    } else {
      console.log("Form validation failed");
    }
  };

  const [errors, setErrors] = useState({});

  const handleError = (inputs, value) => {
    setErrors((prev) => ({ ...prev, [inputs]: value }));
  };

  // Validation function
  const validateForm = () => {
    let isValid = true;

    if (!formData.paymentType) {
      handleError("paymentType", "Please Enter the Payment Type");
      isValid = false;
    }

    if (!formData.paymentAmount) {
      handleError("paymentAmount", "Please Enter the Payment Amount");

      isValid = false;
    }
    if (!formData.paymentNote) {
      handleError("paymentNote", "Please Enter the Payment Note");

      isValid = false;
    }
    if (!formData.paymentDate) {
      handleError("paymentDate", "Please Enter the Payment Date");

      isValid = false;
    }

    return isValid;
  };

  return (
    <div className="main-container">
      <Grid item>
        <Typography color="textPrimary" variant="h5" className="heading1">
          Add a new Payment
        </Typography>
        <Divider />
      </Grid>

      <Grid item className="mt_35px mb_15px">
        <Typography color="textPrimary" variant="h5">
          Payment Details
        </Typography>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={12} md={12} lg={6}>
          <TextField
            name="paymentType"
            label="Payment Type"
            type="text"
            value={formData.paymentType}
            onChange={handleInputChange}
            variant="outlined"
            required
            fullWidth
            error={!errors.paymentType ? false : true}
            helperText={errors.paymentType}
            onFocus={() => handleError("paymentType", null)}
          />
        </Grid>
        <Grid item xs={12} md={12} lg={6}>
          <TextField
            name="paymentAmount"
            label="Payment Amount"
            type="text"
            value={formData.paymentAmount}
            onChange={handleInputChange}
            variant="outlined"
            required
            fullWidth
            error={!errors.paymentAmount ? false : true}
            helperText={errors.paymentAmount}
            onFocus={() => handleError("paymentAmount", null)}
          />
        </Grid>
        <Grid item xs={12} md={12} lg={6}>
          <TextField
            name="paymentDate"
            label="Payment Date"
            type="text"
            value={formData.paymentDate}
            onChange={handleInputChange}
            variant="outlined"
            required
            fullWidth
            error={!errors.paymentDate ? false : true}
            helperText={errors.paymentDate}
            onFocus={() => handleError("paymentDate", null)}
          />
        </Grid>

        <Grid item xs={12} md={12} lg={6}>
          <TextField
            name="paymentNote"
            label="Payment Note"
            type="text"
            value={formData.paymentNote}
            onChange={handleInputChange}
            variant="outlined"
            required
            fullWidth
            error={!errors.paymentNote ? false : true}
            helperText={errors.paymentNote}
            onFocus={() => handleError("paymentNote", null)}
          />
        </Grid>

        <Grid item xs={12} className="dFlex justifyCenter">
          <Button variant="contained" onClick={handleSubmit}>
            {formData?.id ? "Update" : "Submit"}
          </Button>
        </Grid>
      </Grid>
    </div>
  );
};

export default AddPayment;
