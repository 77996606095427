import React, { useEffect, useState } from "react";
import { Grid, Button, TextField, MenuItem, Typography } from "@mui/material";
import Divider from "@mui/material/Divider";
import "../../../Style/Custom.css";
import { DropzoneArea } from "react-mui-dropzone";
import { addEvent } from "api/auth";
import { toast } from "react-toastify";
import { fileUpload } from "api/metadata";

const AddEvent = () => {
  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);

    // Create a temporary object to hold form data
    const tempFormData = {};

    // Extract each key-value pair from query parameters
    queryParams.forEach((value, key) => {
      // Map query parameter keys to form data fields
      switch (key) {
        case "title":
          tempFormData.eventTitle = value;
          break;
        case "event_description":
          tempFormData.eventDetails = value;
          break;
        case "event_date_time":
          tempFormData.eventDateTime = value;
          break;
        case "event_location":
          // Split the location string into area, city, state, and pinCode
          const [city, state, pinCode, area] = value.split(", ");
          tempFormData.area = area || "";
          tempFormData.city = city || "";
          tempFormData.state = state || "";
          tempFormData.pinCode = pinCode || "";
          break;
        case "media_url":
          tempFormData.mediaUrl = value;
          break;
        // Add additional case statements for any other parameters you want to handle
      }
    });

    // Update formData state with the extracted data
    setFormData((prevData) => ({
      ...prevData,
      ...tempFormData,
    }));
  }, [location.search]);

  const [formData, setFormData] = useState({
    addevent: "",
    eventTitle: "",
    eventDetails: "",
    goal: "",
    raised: "",
    address1: "",
    address2: "",
    mediaUrl: "",
    contactName: "",
    contactNumber: "",
    eventDateTime: "",
    mediaType: "",
    area: "",
    pinCode: "",
    state: "",
    city: "",
  });

  const [errors, setError] = useState({});

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async () => {
    if (validation()) {
      const payload = {
        title: formData.eventTitle,
        event_description: formData.eventDetails,
        event_location: `${formData.city}, ${formData.state}, ${formData.pinCode}, ${formData.area}`,
        event_date_time: formData.eventDateTime,
        media_url: formData.mediaUrl,
        goal: formData.goal,
        raised: formData.raised,
      };

      try {
        await addEvent(payload).then((res) => {});
      } catch (err) {
        if (err?.response?.status === 404) {
          toast.error("some-thing went wrong");
        } else {
          toast.error(err?.response?.message);
        }
      }
    } else {
      console.log("Form validation failed");
    }
  };

  const handleError = (inputs, value) => {
    setError((prev) => ({ ...prev, [inputs]: value }));
  };

  const validation = () => {
    let isValid = true;

    if (!formData.eventTitle) {
      handleError("eventTitle", "Please enter  Event Title");
      isValid = false;
    }

    if (!formData.eventDetails) {
      handleError("eventDetails", "Please enter  Event Details");
      isValid = false;
    }

    if (!formData.pinCode) {
      handleError("pinCode", "Please enter your PIN code");
      isValid = false;
    } else if (!/^[0-9]{6}$/.test(formData.pinCode)) {
      handleError("pinCode", "PIN code must be a 6-Digit Number");
      isValid = false;
    }

    if (!formData.state) {
      handleError("state", "Please enter your State");
      isValid = false;
    }

    if (!formData.city) {
      handleError("city", "Please select your City");
      isValid = false;
    }

    if (!formData.mediaUrl) {
      handleError("mediaUrl", "Please enter  Media URL");
      isValid = false;
    }

    if (!formData.eventDateTime) {
      handleError("eventDateTime", "Please enter Event Date and time");
      isValid = false;
    }

    return isValid;
  };

  const handleFileChange = async (files) => {
    const file = files?.[0];
    const _formData = new FormData();
    _formData.append("file", file);
    try {
      const res = await fileUpload(_formData);
      setFormData({ ...formData, mediaUrl: res?.data?.Location });
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <div className="main-container">
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography color="textPrimary" variant="h5" className="heading2">
            Events
          </Typography>
          <Divider />
        </Grid>
        <Grid item xs={12}>
          <Typography color="textPrimary" variant="h5">
            Basic Information
          </Typography>
        </Grid>

        <Grid item xs={12} md={12} lg={6}>
          <TextField
            name="eventTitle"
            label="Event Title"
            value={formData.eventTitle}
            onChange={handleInputChange}
            variant="outlined"
            required
            fullWidth
            error={!errors.eventTitle ? false : true}
            helperText={errors.eventTitle}
            onFocus={() => handleError("eventTitle", null)}
          />
        </Grid>

        <Grid item xs={12} md={12} lg={6}>
          <TextField
            id="outlined-multiline-flexible"
            multiline
            maxRows={4}
            name="eventDetails"
            label="Description"
            value={formData.eventDetails}
            onChange={handleInputChange}
            variant="outlined"
            error={!errors.eventDetails ? false : true}
            helperText={errors.eventDetails}
            onFocus={() => handleError("eventDetails", null)}
            required
            fullWidth
          />
        </Grid>
        <Grid item xs={12} md={12} lg={6}>
          <TextField
            id="outlined-multiline-flexible"
            multiline
            maxRows={4}
            name="raised"
            label="Raised"
            value={formData.raised}
            onChange={handleInputChange}
            variant="outlined"
            error={!errors.raised ? false : true}
            helperText={errors.raised}
            onFocus={() => handleError("raised", null)}
            required
            fullWidth
          />
        </Grid>
        <Grid item xs={12} md={12} lg={6}>
          <TextField
            id="outlined-multiline-flexible"
            multiline
            maxRows={4}
            name="goal"
            label="Goal"
            value={formData.goal}
            onChange={handleInputChange}
            variant="outlined"
            error={!errors.goal ? false : true}
            helperText={errors.goal}
            onFocus={() => handleError("goal", null)}
            required
            fullWidth
          />
        </Grid>
      </Grid>
      <Grid item className="mt_35px">
        <Typography color="textPrimary" variant="h5">
          Date And Time
        </Typography>
      </Grid>
      <Grid container spacing={2} className="mt_2px">
        <Grid item xs={12} md={12} lg={6}>
          <TextField
            name="eventDateTime"
            label="Event Date and Time"
            type="datetime-local"
            value={
              formData.eventDateTime
                ? new Date(formData.eventDateTime).toISOString().slice(0, 16)
                : ""
            }
            onChange={handleInputChange}
            variant="outlined"
            error={!errors.eventDateTime ? false : true}
            helperText={errors.eventDateTime}
            onFocus={() => handleError("eventDateTime", null)}
            required
            fullWidth
            InputLabelProps={{ shrink: true }}
          />
        </Grid>
      </Grid>

      <Grid item className="mt_35px">
        <Typography color="textPrimary" variant="h5">
          Event Venu
        </Typography>
      </Grid>
      <Grid container spacing={2} className="mt_2px">
        <Grid item xs={12} md={12} lg={6}>
          <TextField
            name="area"
            label="Area"
            value={formData.area}
            onChange={handleInputChange}
            variant="outlined"
            required
            fullWidth
            error={!errors.area ? false : true}
            helperText={errors.area}
            onFocus={() => handleError("area", null)}
          />
        </Grid>
        <Grid item xs={12} md={12} lg={6}>
          <TextField
            name="city"
            label="City"
            value={formData.city}
            onChange={handleInputChange}
            variant="outlined"
            required
            fullWidth
            error={!errors.city ? false : true}
            helperText={errors.city}
            onFocus={() => handleError("city", null)}
          ></TextField>
        </Grid>
        <Grid item xs={12} md={12} lg={6}>
          <TextField
            name="state"
            label="State"
            value={formData.state}
            onChange={handleInputChange}
            variant="outlined"
            required
            fullWidth
            error={!errors.state ? false : true}
            helperText={errors.state}
            onFocus={() => handleError("state", null)}
          />
        </Grid>

        <Grid item xs={12} md={12} lg={6}>
          <TextField
            name="pinCode"
            label="PIN Code"
            value={formData.pinCode}
            onChange={handleInputChange}
            variant="outlined"
            required
            fullWidth
            error={!errors.pinCode ? false : true}
            helperText={errors.pinCode}
            onFocus={() => handleError("pinCode", null)}
          />
        </Grid>
      </Grid>

      {/* <Grid item className="mt_35px">
        <Typography color="textPrimary" variant="h5">
          Contact Person
        </Typography>
      </Grid> */}

      <Grid item className="mt_35px">
        <Typography color="textPrimary" variant="h5">
          Media
        </Typography>
      </Grid>
      <Grid container spacing={2} className="mt_2px">
        <Grid item xs={12} md={12} lg={6}>
          <TextField
            name="mediaUrl"
            label="Media URL"
            value={formData.mediaUrl}
            onChange={handleInputChange}
            variant="outlined"
            error={!errors.mediaUrl ? false : true}
            helperText={errors.mediaUrl}
            onFocus={() => handleError("mediaUrl", null)}
            required
            fullWidth
          />
        </Grid>

        <Grid item xs={12}>
          <DropzoneArea
            acceptedFiles={["image/*", "video/*", "audio/*"]}
            filesLimit={3}
            dropzoneText="Drag and drop or click here to add media (audio/image/video)"
            onChange={(files) => handleFileChange(files)}
          />
        </Grid>

        <Grid item xs={12} className="dFlex justifyCenter">
          <Button variant="contained" onClick={handleSubmit}>
            Add
          </Button>
        </Grid>
      </Grid>
    </div>
  );
};

export default AddEvent;
