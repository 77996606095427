import React, { useRef, useState } from "react";
import { Grid, Button, TextField, Typography, Divider } from "@mui/material";
import { useLocation } from "react-router-dom";

import "../../../Style/Custom.css";
import { addBlog, updateBlogs } from "api/auth";
import { fileUpload } from "api/metadata";
import { toast } from "react-toastify";

const initalState = {
  title: "",
  subtitle: "",
  shortDescriptionHeroSection: "",
  content: "",
  image: "",
  meta_keyword: "",
  meta_title: "",
  meta_description: "",
  slugurl: "",
};
const AddBlog = () => {
  // const [formData, setFormData] = useState(initalState);
  const location = useLocation();
  const { item, isUpdate } = location?.state || {};

  const [formData, setFormData] = useState({
    // Initialize form data with the blog's data
    title: item?.title || "",
    subtitle: item?.subtitle || "",
    shortDescriptionHeroSection: item?.shortDescriptionHeroSection || "",
    content: item?.content || "",
    image: "",
    meta_keyword: item?.meta_keyword || "",
    meta_title: item?.meta_title || "",
    meta_description: item?.meta_description || "",
    slugurl: item?.slugurl || "",
    // Add other fields as needed
  });
  const handleInputChange = (e) => {
    if (e.target) {
      const { name, value } = e.target;
      setFormData((prevData) => ({ ...prevData, [name]: value }));
    }
  };
  const santialRef = useRef(null);
  const handleSubmit = async () => {
    if (validateForm()) {
      try {
        isUpdate
          ? await updateBlogs({ ...formData, userId: item?._id })
          : await addBlog(formData).then((res) => {});
      } catch (err) {
        if (err?.response?.status === 404) {
          toast.error("some-thing went wrong");
        } else {
          toast.error(err?.response?.message);
        }
      }
      setFormData(initalState);
    } else {
      console.log("Form validation failed");
    }
  };

  const [errors, setErrors] = useState({});

  const handleError = (inputs, value) => {
    setErrors((prev) => ({ ...prev, [inputs]: value }));
  };

  // Validation function
  const validateForm = () => {
    let isValid = true;

    if (!formData.title) {
      handleError("Title", "Please Enter the Title");
      isValid = false;
    }

    if (!formData.subtitle) {
      handleError("subtitle", "Please Enter the Subtitle");

      isValid = false;
    }
    if (!formData.shortDescriptionHeroSection) {
      handleError(
        "shortDescriptionHeroSection",
        "Please Enter the ShortDescriptionHeroSection"
      );

      isValid = false;
    }
    if (!formData.meta_title) {
      handleError("meta_title", "Please Enter the meta_title");

      isValid = false;
    }
    if (!formData.meta_keyword) {
      handleError("meta_keyword", "Please Enter the meta_keyword");

      isValid = false;
    }
    if (!formData.meta_description) {
      handleError("meta_description", "Please Enter the meta_description");

      isValid = false;
    }
    if (!formData.slugurl) {
      handleError("slugurl", "Please Enter the slugurl");

      isValid = false;
    }
    if (!formData.content) {
      handleError("content", "Please Enter the Content");

      isValid = false;
    }

    return isValid;
  };

  return (
    <div className="main-container">
      <Grid item>
        <Typography color="textPrimary" variant="h5" className="heading1">
          Blog Creation
        </Typography>
        <Divider />
      </Grid>

      <Grid item className="mt_35px mb_15px">
        <Typography color="textPrimary" variant="h5">
          General Information
        </Typography>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={12} md={12} lg={6}>
          <TextField
            name="title"
            label="Title"
            type="text"
            value={formData.title}
            onChange={handleInputChange}
            variant="outlined"
            required
            fullWidth
            error={!errors.title ? false : true}
            helperText={errors.title}
            onFocus={() => handleError("title", null)}
          />
        </Grid>
        <Grid item xs={12} md={12} lg={6}>
          <TextField
            name="subtitle"
            label="Subtitle"
            type="text"
            value={formData.subtitle}
            onChange={handleInputChange}
            variant="outlined"
            required
            fullWidth
            error={!errors.subtitle ? false : true}
            helperText={errors.subtitle}
            onFocus={() => handleError("subtitle", null)}
          />
        </Grid>
        <Grid item xs={12} md={12} lg={6}>
          <TextField
            name="shortDescriptionHeroSection"
            label="ShortDescriptionHeroSection"
            type="text"
            value={formData.shortDescriptionHeroSection}
            onChange={handleInputChange}
            variant="outlined"
            required
            fullWidth
            error={!errors.shortDescriptionHeroSection ? false : true}
            helperText={errors.shortDescriptionHeroSection}
            onFocus={() => handleError("shortDescriptionHeroSection", null)}
          />
        </Grid>

        <Grid item xs={12} md={12} lg={6}>
          <TextField
            name="content"
            label="content"
            type="text"
            value={formData.content}
            onChange={handleInputChange}
            variant="outlined"
            required
            fullWidth
            error={!errors.content ? false : true}
            helperText={errors.content}
            onFocus={() => handleError("content", null)}
          />
        </Grid>
        <Grid item xs={12} md={12} lg={6}>
          <TextField
            name="meta_title"
            label="meta title"
            type="text"
            value={formData.meta_title}
            onChange={handleInputChange}
            variant="outlined"
            required
            fullWidth
            error={!errors.meta_title ? false : true}
            helperText={errors.meta_title}
            onFocus={() => handleError("meta_title", null)}
          />
        </Grid>
        <Grid item xs={12} md={12} lg={6}>
          <TextField
            name="meta_description"
            label="meta description"
            type="text"
            value={formData.meta_description}
            onChange={handleInputChange}
            variant="outlined"
            required
            fullWidth
            error={!errors.meta_description ? false : true}
            helperText={errors.meta_description}
            onFocus={() => handleError("meta_description", null)}
          />
        </Grid>
        <Grid item xs={12} md={12} lg={6}>
          <TextField
            name="meta_keyword"
            label="meta keyword"
            type="text"
            value={formData.meta_keyword}
            onChange={handleInputChange}
            variant="outlined"
            required
            fullWidth
            error={!errors.meta_keyword ? false : true}
            helperText={errors.meta_keyword}
            onFocus={() => handleError("meta_keyword", null)}
          />
        </Grid>
        <Grid item xs={12} md={12} lg={6}>
          <TextField
            name="slugurl"
            label="slugurl"
            type="text"
            value={formData.slugurl}
            onChange={handleInputChange}
            variant="outlined"
            required
            fullWidth
            error={!errors.slugurl ? false : true}
            helperText={errors.slugurl}
            onFocus={() => handleError("slugurl", null)}
          />
        </Grid>

        <Grid item xs={12} md={12} lg={6}>
          <FileInput
            label="Image"
            setFormData={setFormData}
            _key="image"
            formData={formData}
          />
        </Grid>
      </Grid>

      <Grid container spacing={2}>
        <Grid
          item
          xs={12}
          style={{ display: "flex", justifyContent: "center" }}
        >
          <Button variant="contained" onClick={handleSubmit}>
            Register
          </Button>
        </Grid>
      </Grid>
    </div>
  );
};

export default AddBlog;

const FileInput = (props) => {
  const { errors = {}, label, setFormData, _key, formData } = props;
  const fileInputRef = useRef(null);
  const [value, setValue] = useState(null);

  const handleInputChange = async (event) => {
    const file = event.target.files[0];
    const _formData = new FormData();
    _formData.append("file", file);
    try {
      const res = await fileUpload(_formData);
      setValue(res?.data?.Location);
      setFormData({
        ...formData,
        [_key]: res?.data?.Location,
      });
    } catch (err) {
      console.log(err);
    }
  };

  const handleTextFieldClick = () => {
    fileInputRef.current.click();
  };
  return (
    <>
      <TextField
        label={label}
        fullWidth
        onClick={handleTextFieldClick}
        value={value ? value : formData?.[_key]}
        InputProps={{
          readOnly: true,
        }}
        error={!errors.copyright ? false : true}
      />
      <div>
        <img
          style={{ width: "100px", height: "100px" }}
          src={formData?.[_key]}
          alt=""
        />
      </div>
      <input
        type="file"
        ref={fileInputRef}
        name="title_img"
        onChange={handleInputChange}
        style={{ display: "none" }}
        accept="image/*" // Only allow image files
      />
    </>
  );
};
