import * as React from 'react';
import { DataGrid } from '@mui/x-data-grid';
import { Typography, IconButton, Button ,TextField} from '@mui/material';
import EditNoteIcon from '@mui/icons-material/EditNote';
import { useNavigate } from 'react-router';
import { DeleteForever } from '@mui/icons-material';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { CSVLink } from 'react-csv';
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import SearchIcon from '@mui/icons-material/Search';
import InputAdornment from '@mui/material/InputAdornment';
import { useState,useRef } from 'react';
import { useReactToPrint } from "react-to-print";

const DisplayPurposes = () => {
  const navigate = useNavigate();
  const componentPDFTable=useRef();
  const [downloadInProgress, setDownloadInProgress] = useState(false);

  const [searchInput, setSearchInput] = useState("");

  const handleSearchChange = (e) => {
    setSearchInput(e.target.value);
  };
  const rows = [
    {
      id: 1,
      purpose: 'Event',
      purposeType: 'Webinar',
      title: 'Empowering Digital Education for Underprivileged Youth',
      description: 'We are passionately committed to a cause that aims to empower underprivileged youth through digital education. In recognizing the transformative power of technology, we invite you to join our initiative in bridging the digital divide. Your support will enable these young minds to access crucial digital learning resources, unlocking a world of opportunities and preparing them for success in the digital age.',
    },

  ];
  const filteredRows = rows.filter(row => 
    row.purpose.toLowerCase().includes(searchInput.toLowerCase()) ||
    // row.purposeType.toLowerCase().includes(searchInput.toLowerCase()) ||
    // row.title.toLowerCase().includes(searchInput.toLowerCase()) ||
    row.description.toLowerCase().includes(searchInput.toLowerCase())
  );

  const handleView = () => {
    navigate('/view-purpose-details');
  };
  const handleEdit = () => {
    navigate('/add-donation-purpose');
  };
  const handleDelete = () => {
    alert('Are you sure you want to delete this Donor Permanently?');
  };

  const columns = [
    { field: 'id', headerName: 'S.No.', width: 125, minWidth: 155, maxWidth: 200 },
    { field: 'purpose', headerName: 'Purpose',width: 125, minWidth: 155, maxWidth: 200  },
    { field: 'purposeType', headerName: 'Type',width: 125, minWidth: 155, maxWidth: 200  },
    { field: 'title', headerName: 'Title',width: 125, minWidth: 155, maxWidth: 200  },
    { field: 'description', headerName: 'Description', width: 155, minWidth: 250, maxWidth: 250 },
    {
      field: 'action',
      headerName: 'Action',
      width: 125, minWidth: 155, maxWidth: 200 ,
      renderCell: () => (
        <>
          <IconButton aria-label="view" color='warning' onClick={() => handleView()}>
            <VisibilityIcon />
          </IconButton>
          <IconButton aria-label="edit" color='primary' onClick={() => handleEdit()}>
            <EditNoteIcon />
          </IconButton>
          <IconButton aria-label="delete" color='error' onClick={() => handleDelete()}>
            <DeleteForever />
          </IconButton>
        </>
      )
    }
  ];

  const generatePDFPrintTable = useReactToPrint({
    content: () => componentPDFTable.current,
    documentTitle: "userData",
    onAfterPrint: () => setDownloadInProgress(false),
  });
  const formatDataForCSV = () => {
    const formattedData = rows.map((row) => ({
      "S.No": row.id,
      "Donation Purpose": row.purpose,
      "Type": row.purposeType,
      "Title": row.title,
      "Description": row.description,
    }));
    return formattedData;
  };

  return (
    <div className='table-common'>
      <div spacing={0} className="d-flex-space-bw">
      <div>
          <TextField
            size="small"
            placeholder="Search"
            value={searchInput}
            onChange={handleSearchChange}
            variant="outlined"
            fullWidth
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
          />
        </div>
        <div>
          <Typography variant="h5">
            Donation Purpose Details
          </Typography>
        </div>
        <div>
        <Button onClick={generatePDFPrintTable} variant="text">
            <IconButton onClick={generatePDFPrintTable} aria-label="edit" color="primary">
              <ArrowDownwardIcon />
            </IconButton>
            Download
          </Button>
        
          <Button variant="text">
            <CSVLink
              data={formatDataForCSV()}
              filename={"Donation_Purpose_Details.csv"}
              className="csv-link"
              target="_blank"
            >
              <IconButton aria-label="edit" color="primary">
                <ArrowDownwardIcon />
              </IconButton>
              CSV
            </CSVLink>
          </Button>
        </div>
      </div>
      <div ref={componentPDFTable}>

      <div style={{ height: 'calc(100% - 48px)',width:'100%',overflow:'auto' }}>
        <DataGrid rows={filteredRows } columns={columns} pageSize={5}  sx={{width:'100%',minWidth:'750px !important'}}/>
      </div>
    </div>
    </div>

  );
};

export default DisplayPurposes;
