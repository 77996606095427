import React, { useState, useEffect, useRef } from "react";
import { Grid, TextField, Typography, Button } from "@mui/material";
import Divider from "@mui/material/Divider";
import "../../../Style/Custom.css";
import {
  addMetaData,
  getMetaData,
  updateMetaData,
  fileUpload,
} from "api/metadata";
import { toast } from "react-toastify";

const AboutUsPage = () => {
  const [dataId, setDataId] = useState(null);
  const [formData, setFormData] = useState({
    section_one_title: "",
    section_one_desc: "",
    section_one_img_1: "",
    section_one_img_2: "",
    section_one_img_3: "",
    section_two_title: "",
    section_two_desc: "",
    section_two_img_1: "",
    section_two_img_2: "",
    section_two_img_3: "",
  });

  const [heroSection, setHeroSection] = useState({
    entries: [
      {
        title: "",
        desc: "",
        title_img: "",
      },
    ],
  });

  const [memberSection, setMemberSection] = useState({
    entries: [
      {
        name: "",
        desig: "",
        title_img: "",
      },
    ],
  });
  const addNewCard = () => {
    setHeroSection({
      ...heroSection,
      entries: [
        ...heroSection.entries,
        {
          title: "",
          desc: "",
          title_img: "",
        },
      ],
    });
  };
  const addNewMember = () => {
    setMemberSection({
      ...memberSection,
      entries: [
        ...memberSection.entries,
        {
          title: "",
          desc: "",
          title_img: "",
        },
      ],
    });
  };
  const handleMemberChange = (index, event) => {
    const { name, value } = event.target;
    const newEntries = memberSection.entries.map((entry, i) => {
      if (i === index) {
        return {
          ...entry,
          [name]: value,
        };
      }
      return entry;
    });
    setMemberSection({ ...memberSection, entries: newEntries });
  };
  const handleheroChange = (index, event) => {
    const { name, value } = event.target;
    const newEntries = heroSection.entries.map((entry, i) => {
      if (i === index) {
        return {
          ...entry,
          [name]: value,
        };
      }
      return entry;
    });
    setHeroSection({ ...heroSection, entries: newEntries });
  };
  const [heroError, setHeroError] = useState([
    {
      title: "",
      desc: "",
      get_started_link: "",
      title_img: "",
    },
  ]);

  const handleHeroError = (index, name, error) => {
    setHeroError({
      ...heroSection,
      entries: [
        ...heroSection.entries,
        {
          title: "",
          desc: "",
          get_started_link: "",
          title_img: "",
        },
      ],
    });
    setHeroError([
      ...heroError,
      {
        title: "",
        desc: "",
        get_started_link: "",
        title_img: "",
      },
    ]);
  };

  const [errors, setErrors] = useState({});

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };
  const handleSubmit = async () => {
    const payload = {
      hero_section: heroSection,
      member_section: memberSection,
      section_one: {
        title: formData?.section_one_title,
        desc: formData?.section_one_desc,
        img_1: formData?.section_one_img_1,
        img_2: formData?.section_one_img_2,
        img_3: formData?.section_one_img_3,
      },
      section_two: {
        title: formData?.section_two_title,
        desc: formData?.section_two_desc,
        img_1: formData?.section_two_img_1,
        img_2: formData?.section_two_img_2,
        img_3: formData?.section_two_img_3,
      },
    };
    const _payload = JSON.stringify(payload);
    try {
      if (dataId) {
        const data = JSON.parse(sessionStorage.getItem("user"));
        await updateMetaData({
          id: dataId,
          data: { about_us: _payload, website: data.ngo_id.ngo_website },
        });
        toast.success("About us Page data updated successfull.");
      } else {
        const data = JSON.parse(sessionStorage.getItem("user"));
        await addMetaData({
          about_us: _payload,
          website: data.ngo_id.ngo_website,
        });
        toast.success("About us Page data addedd successfull.");
      }
    } catch (err) {
      toast.error("Failed to update/add data");
    }
  };

  const handleError = (inputs, value) => {
    setErrors((prev) => ({ ...prev, [inputs]: value }));
  };

  const fetchMetaData = async () => {
    try {
      const data = JSON.parse(sessionStorage.getItem("user"));
      const res = await getMetaData(data.ngo_id.ngo_website);
      setDataId(res?.data?._id);
      const aboutUsPageData = JSON.parse(res?.data?.about_us);
      setHeroSection(aboutUsPageData?.hero_section);
      setMemberSection(aboutUsPageData?.member_section);
      setFormData({
        section_one_title: aboutUsPageData?.section_one?.title,
        section_one_desc: aboutUsPageData?.section_one?.desc,
        section_one_img_1: aboutUsPageData?.section_one?.img_1,
        section_one_img_2: aboutUsPageData?.section_one?.img_2,
        section_one_img_3: aboutUsPageData?.section_one?.img_3,
        section_two_title: aboutUsPageData?.section_two?.title,
        section_two_desc: aboutUsPageData?.section_two?.desc,
        section_two_img_1: aboutUsPageData?.section_two?.img_1,
        section_two_img_2: aboutUsPageData?.section_two?.img_2,
        section_two_img_3: aboutUsPageData?.section_two?.img_3,
      });
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    fetchMetaData();
  }, []);

  return (
    <div>
      <div className="main-container">
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography color="textPrimary" variant="h5" className="heading2">
              Add About Us Page Data
            </Typography>
            <Divider />
          </Grid>

          <Typography color="textPrimary" variant="h5" className="heading2">
            Hero Section
          </Typography>
          <Divider />
          {heroSection.entries &&
            heroSection.entries.map((entry, index) => (
              <Grid container spacing={2} key={index}>
                <Grid item xs={12} md={12} lg={6}>
                  <TextField
                    name="title"
                    label="Title"
                    value={entry.title}
                    onChange={(e) => handleheroChange(index, e)}
                    variant="outlined"
                    fullWidth
                    error={!heroError[index]?.title ? false : true}
                    helperText={heroError[index]?.title}
                    onFocus={() => handleHeroError(index, "title", null)}
                  />
                </Grid>
                <Grid item xs={12} md={12} lg={6}>
                  <TextField
                    name="desc"
                    label="Description"
                    value={entry.desc}
                    onChange={(e) => handleheroChange(index, e)}
                    variant="outlined"
                    fullWidth
                    error={!heroError[index]?.desc ? false : true}
                    helperText={heroError[index]?.desc}
                    onFocus={() => handleHeroError(index, "desc", null)}
                  />
                </Grid>

                <Grid item xs={12} md={12} lg={6}>
                  <FileInputs
                    label="Title Image"
                    index={index}
                    setHeroSection={setHeroSection}
                    heroSection={heroSection}
                    _key="title_img"
                  />
                </Grid>
              </Grid>
            ))}
          <Grid item xs={12}>
            <Button onClick={addNewCard}>Add More...</Button>
          </Grid>

          <Typography color="textPrimary" variant="h5" className="heading2">
            Section One
          </Typography>
          <Divider />
          <Grid container spacing={2}>
            <Grid item xs={12} md={12} lg={6}>
              <TextField
                name="section_one_title"
                label="Title"
                value={formData.section_one_title}
                onChange={handleInputChange}
                variant="outlined"
                fullWidth
                error={!errors.section_one_title ? false : true}
                helperText={errors.section_one_title}
                onFocus={() => handleError("section_one_title", null)}
              />
            </Grid>
            <Grid item xs={12} md={12} lg={6}>
              <TextField
                name="section_one_desc"
                label="Description"
                value={formData.section_one_desc}
                onChange={handleInputChange}
                variant="outlined"
                fullWidth
                error={!errors.section_one_desc ? false : true}
                helperText={errors.section_one_desc}
                onFocus={() => handleError("section_one_desc", null)}
              />
            </Grid>
            <Grid item xs={12} md={12} lg={6}>
              <FileInput
                label="Image One"
                setFormData={setFormData}
                _key="section_one_img_1"
                formData={formData}
              />
            </Grid>
            <Grid item xs={12} md={12} lg={6}>
              <FileInput
                label="Image One"
                setFormData={setFormData}
                _key="section_one_img_2"
                formData={formData}
              />
            </Grid>
            <Grid item xs={12} md={12} lg={6}>
              <FileInput
                label="Image One"
                setFormData={setFormData}
                _key="section_one_img_3"
                formData={formData}
              />
            </Grid>
          </Grid>

          <Typography color="textPrimary" variant="h5" className="heading2">
            Section Two
          </Typography>
          <Divider />
          <Grid container spacing={2}>
            <Grid item xs={12} md={12} lg={6}>
              <TextField
                name="section_two_title"
                label="Title"
                value={formData.section_two_title}
                onChange={handleInputChange}
                variant="outlined"
                fullWidth
                error={!errors.section_two_title ? false : true}
                helperText={errors.section_two_title}
                onFocus={() => handleError("section_two_title", null)}
              />
            </Grid>
            <Grid item xs={12} md={12} lg={6}>
              <TextField
                name="section_two_desc"
                label="Description"
                value={formData.section_two_desc}
                onChange={handleInputChange}
                variant="outlined"
                fullWidth
                error={!errors.section_two_desc ? false : true}
                helperText={errors.section_two_desc}
                onFocus={() => handleError("section_two_desc", null)}
              />
            </Grid>
            <Grid item xs={12} md={12} lg={6}>
              <FileInput
                label="Image One"
                setFormData={setFormData}
                _key="section_two_img_1"
                formData={formData}
              />
            </Grid>
            <Grid item xs={12} md={12} lg={6}>
              <FileInput
                label="Image One"
                setFormData={setFormData}
                _key="section_two_img_2"
                formData={formData}
              />
            </Grid>
            <Grid item xs={12} md={12} lg={6}>
              <FileInput
                label="Image One"
                setFormData={setFormData}
                _key="section_two_img_3"
                formData={formData}
              />
            </Grid>
          </Grid>

          <Typography color="textPrimary" variant="h5" className="heading2">
            Member Section
          </Typography>
          <Divider />
          {memberSection?.entries &&
            memberSection?.entries.map((entry, index) => (
              <Grid container spacing={2} key={index}>
                <Grid item xs={12} md={12} lg={6}>
                  <TextField
                    name="title"
                    label="Name"
                    value={entry.title}
                    onChange={(e) => handleMemberChange(index, e)}
                    variant="outlined"
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} md={12} lg={6}>
                  <TextField
                    name="desc"
                    label="Designation"
                    value={entry.desc}
                    onChange={(e) => handleMemberChange(index, e)}
                    variant="outlined"
                    fullWidth
                  />
                </Grid>

                <Grid item xs={12} md={12} lg={6}>
                  <FileInputs
                    label="Title Image"
                    index={index}
                    setHeroSection={setMemberSection}
                    heroSection={memberSection}
                    _key="title_img"
                  />
                </Grid>
              </Grid>
            ))}
          <Grid item xs={12}>
            <Button onClick={addNewMember}>Add More...</Button>
          </Grid>

          <Grid item xs={12} className="dFlex justifyCenter">
            <Button variant="contained" onClick={handleSubmit}>
              {dataId ? "Update" : "Add"}
            </Button>
          </Grid>
        </Grid>
      </div>
    </div>
  );
};

export default AboutUsPage;

const FileInput = (props) => {
  const { errors = {}, label, setFormData, _key, formData } = props;
  const fileInputRef = useRef(null);
  const [value, setValue] = useState(null);

  const handleInputChange = async (event) => {
    const file = event.target.files[0];
    const _formData = new FormData();
    _formData.append("file", file);
    try {
      const res = await fileUpload(_formData);
      setValue(res?.data?.Location);
      setFormData({
        ...formData,
        [_key]: res?.data?.Location,
      });
    } catch (err) {
      console.log(err);
    }
  };

  const handleTextFieldClick = () => {
    fileInputRef.current.click();
  };
  return (
    <>
      <TextField
        label={label}
        fullWidth
        onClick={handleTextFieldClick}
        value={value ? value : formData?.[_key]}
        InputProps={{
          readOnly: true,
        }}
        error={!errors.copyright ? false : true}
      />
      <div>
        <img className="wh-100" src={formData?.[_key]} alt="" />
      </div>
      <input
        type="file"
        ref={fileInputRef}
        name="title_img"
        onChange={handleInputChange}
        style={{ display: "none" }}
        accept="image/*" // Only allow image files
      />
    </>
  );
};

const FileInputs = ({
  index,
  label,
  setHeroSection,
  heroSection,
  _key,
  errors = {},
}) => {
  const fileInputRef = useRef(null);
  const [value, setValue] = useState(null);

  const handleInputChange = async (event) => {
    const file = event.target.files[0];
    const _formData = new FormData();
    _formData.append("file", file);
    try {
      const res = await fileUpload(_formData);
      const imageUrl = res?.data?.Location;
      setValue(imageUrl);

      // Update the specific entry within the heroSection.entries array
      const newEntries = heroSection.entries.map((entry, i) => {
        if (i === index) {
          return {
            ...entry,
            [_key]: imageUrl,
          };
        }
        return entry;
      });

      setHeroSection({
        ...heroSection,
        entries: newEntries,
      });
    } catch (err) {
      console.log(err);
    }
  };

  const handleTextFieldClick = () => {
    fileInputRef.current.click();
  };

  return (
    <>
      <TextField
        label={label}
        fullWidth
        onClick={handleTextFieldClick}
        value={value || heroSection.entries[index][_key] || ""}
        InputProps={{
          readOnly: true,
        }}
        error={Boolean(errors[_key])}
        helperText={errors[_key]}
      />
      <div>
        {heroSection.entries[index][_key] && (
          <img
            className="wh-100"
            src={heroSection.entries[index][_key]}
            alt=""
          />
        )}
      </div>
      <input
        type="file"
        ref={fileInputRef}
        name={_key}
        onChange={handleInputChange}
        style={{ display: "none" }}
        accept="image/*" // Only allow image files
      />
    </>
  );
};
