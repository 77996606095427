import React from "react";
import { Grid, Typography, Divider, Button, IconButton } from "@mui/material";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { useNavigate, useLocation } from "react-router";

const ViewProfile = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { item } = location?.state || {};
  const sessionData = JSON.parse(sessionStorage.getItem("user"));

  const handleClickBack = () => {
    navigate("/display-member");
  };

  return (
    <div className="main-container-single">
      <div spacing={0} className="d-flex-space-bw">
        <div className="d-flex-center95">
          <Typography variant="h5">Profile Details</Typography>
        </div>
        <div>
          <Button onClick={handleClickBack} variant="text">
            <IconButton
              onClick={handleClickBack}
              aria-label="edit"
              color="primary"
            >
              <ArrowBackIosIcon />
            </IconButton>
            Back
          </Button>
        </div>
      </div>
      <Grid container>
        <Grid item xs={12}>
          <Divider />
        </Grid>
      </Grid>
      <Grid container spacing={3} className="mt-4" style={{ margin: "8px" }}>
        <Grid item xs={12}>
          <Typography color="textPrimary" gutterBottom variant="h4">
            {/* Basic Information */}
          </Typography>
        </Grid>
        <Grid item xs={12} md={12} lg={6}>

          <Typography variant="h6">NGO Name:</Typography>
          <Typography>{sessionData?.ngo_id?.ngo_name}</Typography>
        </Grid>
        <Grid item xs={12} md={12} lg={6}>
          <Typography variant="h6">NGO Email:</Typography>
          <Typography>{sessionData?.ngo_id?.ngo_email}</Typography>
        </Grid>

        <Grid item xs={12} md={12} lg={6}>
          <Typography variant="h6">NGO Address:</Typography>
          <Typography>{sessionData?.ngo_id?.ngo_address}</Typography>
        </Grid>
        <Grid item xs={12} md={12} lg={6}>
          <Typography variant="h6">Mobile Number</Typography>
          <Typography>{sessionData?.ngo_id?.ngo_mobile_number}</Typography>
        </Grid>
        <Grid item xs={12} md={12} lg={6}>
          <Typography variant="h6">GST Number:</Typography>
          <Typography>{sessionData?.ngo_id?.ngo_gst_number}</Typography>
        </Grid>
        <Grid item xs={12} md={12} lg={6}>
          <Typography variant="h6">PAN Number:</Typography>
          <Typography>{sessionData?.ngo_id?.ngo_pan_number}</Typography>
        </Grid>
        <Grid item xs={12} md={12} lg={6}>
          <Typography variant="h6">NGO Website:</Typography>
          <Typography>{sessionData?.ngo_id?.ngo_website}</Typography>
        </Grid> <Grid item xs={12} md={12} lg={6}>
          <Typography variant="h6">Number of Director:</Typography>
          <Typography>{sessionData?.ngo_id?.number_of_directors}</Typography>
        </Grid> <Grid item xs={12} md={12} lg={6}>
          <Typography variant="h6">Total Employee:</Typography>
          <Typography>{sessionData?.ngo_id?.total_emp}</Typography>
        </Grid>
        <Grid item xs={12} md={12} lg={6}>
          <Typography variant="h6">User Name:</Typography>
          <Typography>{sessionData?.user_name}</Typography>
        </Grid>
        <Grid item xs={12} md={12} lg={6}>
          <Typography variant="h6">Role:</Typography>
          <Typography>{sessionData?.role}</Typography>
        </Grid>
        <Grid item xs={12} md={12} lg={6}>
          <Typography variant="h6">Status:</Typography>
          <Typography>{sessionData?.status}</Typography>
        </Grid>
        <Grid item xs={12}>
          <Divider />
        </Grid>

        {/* <Grid item xs={12}>
          <Typography color="textPrimary" gutterBottom variant="h4">
            Address Information
          </Typography>
        </Grid>
        <Grid item xs={12} md={12} lg={6}>
          <Typography variant="h6">Address:</Typography>
          <Typography variant="body1">{item?.address_1}</Typography>
        </Grid>
        <Grid item xs={12}>
          <Divider />
        </Grid>

        <Grid item xs={12}>
          <Divider />
        </Grid>

        <Grid item xs={12}>
          <Typography color="textPrimary" gutterBottom variant="h4">
            Qualification Details
          </Typography>
        </Grid>
        <Grid item xs={12} md={12} lg={6}>
          <Typography variant="h6">Highest Qualification:</Typography>
          <Typography variant="body1">{item?.high_qualification}</Typography>
        </Grid>
        <Grid item xs={12}>
          <Divider />
        </Grid>

        <Grid item xs={12}>
          <Typography color="textPrimary" gutterBottom variant="h4">
            Emergency Contact Information
          </Typography>
        </Grid>
        <Grid item xs={12} md={12} lg={6}>
          <Typography variant="h6">Name:</Typography>
          <Typography variant="body1">{item?.emergency_name}</Typography>
        </Grid>
        <Grid item xs={12} md={12} lg={6}>
          <Typography variant="h6">Phone Number:</Typography>
          <Typography variant="body1">{item?.emergency_number}</Typography>
        </Grid>
        <Grid item xs={12} md={12} lg={6}>
          <Typography variant="h6">Relation:</Typography>
          <Typography variant="body1">{item?.emergency_relation}</Typography>
        </Grid>
        <Grid item xs={12}>
          <Divider />
        </Grid> */}

        {/* <Grid item xs={12}>
          <Typography color="textPrimary" gutterBottom variant="h4">
            Status Information
          </Typography>
        </Grid>
        <Grid item xs={12} md={12} lg={6}>
          <Typography variant="h6">Status:</Typography>
          <Typography variant="body1">Active</Typography>
        </Grid>
        <Grid item xs={12}>
          <Divider />
        </Grid> */}
      </Grid>

    </div>
  );
};

export default ViewProfile;
