import React from "react";
import { DataGrid } from "@mui/x-data-grid";
import { Typography, IconButton, Dialog, DialogTitle, DialogContent, Grid, TextField, DialogActions, Button, Divider } from "@mui/material";
import EditNoteIcon from "@mui/icons-material/EditNote";
import { useNavigate } from "react-router";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { useEffect, useState, useRef } from "react";
import { DeleteForever } from "@mui/icons-material";
import { useReactToPrint } from "react-to-print";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import SearchIcon from "@mui/icons-material/Search";
import InputAdornment from "@mui/material/InputAdornment";
import CommonPopup from "component/CommonPopup";
import { CSVLink } from "react-csv";
import NGOLogo from "component/Logo";
import { getBlogsAdmin } from "api/auth";
import DownloadHeader from "component/DownloadHeader";
import { deleteBlog } from "api/blogApi";

const DisplayBlogs = () => {
  const componentPDF = useRef();
  const componentPDFTable = useRef();

  const navigate = useNavigate();
  const [downloadInProgress, setDownloadInProgress] = useState(false);
  const [rows, setRows] = React.useState([]);
  const [open, setOpen] = useState(false);
  const [searchInput, setSearchInput] = useState("");
  const [openModal, setOpenModal] = useState(false);
  const [selectedId, setSelectedId] = useState(null);
  const handleSearchChange = (e) => setSearchInput(e.target.value);
  const handleClose = () => setOpen(false);

  const fetchUsers = async () => {
    try {
      const _user = JSON.parse(sessionStorage.getItem("user"));
      const res = await getBlogsAdmin(_user.schema_name);
      const res_ = res?.data?.map((item, i) => ({ ...item, id: item._id, index: i + 1 }));
      setRows(res_);
    } catch (err) { console.log(err); }
  };

  useEffect(() => fetchUsers(), []);

  const handleOpenModal = (id) => { setSelectedId(id); setOpenModal(true); };
  const handleCloseModal = () => { setOpenModal(false); setSelectedId(null); };

  const generatePDF = useReactToPrint({ content: () => componentPDF.current, documentTitle: "userData" });

  // table
  const handleDownloadClickTable = () => {
    setDownloadInProgress(true);
    setTimeout(() => generatePDFPrintTable(), 1);
  };

  const generatePDFPrintTable = useReactToPrint({
    content: () => componentPDFTable.current,
    documentTitle: "blogs_data",
    onStart: () => setDownloadInProgress(true),
    onAfterPrint: () => setDownloadInProgress(false),
  });

  const formatDataForCSV = () => rows.map((row) => ({
    "S.No": row.index,
    Employee: row.first_name,
    "Contact Number": row.mobile_number,
    Address: row.address_1,
  }));

  const columns = [
    { field: "index", headerName: "S.No" },
    { field: "title", headerName: "Title", width: 155, minWidth: 280, maxWidth: 280 },

    { field: "subtitle", headerName: "Sub Title", width: 150, minWidth: 280, maxWidth: 280 },
    { field: "shortDescriptionHeroSection", headerName: "Short Description", width: 125, minWidth: 280, maxWidth: 280 },
  ];

  if (!downloadInProgress) {
    columns.push({
      field: "action",
      headerName: "Action",
      width: 125,
      minWidth: 125,
      maxWidth: 200,
      renderCell: (item) => (
        <div>
          <IconButton aria-label="edit" color="error" onClick={() => handleOpenModal(item.row._id)}><DeleteForever /></IconButton>
          <IconButton aria-label="view" color="warning" onClick={() => handleView(item)}><VisibilityIcon /></IconButton>
          <IconButton aria-label="edit" color="primary" onClick={() => handleEdit(item)}><EditNoteIcon /></IconButton>
        </div>
      ),
    });
  }

  const handleEdit = (item) => navigate("/add-blog", { state: { item: item?.row || {}, isUpdate: true } });
  const handleView = (item) => navigate("/view-employee-details", { state: { item: item?.row || {} } });

  const handleDelete = async (id) => {
    try {
      await deleteBlog(id);
      fetchUsers();
    } catch (error) { console.error('Error deleting blog:', error); }
    handleCloseModal();
  };
  const filteredEmployeeData = rows.filter((employee) =>
    employee?.first_name?.toLowerCase()?.includes(searchInput?.toLowerCase())
  );

  return (
    <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
      <div className="table-common">
        <div spacing={0} className="d-flex-space-bw">
          <div>
            <TextField
              type="text"
              size="small"
              placeholder="Search"
              value={searchInput}
              onChange={handleSearchChange}
              variant="outlined"
              fullWidth
              InputProps={{ startAdornment: <InputAdornment position="start"><SearchIcon /></InputAdornment> }}
            />
          </div>

          <div><Typography variant="h5">Blogs Information</Typography></div>
          <div>
            <Button onClick={handleDownloadClickTable} variant="text">
              <IconButton onClick={handleDownloadClickTable} aria-label="edit" color="primary"><ArrowDownwardIcon /></IconButton>
              Download
            </Button>

            <Button variant="text">
              <CSVLink data={formatDataForCSV()} filename={"employee.csv"} className="csv-link" target="_blank">
                <IconButton aria-label="edit" color="primary"><ArrowDownwardIcon /></IconButton>
                CSV
              </CSVLink>
            </Button>
          </div>
        </div>
        <div ref={componentPDFTable} style={{ padding: downloadInProgress ? "20px" : "0px", height: "100%", overflowX: 'auto', width: '100%' }}>
          {downloadInProgress && <DownloadHeader />}
          <div style={{ height: "calc(100% - 48px)", width: '100%', overflow: 'auto' }}>
            <DataGrid rows={rows} columns={columns} pageSize={5} sx={{ minWidth: '750px !important' }} />
          </div>
        </div>
      </div>

      <Dialog open={open} onClose={handleClose} aria-labelledby="responsive-dialog-title">
        <DialogTitle id="responsive-dialog-title">{"All Employee Details"}</DialogTitle>
        <DialogContent>
          <div ref={componentPDF}>
            {/* credit receipt*/}
            <div className="main-container-single">
              <div spacing={0} className="d-flex-space-bw">
                <div className="d-flex-center95"><Typography variant="h5">Blogs Information</Typography></div>
              </div>
              <Grid container><Grid item xs={12}><Divider /></Grid></Grid>
              <Grid container spacing={3} className="flexContainer">
                {[...Array(5)].map((_, i) => (
                  <React.Fragment key={i}>
                    <Grid item xs={4}><Typography variant="h6">test</Typography></Grid>
                    <Grid item xs={8}><Typography variant="body1">test</Typography></Grid>
                  </React.Fragment>
                ))}
                <Grid item xs={12}><Divider /></Grid>
              </Grid>
            </div>
          </div>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleClose}>Cancel</Button>
          <Button onClick={generatePDF} autoFocus>Download receipt</Button>
        </DialogActions>
      </Dialog>
      <CommonPopup
        title="Delete Confirmation"
        bodyContent={<p>Are you sure you want to delete this Blog?</p>}
        open={openModal}
        handleClose={handleCloseModal}
        onConfirm={() => handleDelete(selectedId)}
      />
    </div>
  );
};

export default DisplayBlogs;
